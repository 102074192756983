import DatePicker, { registerLocale } from "react-datepicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box } from "@mui/material";
import ja from "date-fns/locale/ja";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { DOW } from "utils/constant";
import { convertToCalendarStartDay } from "utils/convertToCalendarStartDay";
import { DATE_SLASH_FORMAT } from "utils/formatDateUtil";
import { styles } from "./styles";
registerLocale("ja", { ...ja, options: { ...ja.options, weekStartsOn: 1 } });

interface Props {
  onChange: (date: Date, e: React.SyntheticEvent<any> | undefined, name: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  date: Date | null;
  id: string;
  name: string;
  isClearable?: boolean;
  dateFormat?: string;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  popperPlacement?: any;
  popperModifiers?: any;
  disabled?: boolean;
  placeholder?: string;
}

export const CustomDatePicker = ({
  onBlur = () => {},
  showTimeSelect = false,
  timeIntervals = 1,
  disabled = false,
  isClearable = false,
  dateFormat = DATE_SLASH_FORMAT,
  popperPlacement = "bottom",
  ...props
}: Props) => {
  const classes = styles({ isClearable });
  const state = useAppSelector(selectMain);

  const dowStyle = (date: Date) => {
    if (date.getDay() === DOW.SATURDAY) {
      return classes.saturday;
    }
    if (date.getDay() === DOW.SUNDAY) {
      return classes.sunday;
    }
    return "";
  };

  return (
    <Box className={classes.box}>
      <DatePicker
        showIcon
        icon={<CalendarTodayIcon color="primary" fontSize="small" />}
        locale="ja"
        id={props.id}
        name={props.name}
        className={classes.dateBlock}
        isClearable={isClearable}
        dayClassName={(date) => dowStyle(date)}
        shouldCloseOnSelect={false}
        selected={props.date}
        dateFormat={dateFormat}
        onChange={(date, e) => {
          // 9999年以降はAPI側でエラーになるため入力できないようにする
          if (date && date?.getFullYear() > 9999) return;
          props.onChange(date as Date, e, props.name);
        }}
        onBlur={onBlur}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        popperPlacement={popperPlacement}
        popperModifiers={props.popperModifiers}
        disabled={disabled}
        calendarStartDay={convertToCalendarStartDay(
          state.personalSetting.calendar_start_day_of_week,
        )}
        autoComplete="off"
        placeholderText={props.placeholder}
        timeCaption="時間"
      />
    </Box>
  );
};
