import { useEffect, useState } from "react";
import { HelpOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Tooltip,
  SelectChangeEvent,
  styled,
  Typography,
  Select,
  MenuItem,
  Box,
  Switch,
} from "@mui/material";
import { SubscriberType, subscriberTypes } from "data-access/repositories/notice/index.dto";
import {
  initialNoticeConfirmedScheduleChange,
  NoticeConfirmedScheduleChange,
} from "data-access/repositories/notice/schedule/confirmed-schedule-change.dto";
import { confirmedScheduleChangeRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-change.repository";
import { ScheduleTypeId } from "data-access/repositories/schedule_type/schedule_type.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { handleReduxError } from "utils/errorHandler";

const STypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
  marginBottom: "8px",
});

interface Props {
  data?: NoticeConfirmedScheduleChange;
  fetchIndexKey: string;
  setIsLoading: (v: boolean) => void;
}
export const ConfirmedScheduleChange = (props: Props) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState<NoticeConfirmedScheduleChange>(
    initialNoticeConfirmedScheduleChange,
  );

  useEffect(() => {
    if (props.data) {
      setFormState(props.data);
    }
  }, [props.data]);

  const handleChangeForMultiSelectBox = async (
    e: SelectChangeEvent<SubscriberType[] | ScheduleTypeId[]>,
  ) => {
    const { name, value } = e.target;
    if (!value.length) return;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleCloseForSelectBox = async () => {
    props.setIsLoading(true);
    try {
      await confirmedScheduleChangeRepository.update({
        subscriberTypes: formState.subscriberTypes,
      });
      mutate(props.fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知方法を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知方法の変更に失敗しました");
    } finally {
      props.setIsLoading(false);
    }
  };

  const selectedSubscriberTypesLabel = (selectedSubscriberTypes: SubscriberType[]): string => {
    return subscriberTypes
      .filter((subscriberType) => selectedSubscriberTypes.includes(subscriberType.value))
      .map((subscriberType) => subscriberType.name)
      .join(", ");
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    props.setIsLoading(true);
    try {
      await confirmedScheduleChangeRepository.update({
        isEnable: checked,
      });
      mutate(props.fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知ルールの更新に失敗しました");
    } finally {
      props.setIsLoading(false);
    }
  };

  const isSelectedSubscriberType = (subscriberType: SubscriberType) => {
    return formState.subscriberTypes.find(
      (noticeSubscriberType) => noticeSubscriberType === subscriberType,
    );
  };

  return (
    <div style={{ marginBottom: "24px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", mr: "8px" }}>予定の編集</Typography>
        <Tooltip title="この通知は予定参加者全員（操作した人を除く）が対象です" placement="right">
          <HelpOutlined fontSize="small" color="primary" />
        </Tooltip>
      </div>
      {props.data && (
        <div style={{ marginLeft: "20px" }}>
          <div style={{ display: "flex", alignItems: "flex-end", gap: "24px" }}>
            <div>
              <STypography>通知方法</STypography>
              <Select
                name="subscriberTypes"
                value={props.data.subscriberTypes}
                onChange={handleChangeForMultiSelectBox}
                onClose={handleCloseForSelectBox}
                multiple
                sx={{ width: "200px", height: "40px" }}
                renderValue={(values) => selectedSubscriberTypesLabel(values)}
              >
                {subscriberTypes.map((subscriberType) => (
                  <MenuItem key={subscriberType.value} value={subscriberType.value}>
                    {isSelectedSubscriberType(subscriberType.value) ? (
                      <CheckIcon color="primary" sx={{ mr: 1 }} />
                    ) : (
                      <Box sx={{ px: 1.5, mr: 1 }} />
                    )}
                    {subscriberType.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Switch
              name="isEnable"
              checked={props.data.isEnable}
              onChange={handleToggle}
              color="primary"
            />
          </div>
        </div>
      )}
    </div>
  );
};
