import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography, Tooltip, styled, tooltipClasses, TooltipProps } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderCellParams, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { MultilineTooltip } from "components/atoms/multiline-tooltip";
import { TodoTagTypeLabel } from "components/label/todo-tag-type-label";
import { ProjectTable } from "components/organisms/project-table";
import { ProjectTableHeader } from "components/organisms/project-table-header";
import { Layout } from "components/templates/layout";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { ProjectCollection } from "data-access/repositories/project/project.dto";
import { ProjectTodo } from "data-access/repositories/project/todo/todo.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { calculateGrossProfitRate } from "utils/calculateGrossProfit";
import { DATE_SLASH_FORMAT, DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { ProjectSidebar } from "./sidebar";
type CSVRenderFunction<T> = (value: T, row: GridValidRowModel) => string;

export interface CSVGridColDef extends Omit<GridColDef, "field"> {
  field: string;
  hide?: boolean;
  csvRender?: CSVRenderFunction<any>;
}
const defaultHeaders: CSVGridColDef[] = [];
defaultHeaders.push({
  field: "code",
  headerName: "案件番号",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  disableReorder: true,
});
defaultHeaders.push({
  field: "name",
  headerName: "案件名",
  minWidth: 250,
  sortingOrder: ["desc", "asc", null],
  disableReorder: true,
});
defaultHeaders.push({
  field: "inquired_by_name",
  headerName: "受付担当者",
  minWidth: 110,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.inquired_by?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.inquired_by?.name;
  },
});
// renderCellはProjectModalのdefaultHeadersの反映作業にて追加される
defaultHeaders.push({
  field: "project_status_type_name",
  headerName: "案件ステータス",
  minWidth: 150,
  align: "left",
  sortingOrder: ["desc", "asc", null],
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.project_status_type.name;
  },
});
defaultHeaders.push({
  field: "todos",
  headerName: "TODO",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<ProjectTodo[]>) => {
    if (!params.value) return;

    const undoneTodos = params.value.filter((todo: ProjectTodo) => !todo.is_done);

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.grayScale[0],
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: "12px",
      },
    }));

    return (
      <>
        {undoneTodos.map((todo: ProjectTodo, index: number) => (
          <CustomTooltip key={index} title={todo.content} placement="bottom">
            <div>
              <TodoTagTypeLabel
                key={todo.id}
                todoTagType={todo.tag_type.value_i18n}
                sx={{ mr: "4px" }}
              />
            </div>
          </CustomTooltip>
        ))}
      </>
    );
  },
  renderHeader: (params: GridColumnHeaderParams) => (
    <Tooltip title="未完了のTODOの数でソートします" placement="top">
      <div style={{ textAlign: "center" }}>{params.colDef.headerName}</div>
    </Tooltip>
  ),
  csvRender: (todos: ProjectTodo[]) => {
    return todos.map((todo) => todo.tag_type.value_i18n).join(" ");
  },
});
defaultHeaders.push({
  field: "project_type_name",
  headerName: "案件タイプ",
  minWidth: 110,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.project_type.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.project_type.name;
  },
});
defaultHeaders.push({
  field: "is_no_bill",
  headerName: "請求しない",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => (
    <>{params.row.is_no_bill ? <CheckCircleOutlineIcon fontSize="small" /> : ""}</>
  ),
});
defaultHeaders.push({
  field: "billing_condition",
  headerName: "請求状況",
  minWidth: 100,
  sortable: false,
});
defaultHeaders.push({
  field: "client_name",
  headerName: "顧客名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.client?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.client?.name;
  },
});
defaultHeaders.push({
  field: "client_phone_number",
  headerName: "顧客電話番号",
  minWidth: 150,
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.client?.phone_number}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.client?.phone_number;
  },
});
defaultHeaders.push({
  field: "building_name",
  headerName: "物件名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.building ? params.row.building.name : ""}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.building ? row.building.name : "";
  },
});
defaultHeaders.push({
  field: "inquiry_number",
  headerName: "先方注文番号",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
});
defaultHeaders.push({
  field: "inquired_date",
  headerName: "受付日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "ordered_date",
  headerName: "受注日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "estimate_user_names",
  headerName: "見積担当者",
  minWidth: 110,
  sortable: false,
});
defaultHeaders.push({
  field: "manager_name",
  headerName: "現場責任者",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.manager?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.manager?.name;
  },
});
defaultHeaders.push({
  field: "expected_complete_date",
  headerName: "完了予定日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "is_supported_by_maker",
  headerName: "メーカー対応",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => (
    <>
      {params.row.is_supported_by_maker ? (
        <>
          <CheckCircleOutlineIcon fontSize="small" />
          <Typography fontSize="0.8rem">メーカー対応</Typography>
        </>
      ) : (
        "ー"
      )}
    </>
  ),
  csvRender: (value: boolean) => (value ? "メーカー対応" : "ー"),
});
defaultHeaders.push({
  field: "completed_date",
  headerName: "完了日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "sales_amount",
  headerName: "売上",
  type: "number",
  minWidth: 120,
  sortable: false,
  editable: true,
  renderCell: (params: GridRenderCellParams) => {
    const salesAmount = params.row.sales_amount;
    return (
      <Typography style={{ lineHeight: "inherit" }}>{salesAmount.toLocaleString()}</Typography>
    );
  },
});
const determineColor = (value: number) => {
  if (value < 0) {
    return theme.palette.error.main;
  } else {
    return theme.palette.text.primary;
  }
};
defaultHeaders.push({
  field: "gross_profit_amount",
  headerName: "粗利",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    const grossProfitAmount = params.row.sales_amount - params.row.total_cost_amount;
    const color = determineColor(grossProfitAmount);
    return (
      <Typography style={{ color, lineHeight: "inherit" }}>
        {grossProfitAmount.toLocaleString()}
      </Typography>
    );
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return (row.sales_amount - row.total_cost_amount).toString();
  },
});
defaultHeaders.push({
  field: "gross_profit_rate",
  headerName: "粗利率",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    const grossProfitRate = calculateGrossProfitRate(
      params.row.sales_amount,
      params.row.total_cost_amount,
    );
    const color = determineColor(Number(grossProfitRate));
    return <Typography style={{ color }}>{grossProfitRate}%</Typography>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return calculateGrossProfitRate(row.sales_amount, row.total_cost_amount).toString() + "%";
  },
});
defaultHeaders.push({
  field: "total_cost_amount",
  headerName: "原価",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    return (
      <Typography style={{ lineHeight: "inherit" }}>
        {params.row.total_cost_amount.toLocaleString()}
      </Typography>
    );
  },
});
defaultHeaders.push({
  field: "person_name",
  headerName: "先方担当者名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.person_name ? params.row.person_name : ""}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.person_name ? row.person_name : "";
  },
});
defaultHeaders.push({
  field: "combinedAddress",
  headerName: "住所",
  minWidth: 200,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => {
    return `${params.row.address || ""}${params.row.address_second ? " " + params.row.address_second : ""}`.trim();
  },
  csvRender: (_, row) => {
    return `${row.address || ""}${row.address_second ? " " + row.address_second : ""}`.trim();
  },
});
defaultHeaders.push({
  field: "note",
  headerName: "概要・メモ",
  minWidth: 250,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => (
    <MultilineTooltip params={params} />
  ),
  csvRender: (value: string) => {
    // テキストフィールド内にダブルクォートが含まれる場合改行されてしまうため
    // それを2つのダブルクォートに置き換えることでテキストの一部であることを示す
    return `"${value.replace(/"/g, '""')}"`;
  },
});
defaultHeaders.push({
  field: "billing_precaution",
  headerName: "請求に関する注意事項",
  minWidth: 250,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => (
    <MultilineTooltip params={params} />
  ),
  csvRender: (value: string) => {
    return `"${value.replace(/"/g, '""')}"`;
  },
});
defaultHeaders.push({
  field: "updated_at",
  headerName: "最終更新日時",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_TIME_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => formatDateUtil(value, DATE_TIME_SLASH_FORMAT),
});

export default function Projects() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const cookie = cookieRepository.get();

  useEffect(() => {
    // 案件の新規作成
    if (location.pathname === "/projects/new") {
      dispatch(projectSidebarOperations.open());
      dispatch(projectSidebarOperations.updateInquiredById(cookie.id));
      dispatch(projectSidebarOperations.updateProjectTypeId(mainState.projectTypes[0]?.id));
      dispatch(
        projectSidebarOperations.updateProjectStatusTypeId(mainState.projectStatusTypes[0]?.id),
      );
      dispatch(
        projectSidebarOperations.updateNote(
          mainState.company.company_setting.project_note_template,
        ),
      );
    }
  }, [location, mainState.projectTypes, mainState.projectStatusTypes]);

  return (
    <Layout>
      <Box>
        <ProjectTableHeader />
        <Box sx={{ mt: 2 }}>
          <ProjectTable defaultHeaders={defaultHeaders} />
        </Box>

        <ProjectSidebar />
      </Box>
    </Layout>
  );
}
