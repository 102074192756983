import { DEFAULT_ROWS_PER_PAGE } from "utils/constant";
import { convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  ScheduleIndexResponse,
  ScheduleIndexRequest,
  ScheduleCreateRequest,
  ScheduleShowResponse,
  ScheduleUpdateRequest,
  ScheduleConfirmRequest,
  ScheduleId,
  ScheduleSearchRequest,
  ScheduleSearchResponse,
} from "./schedule.dto";
import { ApiClient } from "../../ApiClient";

export const scheduleRepository = {
  index(query: ScheduleIndexRequest): Promise<ScheduleIndexResponse> {
    const params = {
      schedule_range_start: query.scheduleRangeStart,
      schedule_range_end: query.scheduleRangeEnd,
      user_ids: query.userIds,
      group_ids: query.groupIds,
      is_time_entered: query.isTimeEntered,
      keyword: query.keyword,
      sort: query.sort,
      direction: query.direction,
    };
    return ApiClient.get("/api/v1/schedules", { params }).then((res) => res.data);
  },
  search(query: ScheduleSearchRequest): Promise<ScheduleSearchResponse> {
    const params = {
      schedule_range_start: query.scheduleRangeStart,
      schedule_range_end: query.scheduleRangeEnd,
      keyword: query.keyword,
      page: query.page || 1,
      items: query.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
      sort: query.sort,
      direction: query.direction,
    };
    return ApiClient.get("/api/v1/schedules/search", { params }).then((res) => res.data);
  },
  create(body: ScheduleCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/schedules", createBody(body), config).then((res) => res.data);
  },
  show(id: ScheduleId): Promise<ScheduleShowResponse> {
    return ApiClient.get(`/api/v1/schedules/${id}`).then((res) => res.data);
  },
  update(id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest): Promise<void> {
    const config = {
      headers: { "content-type": "application/json" },
      params: { with_futures },
    };
    return ApiClient.put(`/api/v1/schedules/${id}`, updateBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ScheduleId, with_futures: boolean): Promise<void> {
    return ApiClient.delete(`/api/v1/schedules/${id}`, { params: { with_futures } }).then(
      (res) => res.data,
    );
  },
  duplicate(id: ScheduleId): Promise<void> {
    return ApiClient.post(`/api/v1/schedules/${id}/duplicate`).then((res) => res.data);
  },
  confirm(body: ScheduleConfirmRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put("/api/v1/schedules/confirmed", body, config).then((res) => res.data);
  },
};

const updateBody = (body: ScheduleUpdateRequest) => {
  return {
    name: body.name,
    project_id: body.projectId === 0 ? null : body.projectId,
    site_manager_id: body.siteManagerId === 0 ? null : body.siteManagerId,
    schedule_type_id: body.scheduleTypeId === 0 ? null : body.scheduleTypeId,
    start_time: body.startTime,
    end_time: body.endTime,
    user_ids: body.userIds,
    note: body.note,
    is_confirmed: body.isConfirmed,
    is_private: body.isPrivate,
    is_notify: body.isNotify,
    recurrence_rule_id: body.recurrenceRuleId,
    recurrence_rule: body.recurrenceRule ? convertKeysToSnakeCase(body.recurrenceRule) : undefined,
  };
};

const createBody = (body: ScheduleCreateRequest) => {
  return {
    name: body.name,
    schedule_type_id: body.scheduleTypeId,
    site_manager_id: body.siteManagerId === 0 ? null : body.siteManagerId,
    start_time: body.startTime,
    end_time: body.endTime,
    note: body.note,
    is_confirmed: body.isConfirmed,
    is_private: body.isPrivate,
    project_id: body.projectId === 0 ? null : body.projectId,
    project: body.project
      ? {
          project_type_id: body.project.projectTypeId === 0 ? null : body.project.projectTypeId,
          name: body.project.name || "",
          sales_amount: Number(body.project.salesAmount) || 0,
          postal_code: body.project.postalCode || "",
          address: body.project.address || "",
          address_second: body.project.addressSecond || "",
          building_id: body.project.buildingId === 0 ? null : body.project.buildingId,
          client_id: body.project.clientId === 0 ? null : body.project.clientId,
          client: body.project.client
            ? {
                name: body.project.client.name || "",
              }
            : null,
        }
      : null,
    user_ids: body.userIds,
    recurrence_rule: body.recurrenceRule
      ? {
          interval: body.recurrenceRule.interval,
          frequency_type: body.recurrenceRule.frequencyType,
          days_of_week: body.recurrenceRule.daysOfWeek,
          end_type: body.recurrenceRule.endType,
          end_date: body.recurrenceRule.endDate,
          count: body.recurrenceRule.count,
        }
      : undefined,
  };
};
