import React, { ElementRef, useEffect, useRef, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Popover,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { LinkButton } from "components/button/link-button";
import { MoreOptionsButton } from "components/button/more-options-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { GroupLabel } from "components/label/group-label";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { PhotoResponse, ProjectPhotoId } from "data-access/repositories/project/photo/photo.dto";
import { photoRepository as projectPhotoRepository } from "data-access/repositories/project/photo/photo.repository";
import {
  PrimaryCategory,
  PrimaryCategoryId,
} from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { Project, ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { workReportPhotoRepository as workReportPhotoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import { WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { theme } from "extensions/theme";
import { useCategoryCreate } from "hooks/useCategoryCreate";
import { useCategoryDelete } from "hooks/useCategoryDelete";
import { useCategoryUpdate } from "hooks/useCategoryUpdate";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { usePhotoSelect } from "hooks/usePhotoSelect";
import FileDownload from "images/file_download_icon.svg";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { downloadImagesV2 } from "utils/downloadImages";
import { handleReduxError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";
import { ShareSettingDialog } from "../share-setting-dialog";

interface Props {
  open: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project: Project;
}

export const ProjectPhotosModal = ({ open, handleOpen, project }: Props) => {
  const classes = styles();
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(open)}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      className={classes.modal}
    >
      <DialogTitle className={classes.title}>
        写真管理
        <div>
          <Button
            onClick={() => setIsOpenShareModal(true)}
            sx={{
              backgroundColor: theme.palette.grayScale[0],
              color: theme.palette.primary.main,
              p: "8px 12px",
              mr: "48px",
              "&:hover": {
                backgroundColor: theme.palette.grayScale[0],
              },
            }}
          >
            <LinkIcon
              size={16}
              color={project.photo_url_key ? theme.palette.blue[500] : theme.palette.primary.main}
            />
            <Typography
              sx={{
                color: project.photo_url_key ? theme.palette.blue[500] : theme.palette.primary.main,
                ml: "8px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {project.photo_url_key ? "リンクで共有中" : "リンクで共有"}
            </Typography>
          </Button>
          <IconButton className={classes.closeButton} onClick={() => handleOpen(open)}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <CategoriesPhotos projectId={project.id} isSharable={!!project.photo_url_key} />
      </DialogContent>

      <ShareSettingDialog
        open={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        id={project.id}
        resourceType="projects"
        photoUrlKey={project.photo_url_key}
      />
    </Dialog>
  );
};

interface CategoriesPhotosProps {
  projectId: ProjectId;
  isSharable: boolean;
}
const CategoriesPhotos = (props: CategoriesPhotosProps) => {
  const isFirstRender = useRef<boolean>(true);
  const classes = styles();
  const dispatch = useAppDispatch();
  const photoCountIndexKey = `/api/v1/projects/${props.projectId}/photos/count_info`;

  const [selectedPrimaryCategoryId, setSelectedPrimaryCategoryId] = useState<PrimaryCategoryId>(
    0 as PrimaryCategoryId,
  );
  const [selectedSecondaryCategoryId, setSelectedSecondaryCategoryId] =
    useState<SecondaryCategoryId>(0 as SecondaryCategoryId);
  const [hoveredPrimaryCategoryId, setHoveredPrimaryCategoryId] =
    useState<PrimaryCategoryId | null>(null);
  const [hoveredSecondaryCategoryId, setHoveredSecondaryCategoryId] =
    useState<SecondaryCategoryId | null>(null);
  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const { data: primaryCategories, mutate: primaryCategoryMutate } = useSWR(
    `/api/v1/projects/${props.projectId}/primary_categories`,
    () => primaryCategoryRepository.index(props.projectId),
    { revalidateOnFocus: false },
  );

  const { data: secondaryCategory, mutate: secondaryCategoryMutate } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/projects/${props.projectId}/secondary_categories/${selectedSecondaryCategoryId}`,
    async () => {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        return await secondaryCategoryRepository.show(
          props.projectId,
          selectedPrimaryCategoryId,
          selectedSecondaryCategoryId,
        );
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    },
    { revalidateOnFocus: false },
  );

  const { data: photos, mutate: photoMutate } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/projects/${props.projectId}/primary_categories/${selectedPrimaryCategoryId}/secondary_categories/${selectedSecondaryCategoryId}/photos`,
    () =>
      secondaryCategoryRepository.getPhotos(
        props.projectId,
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (isFirstRender.current && primaryCategories) {
      setSelectedPrimaryCategoryId(primaryCategories[0].id);
      setSelectedSecondaryCategoryId(primaryCategories[0].secondaryCategories[0].id);
      isFirstRender.current = false;
    }
  }, [primaryCategories]);

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(photos);

  const {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  } = usePhotoSelect(photos);

  const { handleAddPrimaryCategory, handleAddSecondaryCategory, handleAddTertiaryCategory } =
    useCategoryCreate(props.projectId, primaryCategoryMutate, secondaryCategoryMutate);

  const {
    handleDeletePrimaryCategory,
    handleDeleteSecondaryCategory,
    handleDeleteTertiaryCategory,
  } = useCategoryDelete(
    props.projectId,
    primaryCategories || [],
    selectedPrimaryCategoryId,
    selectedSecondaryCategoryId,
    setSelectedPrimaryCategoryId,
    setSelectedSecondaryCategoryId,
    primaryCategoryMutate,
    secondaryCategoryMutate,
  );

  const {
    editingTargetPrimaryCategoryId,
    editingPrimaryCategoryName,
    editingTargetSecondaryCategoryId,
    editingSecondaryCategoryName,
    editingTargetTertiaryCategoryId,
    editingTertiaryCategoryName,
    handleEditPrimaryCategory,
    handleEditSecondaryCategory,
    handleEditTertiaryCategory,
    handleBlurPrimaryCategory,
    handleBlurSecondaryCategory,
    handleBlurTertiaryCategory,
    setEditingPrimaryCategoryName,
    setEditingSecondaryCategoryName,
    setEditingTertiaryCategoryName,
  } = useCategoryUpdate(
    props.projectId,
    selectedPrimaryCategoryId,
    primaryCategoryMutate,
    secondaryCategoryMutate,
  );

  const handleSelectPrimaryCategory = (primaryCategory: PrimaryCategory) => {
    setSelectedPrimaryCategoryId(primaryCategory.id);
    setSelectedSecondaryCategoryId(primaryCategory.secondaryCategories[0].id);
  };

  const handleSelectSecondaryCategory = (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    setSelectedPrimaryCategoryId(primaryCategoryId);
    setSelectedSecondaryCategoryId(secondaryCategoryId);
    setSelectedPhotoIds(new Set());
  };

  const handleDownload = async () => {
    if (!photos || !secondaryCategory) return;
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const selectedPhotos = photos.filter((photo) => selectedPhotoIds.has(photo.id));
      await downloadImagesV2(selectedPhotos, secondaryCategory.name);
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
      setSelectedPhotoIds(new Set());
    } catch (error) {
      handleReduxError(error, dispatch, "写真のダウンロードに失敗しました");
    }
  };

  const handleDeleteImages = async () => {
    if (!simpleConfirmRef.current || !photos) return;
    const res = await simpleConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));

    try {
      const selectedPhotos = photos.filter((photo) => selectedPhotoIds.has(photo.id));
      const deletePromises = selectedPhotos.map((photo) => {
        if (photo.projectAttributes) {
          return projectPhotoRepository.destroy(props.projectId, photo.id as ProjectPhotoId);
        } else if (photo.workReportAttributes) {
          return workReportPhotoRepository.destroy(
            photo.workReportAttributes.id,
            photo.id as WorkReportPhotoId,
          );
        }
      });
      await Promise.all(deletePromises);

      photoMutate();
      dispatch(mainOperations.updateSuccessMessage("写真を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "写真の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
      setSelectedPhotoIds(new Set());
    }
  };

  const handlePhotoUpload = async (
    files: File[] | FileList | null,
    tertiaryCategoryId: TertiaryCategoryId,
  ) => {
    if (files) {
      const requestObj: {
        tertiaryCategoryId: TertiaryCategoryId;
        files: File[];
        capturedAt: (Date | null)[];
      } = {
        tertiaryCategoryId,
        files: [],
        capturedAt: [],
      };
      for (let i = 0; i < files.length; i++) {
        const targetPhoto = files[i];
        const photo = new File([targetPhoto], targetPhoto.name, {
          lastModified: targetPhoto.lastModified,
        });
        requestObj.files.push(photo);
      }
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const response = await projectPhotoRepository.create(props.projectId, requestObj);
        const successfulUploads = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        const successfulUploadNumber = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        dispatch(
          mainOperations.updateSuccessMessage(
            `${response.length}枚中、${successfulUploadNumber}枚の写真をアップロードしました`,
          ),
        );
        photoMutate();
        if (successfulUploads === 0) {
          dispatch(mainOperations.updateErrorMessage("全ての写真のアップロードに失敗しました"));
        }
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  /**
   * 写真の共有リンクタップ時の処理.
   *
   * @param photo 写真オブジェクト(画像ではないので注意).
   */
  const handleClickImageLinkButton = async (photo: PhotoResponse) => {
    const photoId = photo.id;
    const resourceName = photo.projectAttributes ? "project" : "work_report";
    const isSharable = !photo.isSharable;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      switch (resourceName) {
        case "project":
          await projectPhotoRepository.update(
            photo.projectAttributes?.id as ProjectId,
            photoId as ProjectPhotoId,
            isSharable,
          );
          break;
        case "work_report":
          await workReportPhotoRepository.update(
            photo.workReportAttributes?.id as WorkReportId,
            photoId as WorkReportPhotoId,
            isSharable,
          );
          break;
      }
      photoMutate();
      mutate(photoCountIndexKey);
    } catch (error) {
      handleReduxError(error, dispatch, "写真共有の切り替えに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoResponse | null>(null);

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>, photo: PhotoResponse) => {
    setAnchorEl(e.currentTarget);
    setSelectedPhoto(photo);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedPhoto(null);
  };

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />

      <Box sx={{ display: "flex", width: "100%" }}>
        {/* サイドバー */}
        <Box sx={{ width: "180px", mr: "8px", overflow: "scroll", height: "75vh" }}>
          {primaryCategories?.map((primaryCategory) => (
            <Box key={primaryCategory.id} sx={{ mb: "16px" }}>
              <Box
                onMouseEnter={() => setHoveredPrimaryCategoryId(primaryCategory.id)}
                onMouseLeave={() => setHoveredPrimaryCategoryId(null)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "12px",
                  ":hover": { cursor: "pointer" },
                  color:
                    selectedPrimaryCategoryId === primaryCategory.id
                      ? theme.palette.primary.main
                      : "inherit",
                }}
              >
                {editingTargetPrimaryCategoryId === primaryCategory.id ? (
                  <TextField
                    value={editingPrimaryCategoryName}
                    onChange={(e) => setEditingPrimaryCategoryName(e.target.value)}
                    onBlur={handleBlurPrimaryCategory}
                    autoFocus
                    size="small"
                    sx={{ width: "120px" }}
                  />
                ) : (
                  <Typography
                    onClick={() => handleSelectPrimaryCategory(primaryCategory)}
                    fontWeight="bold"
                    className={classes.categoryTreeName}
                  >
                    {primaryCategory.name}
                  </Typography>
                )}
                {!primaryCategory.isCommon && hoveredPrimaryCategoryId === primaryCategory.id && (
                  <div style={{ marginRight: "8px", height: "24px" }}>
                    <IconButton
                      onClick={() =>
                        handleEditPrimaryCategory(primaryCategory.id, primaryCategory.name)
                      }
                      size="small"
                      sx={{ p: 0, pr: "8px" }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeletePrimaryCategory(primaryCategory.id)}
                      size="small"
                      sx={{ p: 0 }}
                    >
                      <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                    </IconButton>
                  </div>
                )}
              </Box>
              {primaryCategory.secondaryCategories.map((secondaryCategory) => (
                <div key={secondaryCategory.id} style={{ marginLeft: "8px" }}>
                  <Box
                    onMouseEnter={() => setHoveredSecondaryCategoryId(secondaryCategory.id)}
                    onMouseLeave={() => setHoveredSecondaryCategoryId(null)}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      mb: "12px",
                      ":hover": { cursor: "pointer" },
                      color:
                        selectedSecondaryCategoryId === secondaryCategory.id
                          ? theme.palette.primary.main
                          : theme.palette.grayScale[700],
                    }}
                  >
                    {editingTargetSecondaryCategoryId === secondaryCategory.id ? (
                      <TextField
                        value={editingSecondaryCategoryName}
                        onChange={(e) => setEditingSecondaryCategoryName(e.target.value)}
                        onBlur={(e) =>
                          handleBlurSecondaryCategory(e, primaryCategory.id, secondaryCategory.id)
                        }
                        autoFocus
                        size="small"
                        sx={{ width: "120px" }}
                      />
                    ) : (
                      <Typography
                        onClick={() =>
                          handleSelectSecondaryCategory(primaryCategory.id, secondaryCategory.id)
                        }
                        fontWeight="bold"
                        fontSize={14}
                        className={classes.categoryTreeName}
                      >
                        {secondaryCategory.name}
                      </Typography>
                    )}
                    {!secondaryCategory.isCommon &&
                      hoveredSecondaryCategoryId === secondaryCategory.id && (
                        <div style={{ marginRight: "8px", height: "21px" }}>
                          <IconButton
                            onClick={() =>
                              handleEditSecondaryCategory(
                                secondaryCategory.id,
                                secondaryCategory.name,
                              )
                            }
                            size="small"
                            sx={{ p: 0, pr: "8px" }}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDeleteSecondaryCategory(
                                primaryCategory.id,
                                secondaryCategory.id,
                              )
                            }
                            size="small"
                            sx={{ p: 0 }}
                          >
                            <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                          </IconButton>
                        </div>
                      )}
                  </Box>
                </div>
              ))}
              <div style={{ display: "flex", marginLeft: "8px" }}>
                <Button
                  onClick={() => handleAddSecondaryCategory(primaryCategory.id)}
                  sx={{ p: 0 }}
                >
                  <AddIcon fontSize="small" color="disabled" />
                  <Typography fontSize={14} fontWeight="bold" color="GrayText">
                    中項目を追加
                  </Typography>
                </Button>
              </div>
            </Box>
          ))}
          <div style={{ display: "flex" }}>
            <Button onClick={handleAddPrimaryCategory} sx={{ p: 0 }}>
              <AddIcon fontSize="small" color="disabled" />
              <Typography fontWeight="bold" color="GrayText">
                大項目を追加
              </Typography>
            </Button>
          </div>
        </Box>

        {/* 写真メイン */}
        {secondaryCategory && (
          <Box sx={{ flex: 1, overflow: "scroll", height: "75vh" }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: "20px" }}>
              {secondaryCategory.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", mb: "20px" }}>
              <Typography
                onClick={handleSelectPhotoAll}
                className={classes.selectAllButton}
                fontSize={14}
                color={theme.palette.primary.main}
              >
                すべて選択
              </Typography>
              <Typography
                onClick={handleDeselectPhotoAll}
                className={classes.deselectButton}
                fontSize={14}
              >
                選択を解除
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleDownload}
                  disabled={selectedPhotoIds.size === 0}
                  sx={{
                    fontSize: "12px",
                    height: "30px",
                    borderRadius: "20px",
                    fontWeight: "500",
                  }}
                  startIcon={
                    <Box
                      sx={{ color: theme.palette.grayScale[0] }}
                      component="img"
                      src={FileDownload}
                    />
                  }
                >
                  {selectedPhotoIds.size}件をダウンロード
                </Button>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={handleDeleteImages}
                  disabled={selectedPhotoIds.size === 0}
                  sx={{
                    fontSize: "12px",
                    height: "30px",
                    borderRadius: "20px",
                    fontWeight: "500",
                  }}
                  startIcon={
                    <DeleteIcon
                      size={16}
                      color={
                        selectedPhotoIds.size === 0
                          ? theme.palette.grayScale[300]
                          : theme.palette.red[500]
                      }
                    />
                  }
                >
                  {selectedPhotoIds.size}件を削除
                </Button>
              </Box>
            </Box>

            {secondaryCategory.tertiaryCategories.map((tertiaryCategory, index) => {
              const filteredPhotos = photos?.filter(
                (photo) => photo.tertiaryCategory.id === tertiaryCategory.id,
              );

              return (
                <React.Fragment key={tertiaryCategory.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {editingTargetTertiaryCategoryId === tertiaryCategory.id ? (
                      <TextField
                        value={editingTertiaryCategoryName}
                        onChange={(e) => setEditingTertiaryCategoryName(e.target.value)}
                        onBlur={(e) => handleBlurTertiaryCategory(e, secondaryCategory.id)}
                        autoFocus
                        size="small"
                        sx={{ mr: 1 }}
                      />
                    ) : (
                      <GroupLabel
                        name={tertiaryCategory.name}
                        colorNumber={tertiaryCategory.colorNumber}
                      />
                    )}
                    <div style={{ marginLeft: "8px" }}>
                      <IconButton
                        onClick={() =>
                          handleEditTertiaryCategory(tertiaryCategory.id, tertiaryCategory.name)
                        }
                        size="small"
                        sx={{ p: 0, pr: "8px" }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      {index !== 0 && (
                        <IconButton
                          onClick={() =>
                            handleDeleteTertiaryCategory(
                              selectedPrimaryCategoryId,
                              secondaryCategory.id,
                              tertiaryCategory.id,
                            )
                          }
                          size="small"
                          sx={{ p: 0 }}
                        >
                          <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <Box className={classes.imageBoxBlock}>
                    {filteredPhotos &&
                      filteredPhotos.map((photo) => (
                        <Box
                          key={photo.id}
                          sx={{
                            position: "relative",
                            overflow: "hidden",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.grayScale[300]}`,
                          }}
                        >
                          <Box
                            sx={{
                              borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                              width: "215px",
                              height: "137px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              alt={`photo-${photo.id}`}
                              src={photo.thumbnailUrl}
                              loading="lazy"
                              style={{
                                width: "215px",
                                height: "137px",
                                objectFit: "cover",
                              }}
                              onClick={() => handleClickPhotoPreview(photo)}
                            />
                            {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                            <input
                              type="checkbox"
                              className={classes.checkbox}
                              checked={selectedPhotoIds.has(photo.id)}
                              onChange={() => handleSelectPhoto(photo.id)}
                            />
                            {props.isSharable && (
                              <LinkButton
                                sx={{ top: "8px", right: "40px", position: "absolute" }}
                                isEnabled={photo.isSharable}
                                onClick={() => handleClickImageLinkButton(photo)}
                              />
                            )}
                            <MoreOptionsButton onClick={(e) => handleMoreClick(e, photo)} />
                          </Box>
                        </Box>
                      ))}
                    <PhotoDropzone
                      tertiaryCategoryId={tertiaryCategory.id}
                      onDrop={handlePhotoUpload}
                    />
                  </Box>
                </React.Fragment>
              );
            })}
            <Button
              variant="outlined"
              onClick={() =>
                handleAddTertiaryCategory(selectedPrimaryCategoryId, selectedSecondaryCategoryId)
              }
              sx={{ width: "100%", fontWeight: "bold" }}
            >
              <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
              小項目を追加
            </Button>
          </Box>
        )}
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List sx={{ p: 0 }}>
          <ListItem sx={{ flexDirection: "column", alignItems: "flex-start", padding: "12px" }}>
            <ListItemText primary={<Typography fontSize={12}>アップロード</Typography>} />
            {selectedPhoto && (
              <>
                <ListItemText
                  primary={
                    <Typography fontSize={14} fontWeight="bold">
                      {formatDateUtil(selectedPhoto.createdAt, DATE_TIME_SLASH_FORMAT)}
                    </Typography>
                  }
                />
                <ListItemText
                  primary={<Typography fontSize={12}>{selectedPhoto.createdBy.name}</Typography>}
                />
              </>
            )}
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
