import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { autoPlacement, offset } from "@floating-ui/dom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  Divider,
  FormControlLabel,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { TagLabel } from "components/label/tag-label";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { RecurrenceRuleSettingModal } from "components/molecules/recurrence-rule-setting-modal";
import { SelectedMembers } from "components/molecules/selected-members";
import { ProjectId } from "data-access/repositories/project/project.dto";
import {
  KeyName,
  RecurrenceRule,
  RecurrenceRuleParams,
} from "data-access/repositories/recurrence_rule/recurrence_rule.dto";
import { RecurrenceRuleOptionsRepository } from "data-access/repositories/recurrence_rule_options/recurrence_rule_options.repository";
import {
  ScheduleCreateRequest,
  initialRecurrenceRule,
  initialScheduleCreateRequest,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { scheduleTypeRepository } from "data-access/repositories/schedule_type/schedule_type.repository";
import { theme } from "extensions/theme";
import { useScheduleMembers } from "hooks/schedule/useScheduleMembers";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { SWRInfiniteKeyLoader, unstable_serialize } from "swr/infinite";
import { calculatePeriodFromDate, calculatePeriodFromTime } from "utils/calculatePeriod";
import { handleStateError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";

const startDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -200, crossAxis: 280 }),
];
const endDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -340, crossAxis: 0 }),
];

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projectId: ProjectId;
  projectName: string;
  fetchKey: SWRInfiniteKeyLoader;
}

export const ProjectScheduleFormModal = (props: Props) => {
  const { isOpen, setIsOpen, projectId, projectName, fetchKey } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const [isChange, setIsChange] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<ScheduleCreateRequest>(initialScheduleCreateRequest);
  const [selectedRecurrenceRule, setSelectedRecurrenceRule] = useState<{
    keyName: KeyName;
    label: string;
  }>({ keyName: "none", label: "" });
  const [recurrenceSettingModalState, setRecurrenceSettingModalState] = useState<{
    isOpen: boolean;
    recurrenceRule?: RecurrenceRule;
  }>({
    isOpen: false,
  });

  const {
    data: recurrenceRuleOptions,
    mutate: recurrenceRuleOptionsMutate,
    isValidating: isLoadingRecurrenceRule,
  } = useSWR(
    isOpen && formState.startTime ? "/api/v1/recurrence_rule_options" : null,
    () =>
      RecurrenceRuleOptionsRepository.index({
        scheduleStartTime: formState.startTime ? formState.startTime : new Date().toString(),
      }),
    { revalidateOnFocus: false },
  );

  const { data: scheduleTypes, isLoading: isLoadingScheduleTypes } = useSWR(
    isOpen ? "/api/v1/schedule_types" : null,
    scheduleTypeRepository.index,
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (!isOpen || !scheduleTypes) return;

    setFormState({
      ...formState,
      projectId,
      name: projectName,
      scheduleTypeId: scheduleTypes[0].id,
      note: mainState.company.company_setting.schedule_note_template,
    });
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  }, [scheduleTypes]);

  // 開始日時を変更したら繰り返しプルダウンリストを更新する
  useEffect(() => {
    recurrenceRuleOptionsMutate();
  }, [formState.startTime]);

  // 繰り返し設定モーダルで設定した値を反映
  useEffect(() => {
    if (!recurrenceSettingModalState.recurrenceRule) return;
    setFormState({
      ...formState,
      recurrenceRule: {
        ...recurrenceSettingModalState.recurrenceRule.params,
        endDate: recurrenceSettingModalState.recurrenceRule.params.endDate || null,
      },
    });
    setSelectedRecurrenceRule({
      keyName: recurrenceSettingModalState.recurrenceRule.keyName,
      label: recurrenceSettingModalState.recurrenceRule.label,
    });
  }, [recurrenceSettingModalState.recurrenceRule]);

  const createSchedule = async () => {
    setIsLoading(true);
    try {
      await scheduleRepository.create(formState);
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("予定を作成しました"));
      mutate(unstable_serialize(fetchKey));
      setErrorMessage("");
    } catch (error) {
      handleStateError(error, setErrorMessage, "予定の作成に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const {
    selectedCompanyUsers,
    inputValue,
    allOptions,
    selectableSiteManagers,
    isLoading: isLoadingMembers,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  } = useScheduleMembers({
    isModalOpen: props.isOpen,
    startTime: formState.startTime,
    endTime: formState.endTime,
    onMembersChange: (newUserIds) => {
      setFormState({ ...formState, userIds: newUserIds });
      setIsChange(true);
    },
    onCreateSchedule: createSchedule,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | null>,
  ) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime": {
        // 日付を変更したときはeが存在する
        const { startDateTime, endDateTime } = (
          e ? calculatePeriodFromDate : calculatePeriodFromTime
        )(
          date,
          formState.startTime ? new Date(formState.startTime) : null,
          formState.endTime ? new Date(formState.endTime) : null,
        );

        setFormState({
          ...formState,
          startTime: startDateTime?.toString() || "",
          endTime: endDateTime.toString(),
        });
        break;
      }
      case "endTime":
        return setFormState({ ...formState, endTime: date.toString() });
    }
  };

  const handleChangeRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    handleCreateWithOverlapCheck();
  };

  const handleClose = () => {
    setIsOpen(false);
    resetMembers();
    setErrorMessage("");
    setFormState(initialScheduleCreateRequest);
    setIsChange(false);
    setIsDeleteModalOpen(false);
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  };

  const handleChangeRecurrence = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    setIsChange(true);
    const { value } = e.target;
    if (value === "custom") {
      setRecurrenceSettingModalState({
        isOpen: true,
        recurrenceRule: {
          keyName: selectedRecurrenceRule.keyName,
          label: selectedRecurrenceRule.label,
          params: formState.recurrenceRule || initialRecurrenceRule,
        },
      });
      return;
    }

    switch (value) {
      case "none":
        setFormState({ ...formState, recurrenceRule: undefined });
        break;
      default: {
        const dataParams: RecurrenceRuleParams = (child as ReactElement).props["data-params"];
        setFormState({
          ...formState,
          recurrenceRule: { ...dataParams, endDate: dataParams.endDate || "" },
        });
        break;
      }
    }
    setSelectedRecurrenceRule({
      keyName: value as KeyName,
      label: (child as ReactElement).props.children,
    });
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleClose}
        handleNo={() => setIsDeleteModalOpen(false)}
        content="作成せず破棄しますか？"
        yesButtonText="破棄する"
        noButtonText="キャンセル"
        yesButtonColor="error"
      />

      <ConfirmDialog
        isOpen={isOverlapConfirmModalOpen}
        handleYes={async () => {
          handleCancelOverlapConfirm();
          await createSchedule();
        }}
        handleNo={handleCancelOverlapConfirm}
        content={`他の予定と時間が重複しているメンバーがいますが、予定を保存しますか？`}
        yesButtonText="保存"
      />

      <RecurrenceRuleSettingModal
        isOpen={recurrenceSettingModalState.isOpen}
        recurrenceRule={formState.recurrenceRule || undefined}
        setRecurrenceSettingModalState={(isOpen, recurrenceRule) =>
          setRecurrenceSettingModalState({ isOpen, recurrenceRule })
        }
        onClose={() =>
          setRecurrenceSettingModalState({
            isOpen: false,
            recurrenceRule: recurrenceSettingModalState.recurrenceRule,
          })
        }
        startDate={formState.startTime || ""}
      />

      <CustomModal
        title="予定の新規作成"
        open={isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        maxWidth="sm"
        footer={
          <>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "10rem" }}
            >
              キャンセル
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存
            </Button>
          </>
        }
      >
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoadingScheduleTypes || isLoadingRecurrenceRule || isLoadingMembers}
          invisible
        >
          <CircularProgress />
        </Backdrop>
        <div style={{ display: "flex", marginBottom: "16px", gap: "16px" }}>
          <TextField
            id="name"
            name="name"
            label="予定名"
            variant="standard"
            value={formState.name}
            onChange={handleChange}
            sx={{ width: "400px" }}
          />
          <Select
            id="scheduleTypeId"
            name="scheduleTypeId"
            variant="standard"
            value={formState.scheduleTypeId.toString()}
            onChange={handleSelectChange}
            sx={{ minWidth: "100px" }}
          >
            {scheduleTypes?.map((scheduleType) => (
              <MenuItem key={scheduleType.id} value={scheduleType.id}>
                <TagLabel tagName={scheduleType.name} colorNumber={scheduleType.color_number} />
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", gap: "12px" }}>
            <ScheduleIcon />
            <div>
              <div style={{ display: "flex" }}>
                <CustomDatePicker
                  id="startTime"
                  name="startTime"
                  date={formState.startTime ? new Date(formState.startTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={startDatePopperPosition}
                  placeholder="開始日時"
                />
                <p>〜</p>
                <CustomDatePicker
                  id="endTime"
                  name="endTime"
                  date={formState.endTime ? new Date(formState.endTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={endDatePopperPosition}
                  placeholder="終了日時"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                  繰り返し・長期予定
                </Typography>
                <Select
                  value={selectedRecurrenceRule.keyName}
                  variant="standard"
                  onChange={handleChangeRecurrence}
                  disabled={!formState.startTime || !formState.endTime}
                  sx={{ minWidth: "70px", maxWidth: "70%" }}
                >
                  {recurrenceRuleOptions?.map((option: RecurrenceRule, index) => (
                    <MenuItem key={index} value={option.keyName} data-params={option.params}>
                      {option.label}
                    </MenuItem>
                  ))}
                  {recurrenceSettingModalState.recurrenceRule &&
                    recurrenceSettingModalState.recurrenceRule.label !== "" && (
                      <MenuItem
                        value="customized"
                        data-params={recurrenceSettingModalState.recurrenceRule.params}
                      >
                        {recurrenceSettingModalState.recurrenceRule.label}
                      </MenuItem>
                    )}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "12px" }}>
          <Person2OutlinedIcon sx={{ mt: "16px" }} />
          <div style={{ width: "100%", overflow: "hidden" }}>
            <div style={{ marginBottom: "12px" }}>
              <SelectedMembers
                allOptions={allOptions}
                selectedCompanyUsers={selectedCompanyUsers}
                selectedSiteManagerId={formState.siteManagerId}
                inputValue={inputValue}
                setInputValue={setInputValue}
                onChangeUsers={handleChangeUsers}
                handleClear={handleClear}
                filterOptions={filterOptions}
                groupByFunction={groupByFunction}
                popperPlacement="top-end"
              />
            </div>

            <FormControlLabel
              label="予定を確定"
              control={
                <Checkbox
                  name="isConfirmed"
                  onChange={handleChangeRadioButton}
                  sx={{
                    "& input": { zIndex: 0 },
                  }}
                />
              }
              checked={formState.isConfirmed}
              sx={{
                mb: "20px",
                "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold" },
              }}
            />

            <FormControl variant="standard" fullWidth>
              <InputLabel id="site-manager-select-label">現場責任者を選択</InputLabel>
              <Select
                labelId="site-manager-select-label"
                id="siteManagerId"
                name="siteManagerId"
                value={formState.siteManagerId || 0}
                onChange={(event) => handleChange(event)}
              >
                <MenuItem value={0}>未設定</MenuItem>
                {selectableSiteManagers?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "16px" }}>
          <FormatAlignLeftIcon />
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
              <CustomFormLabel labelName="作業内容・予定メモ" />
              <Button
                variant="outlined"
                onClick={() => setFormState({ ...formState, note: "" })}
                sx={{ height: "32px", width: "98px", fontSize: "11px", fontWeight: "bold" }}
              >
                内容をクリア
              </Button>
            </div>
            <TextField
              id="note"
              name="note"
              multiline
              rows={4}
              value={formState.note}
              onChange={handleChange}
              fullWidth
              sx={{ mb: "8px" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <LockIcon fontSize="small" sx={{ mr: "16px" }} />
          <FormControlLabel
            label="予定を非公開にする"
            control={<Checkbox name="isPrivate" onChange={handleChangeRadioButton} />}
            checked={formState.isPrivate}
            sx={{
              "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold", mr: "0px" },
            }}
          />
          <Tooltip
            title="予定を非公開にすると、予定の作成者と参加者以外が予定の詳細を見ることができなくなります。"
            placement="top"
          >
            <HelpOutlineIcon
              sx={{ fontSize: "17px", mt: "2px", color: theme.palette.primary.main }}
            />
          </Tooltip>
        </div>

        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};
