import { PrimaryCategoryId } from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { tertiaryCategoryRepository } from "data-access/repositories/project/tertiary_category/tertiary_category.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleReduxError } from "utils/errorHandler";

export const useCategoryCreate = (
  projectId: ProjectId,
  primaryCategoryMutate: () => void,
  secondaryCategoryMutate: () => void,
) => {
  const dispatch = useAppDispatch();

  const handleAddPrimaryCategory = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await primaryCategoryRepository.create(projectId);
      primaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("大項目を追加しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "大項目の追加に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAddSecondaryCategory = async (primaryCategoryId: PrimaryCategoryId) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await secondaryCategoryRepository.create(projectId, primaryCategoryId);
      primaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("中項目を追加しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "中項目の追加に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAddTertiaryCategory = async (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await tertiaryCategoryRepository.create(projectId, primaryCategoryId, secondaryCategoryId);
      secondaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("小項目を追加しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "小項目の追加に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return {
    handleAddPrimaryCategory,
    handleAddSecondaryCategory,
    handleAddTertiaryCategory,
  };
};
