import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import {
  Box,
  MenuItem,
  FormControl,
  Typography,
  Select,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectPhotosModal } from "components/molecules/project-photos-modal";
import { PhotoResponseSnakeCase } from "data-access/repositories/project/photo/photo.dto";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { ProjectType } from "data-access/repositories/project_type/project_type.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import { mutate } from "swr";
import { ProjectSidebarDateBlock } from "../project-sidebar-date-block";

interface Props {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  state: ProjectSidebarState;
  projectTypes: ProjectType[];
  projectForm: ProjectUpdateRequest;
  photoInfo: { first_three_photos: PhotoResponseSnakeCase[]; total_count: number };
  isPhotoDisplay: boolean;
  users: User[];
  activityFetchKey: string;
}

export const ProjectSidebarTitle = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const {
    onChange,
    onBlur,
    state,
    projectTypes,
    projectForm,
    isPhotoDisplay,
    users,
    activityFetchKey,
  } = props;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    dispatch(projectSidebarOperations.showProject(state.project.id));
    mutate(activityFetchKey);
  };

  return (
    <>
      <ProjectPhotosModal open={open} handleOpen={handleOpen} project={state.project} />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: "24px" }}>
        <div>
          <Box sx={{ display: "flex", height: "70px", gap: "24px", mb: "24px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CustomFormLabel labelName="案件番号" small />
              {/* 案件番号の書式が空欄の場合は手入力となる */}
              {mainState.company.company_setting.project_code_format === "" ? (
                <TextField
                  size="small"
                  id="code"
                  name="code"
                  value={projectForm.code}
                  onChange={onChange}
                  onBlur={onBlur}
                  sx={{
                    width: "120px",
                  }}
                />
              ) : (
                <Typography sx={{ fontWeight: "500" }}>{projectForm.code}</Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CustomFormLabel labelName="先方注文番号" small />
              <TextField
                size="small"
                id="inquiryNumber"
                name="inquiryNumber"
                value={projectForm.inquiryNumber}
                type="string"
                onChange={onChange}
                onBlur={onBlur}
                sx={{
                  width: "120px",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CustomFormLabel labelName="現場責任者" small />
              <Select
                id="managerId"
                name="managerId"
                variant="standard"
                value={projectForm.managerId}
                onChange={(e) => onChange(e)}
                sx={{
                  fontWeight: "bold",
                  width: "120px",
                  "&.MuiInputBase-root:before": {
                    borderBottom: "none",
                  },
                }}
                renderValue={(value) => {
                  const user = users.find((user) => user.id === value);
                  return user?.name;
                }}
              >
                {users.map(
                  (user) =>
                    !user.is_deactivate && (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ),
                )}
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CustomFormLabel labelName="受付担当者" small />
              <Select
                id="inquiredById"
                name="inquiredById"
                variant="standard"
                value={projectForm.inquiredById}
                onChange={(e) => onChange(e)}
                sx={{
                  fontWeight: "bold",
                  width: "120px",
                  "&.MuiInputBase-root:before": {
                    borderBottom: "none",
                  },
                }}
                renderValue={(value) => {
                  const user = users.find((user) => user.id === value);
                  return user?.name;
                }}
              >
                {users.map(
                  (user) =>
                    !user.is_deactivate && (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ),
                )}
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CustomFormLabel labelName="案件タイプ" small />
              <FormControl size="small">
                <Select
                  id="projectTypeId"
                  name="projectTypeId"
                  variant="standard"
                  value={projectForm.projectTypeId}
                  onOpen={() => dispatch(mainOperations.getProjectTypes())}
                  onChange={(e) => onChange(e)}
                  sx={{
                    fontWeight: "bold",
                    width: "120px",
                    "&.MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                  }}
                >
                  {projectTypes.map((projectType: ProjectType, index) => (
                    <MenuItem key={index} value={projectType.id}>
                      {projectType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {!isPhotoDisplay && (
              <Box sx={{ display: "flex", alignItems: "center", ml: "0.5rem" }}>
                <ImageIcon sx={{ fontSize: "3.8rem", cursor: "not-allowed" }} />
              </Box>
            )}
            {isPhotoDisplay && (
              <Box
                onClick={handleOpen}
                sx={{ display: "flex", alignItems: "end", cursor: "pointer" }}
              >
                {props.photoInfo.total_count === 0 ? (
                  <ImageIcon sx={{ fontSize: "3.8rem", cursor: "pointer" }} />
                ) : (
                  props.photoInfo.total_count !== 0 && (
                    <>
                      {props.photoInfo.first_three_photos.map((photo) => (
                        <Box
                          key={photo.id}
                          component="img"
                          sx={{
                            height: 44,
                            width: 44,
                            maxHeight: 44,
                            maxWidth: 44,
                            pr: 0.2,
                          }}
                          alt={photo.file_name}
                          src={photo.thumbnail_url}
                        />
                      ))}
                    </>
                  )
                )}
                {props.photoInfo.total_count > 3 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      p: "0.5rem",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.primary.main,
                      width: "43px",
                      height: "43px",
                    }}
                  >
                    <Typography sx={{ m: 0, color: theme.palette.grayScale[0] }}>{`+${
                      props.photoInfo.total_count - 3
                    }`}</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <ProjectSidebarDateBlock state={state} />
        </div>
      </Box>
    </>
  );
};
