import { createSlice } from "@reduxjs/toolkit";
import { dropGridSchedule, destroy } from "store/calendar/actions";
import type { RootState } from "../store";

interface CalendarState {
  isLoading: boolean;
  isSubmitted: boolean;
  isDropped: boolean;
  isDestroyed: boolean;
  successMessage: string;
  errorMessage: string;
}

const initialState: CalendarState = {
  isLoading: false,
  isSubmitted: false,
  isDropped: false,
  isDestroyed: false,
  successMessage: "",
  errorMessage: "",
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    deleteSnackbarMessage: (state) => ({
      ...state,
      successMessage: "",
      errorMessage: "",
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(dropGridSchedule.pending, (state) => {
      state.isLoading = true;
      state.isDropped = false;
    });
    builder.addCase(dropGridSchedule.fulfilled, (state) => {
      state.isLoading = false;
      state.isDropped = true;
      state.successMessage = "予定を更新しました";
    });
    builder.addCase(dropGridSchedule.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroy.pending, (state) => {
      state.isLoading = true;
      state.isDestroyed = false;
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoading = false;
      state.isDestroyed = true;
      state.successMessage = "予定を削除しました";
    });
    builder.addCase(destroy.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectCalendar = (state: RootState) => state.calendar;
export default calendarSlice.reducer;
