import { useEffect, useState } from "react";
import { Range } from "react-date-range";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CustomDateRangePicker } from "components/molecules/custom-date-range-picker";
import { SearchTextField } from "components/molecules/search-text-field";
import { getFilterItem, setFilterItem } from "data-access/local_storage/table_filter/local_storage";
import {
  DefaultProjectFilter,
  DefaultProjectType,
} from "data-access/local_storage/table_filter/local_storage.dto";
import { ProjectIndexRequest } from "data-access/repositories/project/project.dto";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
} from "data-access/repositories/project_status_type/project_status_type.dto";
import { ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { theme } from "extensions/theme";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { projectTableOperations } from "store/project-table/operations";
import { selectProjectTable } from "store/project-table/slice";
import { projectTableHeaderOperations } from "store/project-table-header/operations";
import { selectProjectTableHeader } from "store/project-table-header/slice";
import { formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";

export const ProjectTableHeader = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectTableHeader);
  const mainState = useAppSelector(selectMain);
  const tableState = useAppSelector(selectProjectTable);
  const filterItemLocalStorage = getFilterItem("project");

  useEffect(() => {
    if (!mainState.projectStatusTypes.length || !mainState.projectTypes.length) return;
    const updatedFilterItem = { ...filterItemLocalStorage };
    const shouldUpdateState = {
      projectType: false,
      projectStatus: false,
    };

    // 案件タイプとステータスの存在チェックと無効な値のチェック
    if (
      filterItemLocalStorage.projectTypeIds.length === 0 ||
      filterItemLocalStorage.projectTypeIds.some((id) => !allProjectTypeIds.includes(id))
    ) {
      updatedFilterItem.projectTypeIds = allProjectTypeIds;
      shouldUpdateState.projectType = true;
    }

    if (
      filterItemLocalStorage.projectStatusTypeIds.length === 0 ||
      filterItemLocalStorage.projectStatusTypeIds.some((id) => !allProjectStatusIds.includes(id))
    ) {
      updatedFilterItem.projectStatusTypeIds = allProjectStatusIds;
      shouldUpdateState.projectStatus = true;
    }

    // 状態の更新が必要な場合のみ更新
    if (shouldUpdateState.projectType) {
      setFilterProjectTypeIds(updatedFilterItem.projectTypeIds);
    } else {
      setFilterProjectTypeIds(filterItemLocalStorage.projectTypeIds);
    }

    if (shouldUpdateState.projectStatus) {
      setFilterProjectStatusIds(updatedFilterItem.projectStatusTypeIds);
    } else {
      setFilterProjectStatusIds(filterItemLocalStorage.projectStatusTypeIds);
    }

    if (shouldUpdateState.projectType || shouldUpdateState.projectStatus) {
      saveFilterItem(updatedFilterItem);
    }
  }, [mainState.projectStatusTypes, mainState.projectTypes]);

  const allProjectStatusIds: ProjectStatusTypeId[] = mainState.projectStatusTypes.map(
    (projectStatus) => projectStatus.id,
  );
  const allProjectTypeIds: ProjectTypeId[] = mainState.projectTypes.map(
    (projectType) => projectType.id,
  );
  const completedProjectStatus: ProjectStatusType | undefined = mainState.projectStatusTypes.find(
    (projectStatus) => {
      return projectStatus.reserved_type === "completed";
    },
  );
  const orderFailedProjectStatus: ProjectStatusType | undefined = mainState.projectStatusTypes.find(
    (projectStatus) => {
      return projectStatus.reserved_type === "order_failed";
    },
  );
  const noReservedProjectStatuses: ProjectStatusType[] = mainState.projectStatusTypes.filter(
    (projectStatus) => {
      return projectStatus.reserved_type === "no_reserved";
    },
  );

  const [filterProjectTypeIds, setFilterProjectTypeIds] = useState<ProjectTypeId[]>(
    DefaultProjectFilter.projectTypeIds,
  );
  const [filterProjectStatusIds, setFilterProjectStatusIds] = useState<ProjectStatusTypeId[]>(
    DefaultProjectFilter.projectStatusTypeIds,
  );
  const [inquiredDateRange, setInquiredDateRange] = useState<Range>(
    DefaultProjectFilter.inquiredDate.range,
  );
  const [inquiredDateLabel, setInquiredDateLabel] = useState<string>(
    DefaultProjectFilter.inquiredDate.label,
  );
  const [orderedDateRange, setOrderedDateRange] = useState<Range>(
    DefaultProjectFilter.orderedDate.range,
  );
  const [orderedDateLabel, setOrderedDateLabel] = useState<string>(
    DefaultProjectFilter.orderedDate.label,
  );
  const [completedDateRange, setCompletedDateRange] = useState<Range>(
    DefaultProjectFilter.completedDate.range,
  );
  const [completedDateLabel, setCompletedDateLabel] = useState<string>(
    DefaultProjectFilter.completedDate.label,
  );
  const [billingConditions, setBillingConditions] = useState<string[]>(
    DefaultProjectFilter.billingConditions,
  );
  const [keyword, setKeyword] = useState<string>(DefaultProjectFilter.keyword);

  useEffect(() => {
    const filterStorageKeyName = "projectTableFilter";
    const localStorageItem = localStorage.getItem(filterStorageKeyName);

    // 検索条件を常にLocalStorageに保存するため
    if (localStorageItem === null) {
      localStorage.setItem(filterStorageKeyName, JSON.stringify(DefaultProjectFilter));
    } else {
      setInquiredDateRange(filterItemLocalStorage.inquiredDate.range);
      setInquiredDateLabel(filterItemLocalStorage.inquiredDate.label);
      setOrderedDateRange(filterItemLocalStorage.orderedDate.range);
      setOrderedDateLabel(filterItemLocalStorage.orderedDate.label);
      setCompletedDateRange(filterItemLocalStorage.completedDate.range);
      setCompletedDateLabel(filterItemLocalStorage.completedDate.label);
      setBillingConditions(filterItemLocalStorage.billingConditions);
      setKeyword(filterItemLocalStorage.keyword);
    }
  }, []);

  const saveFilterItem = (item: DefaultProjectType) => {
    setFilterItem("project", { ...item });
    dispatch(projectTableHeaderOperations.addCountChanges());
  };

  useEffect(() => {
    if (state.isSubmitted) {
      dispatch(projectSidebarOperations.showProject(state.respondProject.id));
      dispatch(projectSidebarOperations.open());
      dispatch(projectTableHeaderOperations.updateIsSubmitted(false));
    }
  }, [state.isSubmitted]);

  const handleCreateButtonClick = () => {
    navigate("/projects/new");
  };

  const searchQuery = (page?: number) => {
    const query: ProjectIndexRequest = {
      projectTypeIds: filterItemLocalStorage.projectTypeIds,
      projectStatusTypeIds: filterItemLocalStorage.projectStatusTypeIds,
      billingConditions: filterItemLocalStorage.billingConditions,
      page: page ?? tableState.currentPage,
    };
    if (filterItemLocalStorage.keyword !== "") {
      query.keyword = filterItemLocalStorage.keyword;
    }
    if (filterItemLocalStorage.inquiredDate.range.startDate) {
      query.startInquiredDate = formatDateUtil(
        filterItemLocalStorage.inquiredDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItemLocalStorage.inquiredDate.range.endDate) {
      query.endInquiredDate = formatDateUtil(
        filterItemLocalStorage.inquiredDate.range.endDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItemLocalStorage.orderedDate.range.startDate) {
      query.startOrderedDate = formatDateUtil(
        filterItemLocalStorage.orderedDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItemLocalStorage.orderedDate.range.endDate) {
      query.endOrderedDate = formatDateUtil(
        filterItemLocalStorage.orderedDate.range.endDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItemLocalStorage.completedDate.range.startDate) {
      query.startCompletedDate = formatDateUtil(
        filterItemLocalStorage.completedDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItemLocalStorage.completedDate.range.endDate) {
      query.endCompletedDate = formatDateUtil(
        filterItemLocalStorage.completedDate.range.endDate,
        "yyyy-MM-dd",
      );
    }
    return query;
  };

  const handleSubmit = () => {
    dispatch(projectTableOperations.updateCurrentPage(1));
    dispatch(projectTableOperations.getIndex(searchQuery(1)));
  };

  const handleChangeBillingCondition = (event: SelectChangeEvent<typeof billingConditions>) => {
    const {
      target: { value },
    } = event;
    if (typeof value !== "string") {
      const saveItems: string[] = [];
      if (value.length === 0) {
        saveItems.push("no_bill");
      } else if (billingConditions.length === 5 && value.length === 4) {
        // 「すべての請求状況」状態からの選択
        const removedItem = billingConditions.filter((v) => {
          return !value.includes(v);
        });
        saveItems.push(...removedItem);
      } else if (value.includes("all")) {
        saveItems.push(...DefaultProjectFilter.billingConditions);
      } else {
        saveItems.push(...value);
      }

      if (saveItems.length > 0) {
        setBillingConditions(saveItems);
        filterItemLocalStorage.billingConditions = saveItems;
        setFilterItem("project", filterItemLocalStorage);
      }
    }
  };

  const handleChangeProjectType = (event: SelectChangeEvent<(ProjectTypeId | string)[]>) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      return;
    } else if (value.includes("all")) {
      setFilterProjectTypeIds(allProjectTypeIds);
      filterItemLocalStorage.projectTypeIds = allProjectTypeIds;
      setFilterItem("project", filterItemLocalStorage);
    } else if (filterProjectTypeIds.length === mainState.projectTypes.length) {
      // すべてのステータスを選択時に、いずれかのステータスを選択
      const selectProjectTypeId: ProjectTypeId[] = mainState.projectTypes
        .map((projectType) => projectType.id)
        .filter((id) => {
          return !(value as number[]).includes(id);
        });
      setFilterProjectTypeIds(selectProjectTypeId);
      filterItemLocalStorage.projectTypeIds = selectProjectTypeId;
      setFilterItem("project", filterItemLocalStorage);
    } else {
      setFilterProjectTypeIds(value as ProjectTypeId[]);
      filterItemLocalStorage.projectTypeIds = value as ProjectTypeId[];
      setFilterItem("project", filterItemLocalStorage);
    }
  };

  const handleChangeProjectStatus = (
    event: SelectChangeEvent<(ProjectStatusTypeId | string)[]>,
  ) => {
    const {
      target: { value },
    } = event;

    if (value.length === 0) {
      return;
    } else if (value.includes("all")) {
      // 「すべてのステータス」を選択
      setFilterProjectStatusIds(allProjectStatusIds);
      filterItemLocalStorage.projectStatusTypeIds = allProjectStatusIds;
      setFilterItem("project", filterItemLocalStorage);
    } else if (value.includes("not_completed_and_order_failed")) {
      // 「完了・失注以外」を選択
      setFilterProjectStatusIds(noReservedProjectStatuses.map((statusType) => statusType.id));
      filterItemLocalStorage.projectStatusTypeIds = noReservedProjectStatuses.map(
        (statusType) => statusType.id,
      );
      setFilterItem("project", filterItemLocalStorage);
    } else if (filterProjectStatusIds.length === mainState.projectStatusTypes.length) {
      // すべてのステータスを選択時に、いずれかのステータスを選択
      const selectProjectStatusTypeId: ProjectStatusTypeId[] = allProjectStatusIds.filter((id) => {
        return !(value as number[]).includes(id);
      });
      setFilterProjectStatusIds(selectProjectStatusTypeId);
      filterItemLocalStorage.projectStatusTypeIds = selectProjectStatusTypeId;
      setFilterItem("project", filterItemLocalStorage);
    } else {
      // ひとつひとつのステータスを選択
      setFilterProjectStatusIds(value as ProjectStatusTypeId[]);
      filterItemLocalStorage.projectStatusTypeIds = value as ProjectStatusTypeId[];
      setFilterItem("project", filterItemLocalStorage);
    }
  };

  const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setKeyword(e.target.value as string);
    filterItemLocalStorage.keyword = e.target.value as string;
    setFilterItem("project", filterItemLocalStorage);
  };

  const filterBillingConditions: { id: string; name: string }[] = [
    { id: "all_not_yet_bill", name: "未請求" },
    { id: "partially_not_yet_bill", name: "一部未請求" },
    { id: "all_billed", name: "すべて請求済み" },
    { id: "managing_billing_on_another_system", name: "別システムで管理" },
    { id: "no_bill", name: "請求しない" },
  ];

  const isSelectedProjectTypeIds = (id: ProjectTypeId): boolean => {
    return filterProjectTypeIds.includes(id);
  };
  const filteredProjectTypeName = (): string => {
    if (filterProjectTypeIds.length === mainState.projectTypes.length) return "すべての案件タイプ";
    return mainState.projectTypes
      .filter((projectType) => isSelectedProjectTypeIds(projectType.id))
      .map((projectType) => projectType.name)
      .join(", ");
  };

  const isSelectedProjectStatusType = (id: ProjectStatusTypeId): boolean => {
    return filterProjectStatusIds.includes(id);
  };

  const filteredProjectStatusTypeName = (): string => {
    if (filterProjectStatusIds.length === mainState.projectStatusTypes.length)
      return "すべての案件ステータス";
    return mainState.projectStatusTypes
      .filter((projectStatusType) => isSelectedProjectStatusType(projectStatusType.id))
      .map((projectStatusType) => projectStatusType.name)
      .join(", ");
  };

  const isSelectedBillingCondition = (id: string) => {
    return filterItemLocalStorage.billingConditions.includes(id);
  };
  const filteredBillingCondition: string = filterBillingConditions
    .filter((billingCondition) => isSelectedBillingCondition(billingCondition.id))
    .map((billingCondition) => billingCondition.name)
    .join(", ");

  const handleChangeInquiredDateRange = (range: Range) => {
    setInquiredDateRange(range);
    filterItemLocalStorage.inquiredDate.range = range;
    if (range.startDate && range.endDate) {
      const label =
        "受付日:" +
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
        "-" +
        formatDateUtil(range.endDate, "yyyy年MM月dd日");

      setInquiredDateLabel(label);
      filterItemLocalStorage.inquiredDate.label = label;
    } else {
      setInquiredDateLabel("すべての受付日");
      filterItemLocalStorage.inquiredDate.label = "すべての受付日";
    }
    saveFilterItem(filterItemLocalStorage);
  };

  const handleChangeOrderedDateRange = (range: Range) => {
    setOrderedDateRange(range);
    filterItemLocalStorage.orderedDate.range = range;
    if (range.startDate && range.endDate) {
      const label =
        "受注日:" +
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
        "-" +
        formatDateUtil(range.endDate, "yyyy年MM月dd日");
      setOrderedDateLabel(label);
      filterItemLocalStorage.orderedDate.label = label;
    } else {
      setOrderedDateLabel("すべての受注日");
      filterItemLocalStorage.orderedDate.label = "すべての受注日";
    }
    saveFilterItem(filterItemLocalStorage);
  };

  const handleChangeCompletedDateRange = (range: Range) => {
    setCompletedDateRange(range);
    filterItemLocalStorage.completedDate.range = range;
    if (range.startDate && range.endDate) {
      const label =
        "完了日:" +
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
        "-" +
        formatDateUtil(range.endDate, "yyyy年MM月dd日");
      setCompletedDateLabel(label);
      filterItemLocalStorage.completedDate.label = label;
    } else {
      setCompletedDateLabel("すべての完了日");
      filterItemLocalStorage.completedDate.label = "すべての完了日";
    }
    saveFilterItem(filterItemLocalStorage);
  };

  const handleClose = () => {
    dispatch(projectTableHeaderOperations.addCountChanges());
  };

  const notYetBilledConditions = ["all_not_yet_bill", "partially_not_yet_bill"];

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setFilterProjectStatusIds(completedProjectStatus?.id ? [completedProjectStatus.id] : []);
      setBillingConditions(notYetBilledConditions);
      saveFilterItem({
        ...DefaultProjectFilter,
        projectStatusTypeIds: completedProjectStatus?.id ? [completedProjectStatus.id] : [],
        billingConditions: notYetBilledConditions,
      });
    } else {
      setFilterProjectStatusIds(allProjectStatusIds);
      setBillingConditions(DefaultProjectFilter.billingConditions);
      saveFilterItem({
        ...DefaultProjectFilter,
        projectStatusTypeIds: allProjectStatusIds,
        billingConditions: DefaultProjectFilter.billingConditions,
      });
    }
  };

  const conditionClear = () => {
    setFilterProjectTypeIds(allProjectTypeIds);
    setFilterProjectStatusIds(allProjectStatusIds);
    setInquiredDateRange(DefaultProjectFilter.inquiredDate.range);
    setInquiredDateLabel(DefaultProjectFilter.inquiredDate.label);
    setOrderedDateRange(DefaultProjectFilter.orderedDate.range);
    setOrderedDateLabel(DefaultProjectFilter.orderedDate.label);
    setCompletedDateRange(DefaultProjectFilter.completedDate.range);
    setCompletedDateLabel(DefaultProjectFilter.completedDate.label);
    setBillingConditions(DefaultProjectFilter.billingConditions);
    setKeyword(DefaultProjectFilter.keyword);
    saveFilterItem({
      ...DefaultProjectFilter,
      projectTypeIds: allProjectTypeIds,
      projectStatusTypeIds: allProjectStatusIds,
    });
  };

  // 請求チェックのフラグ
  const IsCheckMissedBilling = (): boolean => {
    const sortedBillingConditions = filterItemLocalStorage.billingConditions.sort();
    // 並び替えた送付ステータスフィルタと、未請求、一部未請求の配列が一致するか
    const isBillingConditionsMatch =
      JSON.stringify(sortedBillingConditions) === JSON.stringify(notYetBilledConditions);
    // 案件ステータスフィルタが完了のみか
    const isProjectStatusTypeIdMatch =
      JSON.stringify(filterItemLocalStorage.projectStatusTypeIds) ===
      JSON.stringify([completedProjectStatus?.id]);

    // 送付ステータスフィルタのチェックが「未請求」「一部未請求」のみで、案件ステータスが「完了」のみの場合にtrueを返す
    return isBillingConditionsMatch && isProjectStatusTypeIdMatch;
  };

  return (
    <Box sx={{ display: "flex" }} className={classes.tableHeader}>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Select
          labelId="projectTypes"
          id="projectTypes"
          name="projectTypes"
          value={filterProjectTypeIds}
          onChange={handleChangeProjectType}
          onClose={handleClose}
          renderValue={filteredProjectTypeName}
          sx={{ mr: "16px", minWidth: "160px", mb: "8px" }}
          multiple
        >
          <MenuItem
            value="all"
            disabled={
              tableState.isLoading ||
              getFilterItem("project").projectTypeIds.length === mainState.projectTypes.length
            }
          >
            すべての案件タイプ
          </MenuItem>
          {mainState.projectTypes.map((projectType) => {
            return (
              <MenuItem key={projectType.id} value={projectType.id}>
                {isSelectedProjectTypeIds(projectType.id) ? (
                  <CheckIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <Box sx={{ px: 1.5, mr: 1 }} />
                )}
                {projectType.name}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          labelId="projectStatusTypeIds"
          id="projectStatusTypeIds"
          name="projectStatusTypeIds"
          value={filterProjectStatusIds}
          onChange={handleChangeProjectStatus}
          onClose={handleClose}
          renderValue={filteredProjectStatusTypeName}
          sx={{ minWidth: "160px", mr: "16px", mb: "8px" }}
          multiple
        >
          <MenuItem
            value="all"
            disabled={
              tableState.isLoading ||
              getFilterItem("project").projectStatusTypeIds.length ===
                mainState.projectStatusTypes.length
            }
          >
            すべてのステータス
          </MenuItem>
          <MenuItem value="not_completed_and_order_failed" disabled={tableState.isLoading}>
            {`${completedProjectStatus?.name}・${orderFailedProjectStatus?.name}以外`}
          </MenuItem>
          {mainState.projectStatusTypes.map((projectStatusType) => {
            return (
              <MenuItem
                key={projectStatusType.id}
                value={projectStatusType.id}
                disabled={tableState.isLoading}
              >
                {isSelectedProjectStatusType(projectStatusType.id) ? (
                  <CheckIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <Box sx={{ px: 1.5, mr: 1 }} />
                )}
                {projectStatusType.name}
              </MenuItem>
            );
          })}
        </Select>

        <CustomDateRangePicker
          sx={{ mr: "16px", mb: "8px" }}
          dateName="受付日"
          dateRangeLabel={inquiredDateLabel}
          dateRange={inquiredDateRange}
          handleChangeDateRange={handleChangeInquiredDateRange}
        />
        <CustomDateRangePicker
          sx={{ mr: "16px", mb: "8px" }}
          dateName="受注日"
          dateRangeLabel={orderedDateLabel}
          dateRange={orderedDateRange}
          handleChangeDateRange={handleChangeOrderedDateRange}
        />
        <CustomDateRangePicker
          sx={{ mr: "16px", mb: "8px" }}
          dateName="完了日"
          dateRangeLabel={completedDateLabel}
          dateRange={completedDateRange}
          handleChangeDateRange={handleChangeCompletedDateRange}
        />

        <Select
          labelId="billingCondition"
          id="billingCondition"
          name="billingConditions"
          multiple
          value={billingConditions}
          onChange={handleChangeBillingCondition}
          onClose={handleClose}
          style={{ minWidth: "160px", marginRight: "16px", marginBottom: "8px" }}
          renderValue={() => filteredBillingCondition}
        >
          <MenuItem
            value="all"
            disabled={
              tableState.isLoading || getFilterItem("project").billingConditions.length === 5
            }
          >
            すべての請求状況
          </MenuItem>
          {filterBillingConditions.map((billingCondition) => {
            return (
              <MenuItem
                key={billingCondition.id}
                value={billingCondition.id}
                disabled={tableState.isLoading}
              >
                {isSelectedBillingCondition(billingCondition.id) ? (
                  <CheckIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <Box sx={{ px: 1.5, mr: 1 }} />
                )}
                {billingCondition.name}
              </MenuItem>
            );
          })}
        </Select>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <SearchTextField
            id="keyword"
            name="keyword"
            value={keyword}
            onChange={handleChangeKeyword}
            onSubmit={handleSubmit}
            sx={{ marginRight: "16px", marginBottom: "8px" }}
          />
        </form>

        <Tooltip
          placement="top"
          title="検索条件が”案件ステータス：完了”かつ”請求状況：未請求、一部未請求”となり、請求漏れの確認ができます"
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={IsCheckMissedBilling()} onChange={handleChangeSwitch} />}
              label="請求チェック"
              labelPlacement="start"
              sx={{
                ml: 0,
                mb: "8px",
                mr: "16px",
                color: theme.palette.primary.main,
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
            />
          </FormGroup>
        </Tooltip>

        <Typography
          fontWeight="400"
          onClick={conditionClear}
          sx={{
            fontSize: "14px",
            mr: "16px",
            mb: "8px",
            color: theme.palette.grayScale[700],
            cursor: "pointer",
          }}
        >
          検索条件をリセット
        </Typography>
      </Box>
      <Button
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={handleCreateButtonClick}
        sx={{ minWidth: "12rem" }}
        disabled={state.isSubmit}
      >
        案件を新規作成
      </Button>
    </Box>
  );
};
