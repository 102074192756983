import { useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { InfoToolTip } from "components/button/info-tooltip";
import { Layout } from "components/templates/layout";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { downloadBlob } from "utils/downloadBlob";
import { handleReduxError } from "utils/errorHandler";
import { participationHoursByScheduleRepository } from "../api/aggregation-download/schedule/participation_hours.repository";
import { participationHoursByWorkReportRepository } from "../api/aggregation-download/work-report/participation_hours.repository";

export const AggregationDownload = () => {
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [targetType, setTargetType] = useState<"work_report" | "schedule">("work_report");

  useEffect(() => {
    const today = new Date();
    const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    setStartDate(firstDayOfPreviousMonth);
    setEndDate(lastDayOfPreviousMonth);
  }, []);

  const handleDateChange = (date: Date, e: React.SyntheticEvent<any> | undefined, name: string) => {
    if (!date) return;
    switch (name) {
      case "startDate":
        setStartDate(date);
        break;
      case "endDate":
        setEndDate(date);
        break;
    }
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetType(e.target.value as "work_report" | "schedule");
  };

  const handleSubmit = async () => {
    try {
      let blob: Blob;
      let fileName: string;
      dispatch(mainOperations.updateSuccessMessage("CSVをダウンロード中..."));

      const targetDateRange = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}~${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
      if (targetType === "schedule") {
        blob = await participationHoursByScheduleRepository.csvDownload(startDate, endDate);
        fileName = `予定集計_${targetDateRange}.csv`;
      } else {
        blob = await participationHoursByWorkReportRepository.csvDownload(startDate, endDate);
        fileName = `日報集計_${targetDateRange}.csv`;
      }

      downloadBlob(blob, fileName);
      dispatch(mainOperations.updateSuccessMessage("CSVをダウンロードしました"));
    } catch (error) {
      handleReduxError(error, dispatch, "ダウンロードに失敗しました");
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
        <Typography fontWeight="bold" fontSize="24px">
          時間集計出力
        </Typography>
        <InfoToolTip size={24} url="https://help.gembahub.jp/130e4bd40307808596f1ffd090a266b2" />
      </div>
      <Stack spacing={4}>
        <Typography>
          作業日報や予定のデータを元に、メンバーごとの作業時間を集計できます。
        </Typography>
        <div style={{ display: "flex", gap: "16px" }}>
          <div>
            <Typography fontSize={12}>開始</Typography>
            <CustomDatePicker
              id="startDate"
              name="startDate"
              date={startDate}
              onChange={handleDateChange}
              popperPlacement="top-start"
            />
          </div>
          <div>
            <Typography fontSize={12}>終了</Typography>
            <CustomDatePicker
              id="endDate"
              name="endDate"
              date={endDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <RadioGroup
          name="targetType"
          row
          onChange={handleRadio}
          sx={{ display: "flex", gap: "20px" }}
        >
          <FormControlLabel
            control={<Radio checked={targetType === "work_report"} />}
            value="work_report"
            sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            label="作業日報"
          />
          <FormControlLabel
            control={<Radio checked={targetType === "schedule"} />}
            value="schedule"
            sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            label="予定"
          />
        </RadioGroup>
        <Button onClick={handleSubmit} variant="contained" sx={{ width: "200px", gap: "8px" }}>
          <FileDownloadOutlinedIcon />
          CSVをダウンロード
        </Button>
      </Stack>
    </Layout>
  );
};
