import { Brand } from "../brand";
import { BuildingId } from "../building/building.dto";
import { ClientId } from "../client/client.dto";
import { PageInfo } from "../page_info/page_info.dto";
import { initialProjectResponse, Project, ProjectId } from "../project/project.dto";
import { ProjectTypeId } from "../project_type/project_type.dto";
import {
  EndType,
  FrequencyType,
  RequestRecurrenceRule,
} from "../recurrence_rule/recurrence_rule.dto";
import {
  initialScheduleType,
  ScheduleType,
  ScheduleTypeId,
} from "../schedule_type/schedule_type.dto";
import { initialUser, User, UserId } from "../user/user.dto";
import { WorkReport } from "../work_report/work_report.dto";

export type ScheduleId = Brand<number, "ScheduleId">;

export interface ScheduleList {
  id: ScheduleId;
  start_time: string;
  end_time: string;
  name: string;
  display_name: string;
  is_confirmed: boolean;
  is_private: boolean;
  is_accessible: boolean;
  note: string;
  schedule_type: ScheduleType;
  users: User[];
  project?: Project;
  recurrence_rule: ResponseRecurrenceRule;
}

export interface Schedule {
  id: ScheduleId;
  name: string;
  start_time?: string;
  end_time?: string;
  note: string;
  color: string;
  is_confirmed: boolean;
  is_private: boolean;
  is_accessible: boolean;
  created_at: string;
  updated_at: string;
  users: User[];
  project?: Project;
  schedule_type: ScheduleType;
  created_by: User;
  updated_by: User;
  site_manager?: User;
  work_report?: WorkReport;
  recurrence_rule?: ResponseRecurrenceRule;
}

export interface ResponseRecurrenceRule {
  id: number;
  label: string;
  original_start_time: string;
  original_end_time: string;
  interval: number;
  frequency_type: FrequencyType;
  days_of_week: number[];
  end_type: EndType;
  end_date?: string;
  count: number;
}

export interface ScheduleIndexRequest {
  scheduleRangeStart?: string;
  scheduleRangeEnd?: string;
  userIds?: number[];
  groupIds?: number[];
  isTimeEntered?: boolean;
  // 以下、CSVダウンロード用
  keyword?: string;
  sort?: string;
  direction?: string;
}

export interface ScheduleSearchRequest {
  scheduleRangeStart?: string;
  scheduleRangeEnd?: string;
  keyword?: string;
  page?: number;
  rowsPerPage?: number;
  sort?: string;
  direction?: string;
}

export interface ScheduleCreateRequest {
  name: string;
  scheduleTypeId: ScheduleTypeId;
  siteManagerId: UserId;
  startTime: string;
  endTime: string;
  note: string;
  isConfirmed: boolean;
  isPrivate: boolean;
  projectId: ProjectId;
  project?: ProjectInfo;
  userIds: UserId[];
  users?: User[];
  recurrenceRule?: RequestRecurrenceRule;
}

export interface ProjectInfo {
  projectTypeId?: ProjectTypeId;
  name?: string;
  salesAmount?: number;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  buildingId?: BuildingId;
  clientId?: ClientId;
  client?: {
    name?: string;
  };
}

export interface ScheduleUpdateRequest {
  scheduleTypeId?: number;
  name?: string;
  isConfirmed?: boolean;
  isPrivate?: boolean;
  isNotify?: boolean;
  startTime?: string | null;
  endTime?: string | null;
  userIds?: UserId[];
  note?: string;
  projectId?: number | null;
  siteManagerId?: UserId | null;
  recurrenceRuleId?: number | null;
  recurrenceRule?: RequestRecurrenceRule;
}

export type ScheduleShowResponse = Schedule;

export interface ScheduleAssignmentRequest {
  id: ScheduleId;
  user_id: number;
}

export type ScheduleIndexResponse = Array<ScheduleList>;

export interface ScheduleSearchResponse {
  data: ScheduleList[];
  pagination: PageInfo;
}

export type TimelineScheduleIndexResponse = Array<ScheduleList>;

export interface TimelineScheduleSlideUpdateRequest {
  id: ScheduleId;
  start_time?: string;
  end_time?: string;
  add_user_id?: number | null;
  remove_user_id?: number | null;
}

export interface ScheduleConfirmRequest {
  schedule_ids: number[];
}

export interface ScheduleRightClickObject {
  id: ScheduleId;
  userId: number;
}

export const initialScheduleCreateRequest: ScheduleCreateRequest = {
  name: "",
  scheduleTypeId: 0 as ScheduleTypeId,
  siteManagerId: 0 as UserId,
  startTime: "",
  endTime: "",
  note: "",
  isConfirmed: false,
  isPrivate: false,
  projectId: 0 as ProjectId,
  userIds: [],
};

export const initialScheduleUpdateRequest: ScheduleUpdateRequest = {
  scheduleTypeId: 0,
  name: "",
  isConfirmed: false,
  isPrivate: false,
  startTime: "",
  endTime: "",
  note: "",
  userIds: [],
};

export const initialScheduleResponse: ScheduleShowResponse = {
  id: 0 as ScheduleId,
  name: "",
  start_time: "",
  end_time: "",
  note: "",
  color: "",
  is_confirmed: false,
  is_private: false,
  is_accessible: false,
  created_at: "",
  updated_at: "",
  users: [],
  project: initialProjectResponse,
  schedule_type: initialScheduleType,
  created_by: initialUser,
  updated_by: initialUser,
};

export const initialScheduleRightClickObject: ScheduleRightClickObject = {
  id: 0 as ScheduleId,
  userId: 0,
};

export const initialRecurrenceRule: RequestRecurrenceRule = {
  interval: 1,
  frequencyType: "day",
  daysOfWeek: [],
  endType: "none_specify",
  endDate: null,
  count: 0,
};
