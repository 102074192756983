import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Snackbar, SelectChangeEvent, Button, Select, MenuItem } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {
  GridRowParams,
  GridColDef,
  GridSortModel,
  GridRowId,
  GridRenderCellParams,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { getFilterItem } from "data-access/local_storage/table_filter/local_storage";
import {
  ProjectCollection,
  ProjectId,
  ProjectIndexRequest,
  ProjectUpdateRequest,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
  ProjectStatusTypeIndexResponse,
} from "data-access/repositories/project_status_type/project_status_type.dto";
import { projectStatusTypeRepository } from "data-access/repositories/project_status_type/project_status_type.repository";
import { theme } from "extensions/theme";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { projectTableOperations } from "store/project-table/operations";
import { selectProjectTable } from "store/project-table/slice";
import { selectProjectTableHeader } from "store/project-table-header/slice";
import useSWR from "swr";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { downloadDataGridRowsCsv } from "utils/downloadDataGridRowsCsv";
import { formatDateUtil } from "utils/formatDateUtil";
import { TotalBillingFormModal } from "../total-billing-form-modal";

type ProjectTableProps = {
  defaultHeaders: GridColDef[];
};

export const ProjectTable = ({ defaultHeaders }: ProjectTableProps) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectTable);
  const headerState = useAppSelector(selectProjectTableHeader);
  const detailState = useAppSelector(selectProjectSidebar);
  const orderStorageKeyName = "projectTableColumnOrder";
  const sortStorageKeyName = "projectTableColumnSort";
  const visibilityStorageKeyName = "projectTableColumnVisibility";
  const rowsPerPageLocalStorageKey = "projectTableRowsPerPage";
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const searchQuery = (page?: number) => {
    const filterItem = getFilterItem("project");

    const query: ProjectIndexRequest = {
      projectTypeIds: filterItem.projectTypeIds,
      projectStatusTypeIds: filterItem.projectStatusTypeIds,
      billingConditions: filterItem.billingConditions,
      page: page ?? state.currentPage,
      rowsPerPage: state.rowsPerPage,
    };
    if (filterItem.keyword !== "") {
      query.keyword = filterItem.keyword;
    }
    if (filterItem.inquiredDate.range.startDate) {
      query.startInquiredDate = formatDateUtil(
        filterItem.inquiredDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.inquiredDate.range.endDate) {
      query.endInquiredDate = formatDateUtil(filterItem.inquiredDate.range.endDate, "yyyy-MM-dd");
    }
    if (filterItem.orderedDate.range.startDate) {
      query.startOrderedDate = formatDateUtil(filterItem.orderedDate.range.startDate, "yyyy-MM-dd");
    }
    if (filterItem.orderedDate.range.endDate) {
      query.endOrderedDate = formatDateUtil(filterItem.orderedDate.range.endDate, "yyyy-MM-dd");
    }
    if (filterItem.completedDate.range.startDate) {
      query.startCompletedDate = formatDateUtil(
        filterItem.completedDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.completedDate.range.endDate) {
      query.endCompletedDate = formatDateUtil(filterItem.completedDate.range.endDate, "yyyy-MM-dd");
    }

    const localStorageSortItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageSortItem !== null) {
      const sortItem: { field: string; sort: "desc" | "asc" } = JSON.parse(localStorageSortItem);
      query.sort = sortItem.field;
      query.direction = sortItem.sort;
    }
    return query;
  };

  useEffect(() => {
    if (state.isDestroyed) {
      dispatch(projectSidebarOperations.close());
      dispatch(projectTableOperations.getIndex(searchQuery()));
      navigate("/projects");
    }
  }, [state.isDestroyed]);

  useEffect(() => {
    if (headerState.countChanges > 0) {
      dispatch(projectTableOperations.updateCurrentPage(1));
      dispatch(projectTableOperations.getIndex(searchQuery(1)));
    }
  }, [headerState.countChanges]);

  useEffect(() => {
    dispatch(projectTableOperations.getIndex(searchQuery()));
  }, [state.currentPage, state.rowsPerPage]);

  useEffect(() => {
    if (state.isSubmitted) {
      dispatch(projectTableOperations.getIndex(searchQuery()));
    }
    if (headerState.isSubmitted) {
      dispatch(projectTableOperations.updateSuccessMessage(headerState.successMessage));
      dispatch(projectTableOperations.getIndex(searchQuery()));
    }
    if (detailState.isSubmitted || detailState.isTodoSubmitted) {
      dispatch(projectTableOperations.getIndex(searchQuery()));
      dispatch(projectTableOperations.updateSuccessMessage(detailState.successMessage));
    }
  }, [
    state.isSubmitted,
    headerState.isSubmitted,
    detailState.isSubmitted,
    detailState.isTodoSubmitted,
  ]);

  const { data: projectStatuses } = useSWR(
    "/api/v2/project_status_types",
    projectStatusTypeRepository.index,
  );

  /**
   * セルのレンダリングが追加で必要な場合にここで定義する。
   *
   * @param headers ヘッダー群
   * @param projectStatuses 取得されている案件ステータス一覧.
   * @returns レンダリング後のヘッダー群
   *
   */
  const addCustomRenderCell = (
    headers: GridColDef[],
    projectStatuses: ProjectStatusTypeIndexResponse | undefined,
  ) => {
    const resultHeaders: GridColDef[] = [];
    headers.forEach((header) => {
      switch (header.field) {
        case "project_status_type_name":
          header.renderCell = (params: GridRenderCellParams) => {
            return (
              <ProjectStatusSelectBox
                params={params}
                projectStatuses={projectStatuses}
                searchQuery={searchQuery}
              />
            );
          };
      }
      resultHeaders.push(header);
    });
    return resultHeaders;
  };

  const columns = useMemo(() => {
    const localStorageItem = localStorage.getItem(orderStorageKeyName);
    const visibilityLocalStorageItem = localStorage.getItem(visibilityStorageKeyName);
    visibilityLocalStorageItem && setColumnVisibilityModel(JSON.parse(visibilityLocalStorageItem));

    let resultHeaders: GridColDef[] = [];
    if (localStorageItem === null) {
      resultHeaders = defaultHeaders;
    } else {
      const orderColumns: [{ field: string; hide: boolean }] = JSON.parse(localStorageItem);
      orderColumns.forEach((column) => {
        const header = defaultHeaders.find((header) => header.field === column.field);
        if (header) {
          const updatedHeader = {
            ...header,
            hide: columnVisibilityModel[header.field] === false,
          };
          resultHeaders.push(updatedHeader);
        }
      });
      const remainingHeaders = defaultHeaders.filter(
        (header) => !resultHeaders.some((h) => h.field === header.field),
      );
      // 残りのヘッダーにも表示/非表示の状態を反映
      const updatedRemainingHeaders = remainingHeaders.map((header) => ({
        ...header,
        hide: columnVisibilityModel[header.field] === false,
      }));
      resultHeaders.push(...updatedRemainingHeaders);
    }
    // 追加のカスタムレンダリングが必要な場合はここで追加
    resultHeaders = addCustomRenderCell(resultHeaders, projectStatuses);
    return resultHeaders;
  }, [localStorage, projectStatuses, defaultHeaders]);

  const initialState = useMemo(() => {
    const defaultState = {
      pinnedColumns: { left: ["__check__", "code", "name"] },
      sorting: {},
    };
    const localStorageItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageItem !== null) {
      const sortItem: { field: string; sort: string } = JSON.parse(localStorageItem);
      defaultState.sorting = {
        sortModel: [{ field: sortItem.field, sort: sortItem.sort }],
      };
    }
    return defaultState;
  }, [state.isSubmitted]);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const openDetails = (id: number) => {
    dispatch(projectSidebarOperations.showProject(id as ProjectId));
    dispatch(projectSidebarOperations.showProjectTodo(id as ProjectId));
    dispatch(projectSidebarOperations.open());
    const path = `/projects/${id}`;
    if (path !== location.pathname) {
      navigate(path);
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/projects/${params.id}`);
  };

  const handleCloseSuccessSnackBar = () => {
    dispatch(projectTableOperations.updateSuccessMessage(""));
  };

  const handleCloseErrorSnackBar = () => {
    dispatch(projectTableOperations.updateErrorMessage(""));
  };

  const handleSelectPage = (event: SelectChangeEvent<unknown>) => {
    dispatch(projectTableOperations.updateCurrentPage(event.target.value as number));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(projectTableOperations.updateCurrentPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    dispatch(projectTableOperations.updateRowsPerPage(Number(event.target.value)));
    localStorage.setItem(rowsPerPageLocalStorageKey, event.target.value);
  };

  const setColumnOrder = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(orderStorageKeyName, JSON.stringify(columns));
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    setColumnOrder(allColumns);
  };
  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(visibilityStorageKeyName, JSON.stringify(model));
  };

  const handleSortModelChange = (sortItems: GridSortModel) => {
    // Does not currently allow sorting by multiple items
    if (sortItems.length > 0) {
      localStorage.setItem(sortStorageKeyName, JSON.stringify(sortItems[0]));
    } else if (sortItems.length === 0) {
      localStorage.removeItem(sortStorageKeyName);
    }
    dispatch(projectTableOperations.getIndex(searchQuery()));
  };

  useEffect(() => {
    // ファイルアップロード時のブラウザバックアラートでキャンセルをクリックした時にcloseしないように早期リターンする
    if (detailState.isFileUploading && !params.id) return;
    if (params.id) {
      openDetails(Number(params.id));
    } else {
      dispatch(projectSidebarOperations.close());
    }
  }, [location.pathname]);

  // 最初にチェックされた案件を保持する(複数選択時に同じ顧客の案件のみ選択可能にするため)
  const firstCheckedProject = useRef<ProjectCollection | undefined>();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false);
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProjects, setSelectedProjects] = useState<ProjectCollection[]>([]);

  const handleCheck = (projects: ProjectCollection[]) => {
    setSelectedRowCount(projects.length);
    setSelectedProjects(projects);
    setSelectedRowIds(projects.map((project) => project.id));
    // 複数選択のチェックが入ったときに顧客が異なる案件があれば請求作成ボタンをdisabledにする
    if (projects.length > 1) {
      const firstClientId = projects[0].client?.id;
      for (let i = 1; i < projects.length; i++) {
        if (projects[i].client?.id !== firstClientId) {
          setIsCreateButtonDisabled(true);
          break;
        }
        setIsCreateButtonDisabled(false);
      }
      return;
    }
    // 全件選択のチェックを外したとき
    if (projects.length === 0) {
      setIsCreateButtonDisabled(false);
      firstCheckedProject.current = projects.length > 0 ? projects[0] : undefined;
      return;
    }
    // 通常のチェックのとき
    firstCheckedProject.current = projects.length > 0 ? projects[0] : undefined;
  };

  const rowSelectable = (params: GridRowParams<ProjectCollection>) => {
    let isClientDifferent: boolean = false;

    // 最初にチェックされた案件の顧客と異なる場合は選択不可
    if (firstCheckedProject.current) {
      isClientDifferent = params.row.client?.id !== firstCheckedProject.current?.client?.id;
    }
    // 顧客が未登録の場合は選択不可
    if (!params.row.client || isClientDifferent) return false;

    return true;
  };

  const handleCreate = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setSelectedRowCount(0);
    setSelectedRowIds([]);
    firstCheckedProject.current = undefined;
  };

  const handleClickDownloadCsv = async () => {
    dispatch(
      projectTableOperations.downloadProject({
        projectTypeIds: searchQuery().projectTypeIds,
        projectStatusTypeIds: searchQuery().projectStatusTypeIds,
        startInquiredDate: searchQuery().startInquiredDate,
        endInquiredDate: searchQuery().endInquiredDate,
        startOrderedDate: searchQuery().startOrderedDate,
        endOrderedDate: searchQuery().endOrderedDate,
        startCompletedDate: searchQuery().startCompletedDate,
        endCompletedDate: searchQuery().endCompletedDate,
        keyword: searchQuery().keyword,
        billingConditions: searchQuery().billingConditions,
      }),
    );
  };

  useEffect(() => {
    if (state.isDownloaded && state.downloadProjects) {
      const csvColumnDefinitions = [...columns];
      downloadDataGridRowsCsv(
        "project",
        state.downloadProjects,
        csvColumnDefinitions,
        columnVisibilityModel,
      );
      // 画面遷移後、再度ダウンロードされることを防ぐため、ダウンロード済みフラグをfalseにする
      dispatch(projectTableOperations.updateIsDownloaded(false));
    }
  }, [state.isDownloaded]);

  /**
   * セル編集時の処理.
   * @param id rowのid, ここではProjectId.
   * @param updatedRowParams 編集された項目のkey:value.
   */
  const handleCellEdited = async (id: number, updatedRowParams: { [key: string]: any }) => {
    if (Object.keys(updatedRowParams).length === 0) return;
    try {
      await projectRepository.update(
        id as ProjectId,
        {
          ...convertKeysToCamelCase(updatedRowParams),
        } as ProjectUpdateRequest,
      );
      dispatch(projectTableOperations.updateSuccessMessage("案件を更新しました"));
    } catch (error) {
      dispatch(projectTableOperations.updateErrorMessage(error.response.data.message));
    }
  };

  // 案件ステータスのセルをクリックした時だけ、無反応（詳細画面に遷移しない）にする
  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (params.field !== "project_status_type_name") return;
    event.stopPropagation();
  };

  return (
    <>
      {selectedProjects.length > 0 && (
        <TotalBillingFormModal isOpen={isOpen} setIsOpen={setIsOpen} projects={selectedProjects} />
      )}
      {selectedRowCount > 0 && (
        <div style={{ marginBottom: "12px" }}>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{ width: "190px", fontWeight: "bold" }}
            disabled={isCreateButtonDisabled || selectedRowCount === 0}
          >
            {selectedRowCount}件から請求を新規作成
          </Button>
          <Button sx={{ color: theme.palette.grayScale[700], ml: "12px" }} onClick={handleCancel}>
            キャンセル
          </Button>
        </div>
      )}
      <CustomDataGridPro
        type="project"
        editMode="cell"
        columns={columns}
        rows={state.projects}
        loading={state.isLoading}
        pageInfo={state.pageInfo}
        currentPage={state.currentPage}
        tableHeight={selectedRowCount > 0 ? "70vh" : "75vh"}
        handleDownloadButton={handleClickDownloadCsv}
        handleSelectPage={handleSelectPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleRowClick={handleRowClick}
        handleCellClick={handleCellClick}
        initialState={initialState}
        handleColumnOrderChange={handleColumnOrderChange}
        handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        handleSortModelChange={handleSortModelChange}
        handleCellEdited={handleCellEdited}
        columnVisibilityModel={columnVisibilityModel}
        isCheckbox
        rowSelectableFunc={(params: GridRowParams) => rowSelectable(params)}
        handleCheck={handleCheck}
        rowSelection={selectedRowIds}
      />
      <Snackbar
        open={!!state.loadingMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={state.loadingMessage}
      />
      <ResponseSnackbar
        successMessage={state.successMessage}
        handleCloseSuccess={handleCloseSuccessSnackBar}
        errorMessage={state.errorMessage}
        handleCloseError={handleCloseErrorSnackBar}
      />
    </>
  );
};

interface ProjectStatusSelectBoxProps {
  params: GridRenderCellParams;
  projectStatuses: ProjectStatusType[] | undefined;
  searchQuery: (page?: number) => ProjectIndexRequest;
}

const ProjectStatusSelectBox = (props: ProjectStatusSelectBoxProps) => {
  const dispatch = useAppDispatch();

  // TODO: 完了をタップするとエラーが出る（by 沖永）
  const handleChangeProjectStatus = async (event: SelectChangeEvent<any>, projectId: ProjectId) => {
    try {
      const selectedProjectStatusTypeId = event.target.value as ProjectStatusTypeId;
      await projectRepository.update(projectId, {
        projectStatusTypeId: selectedProjectStatusTypeId,
      } as ProjectUpdateRequest);
      // TODO: ここを本来はmutate (useSWR) にてできた方がよく、その場合は案件一覧をuseSWRで管理するような変更が必要
      dispatch(projectTableOperations.getIndex(props.searchQuery()));
      dispatch(mainOperations.updateSuccessMessage("案件ステータスを変更しました。"));
    } catch (error) {
      dispatch(projectTableOperations.updateErrorMessage(error.response.data.message));
    }
  };
  return (
    <>
      <Select
        value={props.params.row.project_status_type.id}
        variant="standard"
        onChange={(e) => handleChangeProjectStatus(e, props.params.row.id)}
        displayEmpty
        sx={{
          "&.MuiInputBase-root:before": {
            borderBottom: "none",
          },
        }}
      >
        {props.projectStatuses &&
          props.projectStatuses.map((projectStatusType: ProjectStatusType, index: number) => (
            <MenuItem value={projectStatusType.id} key={index}>
              <StatusTypeLabel
                statusType={projectStatusType.name}
                color={projectStatusType.color_number}
                sx={{ height: "auto", p: "3px 0", minWidth: "90px" }}
              />
            </MenuItem>
          ))}
      </Select>
    </>
  );
};
