import { Box } from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { useAppDispatch } from "store/hooks";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";

interface Props {
  state: ProjectSidebarState;
}

export const ProjectSidebarDateBlock = (props: Props) => {
  const { state } = props;
  const dispatch = useAppDispatch();

  const handleChange = (date: Date, e: React.SyntheticEvent<any> | undefined, name: string) => {
    const dateString = date ? date.toString() : "";
    const updateProject = (fieldName: string) => {
      if (state.isExisting) {
        dispatch(
          projectSidebarOperations.updateProject(state.project.id, {
            [fieldName]: dateString,
          }),
        );
      } else {
        dispatch(
          projectSidebarOperations.createProject({
            ...state.form,
            [fieldName]: dateString,
          }),
        );
      }
    };

    switch (name) {
      case "inquiredDate":
        if (e && e.type === "click") {
          updateProject("inquiredDate");
        }
        dispatch(projectSidebarOperations.updateInquiredDate(dateString));
        break;
      case "orderedDate":
        if (e && e.type === "click") {
          updateProject("orderedDate");
        }
        dispatch(projectSidebarOperations.updateOrderedDate(dateString));
        break;
      case "expectedCompleteDate":
        if (e && e.type === "click") {
          updateProject("expectedCompleteDate");
        }
        dispatch(projectSidebarOperations.updateExpectedCompleteDate(dateString));
        break;
      case "completedDate":
        if (e && e.type === "click") {
          updateProject("completedDate");
        }
        dispatch(projectSidebarOperations.updateCompletedDate(date ? date.toString() : ""));
        break;
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const convertName: string = name.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1_$2").toLowerCase();
    const fieldValue = state.form[name];

    if (state.project[convertName] === fieldValue) return;

    if (state.isExisting) {
      dispatch(
        projectSidebarOperations.updateProject(state.project.id, {
          [name]: fieldValue,
        }),
      );
    } else {
      dispatch(
        projectSidebarOperations.createProject({
          ...state.form,
          [name]: fieldValue,
        }),
      );
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <div>
        <CustomFormLabel labelName="受付日" small />
        <CustomDatePicker
          id="inquiredDate"
          name="inquiredDate"
          date={state.form.inquiredDate ? new Date(state.form.inquiredDate) : null}
          onChange={handleChange}
          onBlur={handleBlur}
          isClearable
          popperPlacement={"bottom-start"}
        />
      </div>
      <div>
        <CustomFormLabel labelName="受注日" small />
        <CustomDatePicker
          id="orderedDate"
          name="orderedDate"
          date={state.form.orderedDate ? new Date(state.form.orderedDate) : null}
          onChange={handleChange}
          onBlur={handleBlur}
          isClearable
        />
      </div>
      <div>
        <CustomFormLabel labelName="完了予定日" small />
        <CustomDatePicker
          id="expectedCompleteDate"
          name="expectedCompleteDate"
          date={state.form.expectedCompleteDate ? new Date(state.form.expectedCompleteDate) : null}
          onChange={handleChange}
          onBlur={handleBlur}
          isClearable
        />
      </div>
      <div>
        <CustomFormLabel labelName="完了日" small />
        <CustomDatePicker
          id="completedDate"
          name="completedDate"
          date={state.form.completedDate ? new Date(state.form.completedDate) : null}
          onChange={handleChange}
          onBlur={handleBlur}
          isClearable
        />
      </div>
    </Box>
  );
};
