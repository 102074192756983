import { ApiClient } from "data-access/ApiClient";
import {
  Building,
  BuildingId,
  BuildingIndexRequest,
  BuildingIndexResponse,
  BuildingRequest,
  BuildingCreateResponse,
  BuildingShowResponse,
  BuildingUpdateResponse,
} from "./building.dto";

export const buildingRepository = {
  index(query: BuildingIndexRequest): Promise<BuildingIndexResponse> {
    const params = {
      keyword: query.keyword,
      page: query.page,
      limit: query.limit,
      order_by: query.orderBy,
      direction: query.direction,
    };
    return ApiClient.get("/api/v1/buildings", { params }).then((res) => res.data);
  },
  all(query: BuildingIndexRequest): Promise<Building[]> {
    const params = {
      keyword: query.keyword,
      order_by: query.orderBy,
      direction: query.direction,
    };
    return ApiClient.get("/api/v1/buildings/all", { params }).then((res) => res.data);
  },
  create(body: BuildingRequest): Promise<BuildingCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/buildings", createBody(body), config).then((res) => res.data);
  },
  show(id: BuildingId): Promise<BuildingShowResponse> {
    return ApiClient.get(`/api/v1/buildings/${id}`).then((res) => res.data);
  },
  update(id: BuildingId, body: BuildingRequest): Promise<BuildingUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/buildings/${id}`, createBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: BuildingId): Promise<void> {
    return ApiClient.delete(`/api/v1/buildings/${id}`).then((res) => res.data);
  },
};

const createBody = (body: BuildingRequest) => {
  return {
    client_id: body.clientId,
    name: body.name,
    person_name: body.personName,
    postal_code: body.postalCode,
    address: body.address,
    email: body.email,
    phone_number: body.phoneNumber,
    phone_number_second: body.phoneNumberSecond,
    fax_number: body.faxNumber,
    note: body.note,
  };
};
