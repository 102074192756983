import { useEffect, useState } from "react";
import * as React from "react";
import { FileDownloadOutlined, Link, Visibility } from "@mui/icons-material";
import { Box, Typography, Tooltip, IconButton, Divider, Switch } from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { TitleLabel } from "components/label/title-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { FileDropzone } from "components/molecules/file-dropzone";
import {
  AttachmentResponse,
  ProjectAttachmentId,
} from "data-access/repositories/project/attachment/attachment.dto";
import { attachmentRepository } from "data-access/repositories/project/attachment/attachment.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { imagePreviewModalOperations } from "store/image-preview-modal/operations";
import { mainOperations } from "store/main/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import useSWR from "swr";
import {
  PREVIEWABLE_EXCEL_EXTENSION,
  IMAGE_EXTENSIONS,
  PDF_EXTENSION,
  TIF_EXTENSION,
} from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { isContainExtensions } from "utils/isContainExtensions";

interface MainProps {
  state: ProjectSidebarState;
  onUpload: (files: File[], attachmentTypeId?: string) => void;
  onDelete: (id: ProjectAttachmentId, type: string) => void;
  onCheck: (e: React.ChangeEvent<HTMLInputElement>, attachmentId: number) => void;
}

export const ProjectSidebarAttachmentBlock = (props: MainProps) => {
  const [expandedPanels, setExpandedPanels] = useState<boolean[]>([]);

  const { data: attachmentTypes, mutate } = useSWR(
    props.state.project.id
      ? `/api/v1/projects/${props.state.project.id}/attachments/by_attachment_type`
      : null,
    () => attachmentRepository.byAttachmentType(props.state.project.id),
  );

  useEffect(() => {
    mutate();
  }, [props.state.project.id, props.state.isAttachmentSubmitted]);

  const handleToggle = (index: number) => {
    setExpandedPanels((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <>
      {attachmentTypes &&
        attachmentTypes.map((attachmentType, index) => (
          <Box key={index} sx={{ mb: "24px" }}>
            <TitleLabel title={attachmentType.name} />
            <FileDropzone onDrop={props.onUpload} attachmentTypeId={attachmentType.id} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                {attachmentType.attachments.slice(0, 4).map((attachment) => (
                  <AttachmentBlock
                    key={attachment.id}
                    attachment={attachment}
                    onDelete={props.onDelete}
                    isPublishToMobileName="IsPublishToMobile"
                    onCheck={props.onCheck}
                    state={props.state}
                  />
                ))}
              </Box>
              {expandedPanels[index] && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                  {attachmentType.attachments.slice(4).map((attachment) => (
                    <AttachmentBlock
                      key={attachment.id}
                      attachment={attachment}
                      onDelete={props.onDelete}
                      isPublishToMobileName="IsPublishToMobile"
                      onCheck={props.onCheck}
                      state={props.state}
                    />
                  ))}
                </Box>
              )}
              {attachmentType.attachments.length > 4 && (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <AccordionToggle
                    title={expandedPanels[index] ? "少なく表示" : "すべて表示"}
                    isExpanded={expandedPanels[index] || false}
                    setIsExpanded={() => handleToggle(index)}
                    titleStyle={{ color: theme.palette.primary.main, fontWeight: "500" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

interface Props {
  sx?: object;
  state: ProjectSidebarState;
  attachment: AttachmentResponse;
  onDelete: (id: ProjectAttachmentId, type: string) => void;
  isPublishToMobileName: string;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, id: ProjectAttachmentId) => void;
}

const AttachmentBlock = (props: Props) => {
  const dispatch = useAppDispatch();
  const [copyTip, setCopyTip] = useState<string>("ファイルのリンクをコピーします");

  const handleClickPreview = async () => {
    if (isContainExtensions(props.attachment.file_name, [PDF_EXTENSION])) {
      window.open(props.attachment.file_url);
      return;
    }
    if (isContainExtensions(props.attachment.file_name, [...IMAGE_EXTENSIONS, ...TIF_EXTENSION])) {
      dispatch(imagePreviewModalOperations.open());
      dispatch(
        imagePreviewModalOperations.setImage({
          name: props.attachment.file_name,
          url: props.attachment.file_url,
        }),
      );
      return;
    }
    if (isContainExtensions(props.attachment.file_name, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const result = await attachmentRepository.excelFilePreview(
          props.state.project.id,
          props.attachment.id as ProjectAttachmentId,
        );
        window.open(result.url, "_blank");
      } catch (error) {
        handleReduxError(error, dispatch, "ファイルのプレビューに失敗しました");
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  const handleClickCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(props.attachment.file_url);
      setCopyTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };
  const handleOpenInfoTitleTip = () => {
    setCopyTip("ファイルのリンクをコピーします");
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
        backgroundColor: theme.palette.customPrimary[50],
        p: "16px 16px 8px 16px",
        width: "230px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <FileIcon fileName={props.attachment.file_name} sx={{ width: "1.8rem" }} />
        <div>
          {isContainExtensions(props.attachment.file_name, [
            PDF_EXTENSION,
            ...IMAGE_EXTENSIONS,
            ...TIF_EXTENSION,
            ...PREVIEWABLE_EXCEL_EXTENSION,
          ]) && (
            <Tooltip title="プレビュー" placement="top" arrow>
              <IconButton size="small" onClick={handleClickPreview}>
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={copyTip} onOpen={handleOpenInfoTitleTip} placement="top" arrow>
            <IconButton size="small" onClick={handleClickCopyLink}>
              <Link />
            </IconButton>
          </Tooltip>
          <Tooltip title="ダウンロード" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => fileDownload(props.attachment.file_url, props.attachment.file_name)}
            >
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="削除" placement="top" arrow>
            <IconButton
              size="small"
              onClick={() => props.onDelete(props.attachment.id, "projectAttachment")}
            >
              <DeleteIcon color={theme.palette.grayScale[700]} size={22} />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
      <Box sx={{ wordWrap: "break-word" }}>
        <Typography
          sx={{
            fontWeight: "500",
            wordBreak: "break-all",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            height: "54px",
          }}
        >
          {props.attachment.file_name}
        </Typography>
        <Typography fontSize="14px" sx={{ mt: "8px", color: theme.palette.grayScale[700] }}>
          {formatDateUtil(new Date(props.attachment.created_at), "yyyy年MM月dd日")}
        </Typography>
      </Box>
      <Divider sx={{ my: "8px" }} />
      <div>
        <Typography component="span">全員に共有</Typography>
        <Switch
          onChange={(e) => props.onCheck(e, props.attachment.id)}
          checked={props.attachment.is_publish_to_mobile}
        />
      </div>
    </Box>
  );
};
