import { ScheduleUpdateRequest, ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { dropGridSchedule, destroy } from "./actions";
import { calendarSlice } from "./slice";
import { AppDispatch } from "../store";

export const calendarOperations = {
  destroy: (id: ScheduleId, with_futures: boolean) => (dispatch: AppDispatch) => {
    dispatch(destroy({ id, with_futures }));
  },
  dropGridSchedule:
    (id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(dropGridSchedule({ id, with_futures, body }));
    },
  deleteSnackbarMessage: () => (dispatch: AppDispatch) => {
    dispatch(calendarSlice.actions.deleteSnackbarMessage());
  },
};
