import { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField, Typography } from "@mui/material";
import { CloseIcon } from "components/icon/close-icon";
import { CustomModal } from "components/molecules/custom-modal";
import { CompanyUser } from "data-access/repositories/company_user/company_user.dto";
import {
  NoticeProjectStatusChangeId,
  NoticeProjectStatusChangeNoticeTarget,
  OPTIONS,
  Options,
} from "data-access/repositories/notice/project-status/project_status_change.dto";
import { noticeProjectStatusChangeRepository } from "data-access/repositories/notice/project-status/project_status_change.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  id: NoticeProjectStatusChangeId;
  selectedNoticeTarget: NoticeProjectStatusChangeNoticeTarget;
  fetchIndexKey: string;
  onClose: () => void;
}

export const UserSelectModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const mainState = useAppSelector(selectMain);
  const activeCompanyUsers = mainState.companyUsers.filter(
    (companyUser) => !companyUser.isDeactivate && companyUser.hasEmail,
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [selectCompanyUsers, setSelectCompanyUsers] = useState<CompanyUser[]>([]);
  const [selectOptions, setSelectOptions] = useState<Options[]>([]);

  useEffect(() => {
    const convertSelectedUsers: CompanyUser[] = props.selectedNoticeTarget.userIds
      .map((userId) => activeCompanyUsers.find((companyUser) => companyUser.userId === userId))
      .filter((user): user is CompanyUser => user !== undefined);

    const convertSelectedOptions = () => {
      const options: Options[] = [];
      if (props.selectedNoticeTarget.inquiredBy) {
        options.push("inquired_by");
      }
      if (props.selectedNoticeTarget.manager) {
        options.push("manager");
      }
      return options;
    };

    setSelectCompanyUsers(convertSelectedUsers);
    setSelectOptions(convertSelectedOptions());
  }, [props.isOpen]);

  const handleSelectOption = (option: Options) => {
    setSelectOptions((prevOptions) => {
      if (prevOptions.includes(option)) {
        // オプションが既に選択されている場合は削除
        return prevOptions.filter((item) => item !== option);
      } else {
        // オプションが選択されていない場合は追加
        return [...prevOptions, option];
      }
    });
  };

  const handleSelectUsers = (_: React.SyntheticEvent, value: CompanyUser[]) => {
    setSelectCompanyUsers(value);
  };

  const handleClear = (userToRemove: CompanyUser) => {
    setSelectCompanyUsers((prevUsers) =>
      prevUsers.filter((companyUser) => companyUser.userId !== userToRemove.userId),
    );
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const result: NoticeProjectStatusChangeNoticeTarget = {
      inquiredBy: selectOptions.includes("inquired_by"),
      manager: selectOptions.includes("manager"),
      userIds: selectCompanyUsers.map((user) => user.userId),
    };

    try {
      await noticeProjectStatusChangeRepository.update(props.id, { noticeTarget: result });
      mutate(props.fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知対象者を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知対象者の変更に失敗しました");
    } finally {
      setIsSubmitted(false);
      props.onClose();
    }
  };

  const isValid = useMemo(() => {
    if (selectOptions.length === 0 && selectCompanyUsers.length === 0) return false;
    return true;
  }, [selectCompanyUsers, selectOptions]);

  return (
    <CustomModal
      open={props.isOpen}
      onClose={props.onClose}
      title="メンバー選択"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={props.onClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid || isSubmitted}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <Box>
        <Typography sx={{ fontSize: "14px", fontWeight: "500", mb: "16px" }}>
          かんたん設定
        </Typography>
        <Box sx={{ display: "flex", gap: "16px" }}>
          {Object.values(OPTIONS).map((option) => (
            <Box
              key={option.key}
              className={classes.optionButton}
              onClick={() => handleSelectOption(option.key)}
              sx={{
                color: selectOptions.includes(option.key)
                  ? theme.palette.grayScale[0]
                  : theme.palette.primary.main,
                bgcolor: selectOptions.includes(option.key)
                  ? theme.palette.primary.main
                  : theme.palette.grayScale[0],
              }}
            >
              {option.label}
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ my: "16px" }} />

      <Autocomplete
        options={activeCompanyUsers}
        value={selectCompanyUsers}
        onChange={handleSelectUsers}
        renderInput={(params) => <TextField {...params} label="メンバーを選択" />}
        getOptionLabel={(option) => option.name}
        multiple
        disableCloseOnSelect
        disableClearable
        renderTags={() => null}
      />

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
        {selectCompanyUsers.map((selectUser, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "12px",
              height: "25px",
              p: "2px 8px",
              border: `1px solid ${theme.palette.grayScale[900]}`,
              borderRadius: "4px",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{selectUser.name}</Typography>
            <Button
              startIcon={<CloseIcon size={18} />}
              onClick={() => handleClear(selectUser)}
              color="primary"
              sx={{ p: 0, minWidth: "auto", "& .MuiButton-icon": { m: 0 } }}
            />
          </Box>
        ))}
      </Box>
    </CustomModal>
  );
};
