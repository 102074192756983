import { useEffect, useMemo, useState } from "react";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { ForScheduleCompanyUser } from "data-access/repositories/company_user/for_schedule_company_user.dto";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { UserId } from "data-access/repositories/user/user.dto";
import { User } from "data-access/repositories/user/user.dto";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import useSWR from "swr";

interface Props {
  isModalOpen: boolean;
  startTime: string;
  endTime: string;
  initialUserIds?: UserId[];
  excludeScheduleId?: ScheduleId;
  onMembersChange: (userIds: UserId[]) => void;
  onCreateSchedule: () => Promise<void>;
}

interface UseScheduleMembersReturn {
  selectedCompanyUsers: ForScheduleCompanyUser[];
  inputValue: string;
  allOptions: ForScheduleCompanyUser[];
  selectableSiteManagers: User[];
  isLoading: boolean;
  resetMembers: () => void;
  handleChangeUsers: (
    _: React.SyntheticEvent<Element, Event>,
    value: ForScheduleCompanyUser[],
  ) => void;
  handleClear: (userToRemove: ForScheduleCompanyUser) => void;
  setInputValue: (value: string) => void;
  filterOptions: (options: ForScheduleCompanyUser[]) => ForScheduleCompanyUser[];
  groupByFunction: (option: ForScheduleCompanyUser) => string;
  hasOverlappedMembers: boolean;
  isOverlapConfirmModalOpen: boolean;
  handleCreateWithOverlapCheck: () => Promise<void>;
  handleCancelOverlapConfirm: () => void;
}

export const useScheduleMembers = ({
  initialUserIds = [],
  ...props
}: Props): UseScheduleMembersReturn => {
  const mainState = useAppSelector(selectMain);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState<ForScheduleCompanyUser[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOverlapConfirmModalOpen, setIsOverlapConfirmModalOpen] = useState<boolean>(false);

  const {
    data: companyUsers,
    isLoading,
    mutate,
  } = useSWR(
    props.isModalOpen ? "/api/v1/company_users/for_schedule" : null,
    () =>
      companyUserRepository.indexForSchedule({
        startTime: props.startTime || "",
        endTime: props.endTime || "",
        excludeScheduleId: props.excludeScheduleId,
      }),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!companyUsers || initialUserIds.length === 0) return;

    const initialUsers = companyUsers.filter((user) => initialUserIds.includes(user.userId));

    if (initialUsers.length > 0) {
      setSelectedCompanyUsers(initialUsers);
    }
  }, [companyUsers, initialUserIds]);

  useEffect(() => {
    if (props.isModalOpen && props.startTime && props.endTime) {
      mutate();
    }
  }, [props.startTime, props.endTime, props.isModalOpen]);

  const filterOptions = (options: ForScheduleCompanyUser[]) => {
    // 選択中のユーザーは常に表示
    const selectedOnes = options.filter((option) =>
      selectedCompanyUsers.some((user) => user.userId === option.userId),
    );

    // 未選択のユーザーに対してのみ検索フィルターを適用
    const filteredOthers = options.filter(
      (option) =>
        !selectedCompanyUsers.some((user) => user.userId === option.userId) &&
        (option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          (option.group?.name?.toLowerCase() || "").includes(inputValue.toLowerCase())),
    );

    if (inputValue && filteredOthers.length === 0) {
      return [...selectedOnes, { name: "__NO_RESULT__" } as ForScheduleCompanyUser];
    }

    return [...selectedOnes, ...filteredOthers];
  };

  const groupByFunction = (option: ForScheduleCompanyUser) => {
    return selectedCompanyUsers.some((user) => user.userId === option.userId)
      ? "選択中"
      : "検索結果";
  };

  const resetMembers = () => {
    setSelectedCompanyUsers([]);
    setInputValue("");
    props.onMembersChange([]);
  };

  const handleChangeUsers = (
    _: React.SyntheticEvent<Element, Event>,
    value: ForScheduleCompanyUser[],
  ) => {
    const userIds = value.map((companyUser: ForScheduleCompanyUser) => companyUser.userId);
    setSelectedCompanyUsers(value);
    props.onMembersChange(userIds);
  };

  const handleClear = (userToRemove: ForScheduleCompanyUser) => {
    const newUsers = selectedCompanyUsers.filter(
      (companyUser) => companyUser.userId !== userToRemove.userId,
    );
    setSelectedCompanyUsers(newUsers);
    props.onMembersChange(newUsers.map((user) => user.userId));
  };

  const selectableSiteManagers = useMemo(() => {
    return mainState.users.filter((user) => {
      return selectedCompanyUsers.some((u) => u.userId === user.id);
    });
  }, [mainState.users, selectedCompanyUsers]);

  // メンバーの重複チェック
  const hasOverlappedMembers: boolean = useMemo(() => {
    if (!companyUsers) return false;
    return companyUsers.some(
      (companyUser) =>
        selectedCompanyUsers.some((selected) => selected.userId === companyUser.userId) &&
        companyUser.isScheduleOverlapped &&
        companyUser.scheduleOverlappingType.value !== "can",
    );
  }, [companyUsers, selectedCompanyUsers]);

  // 参加メンバーの重複チェック後に作成処理
  const handleCreateWithOverlapCheck = async () => {
    if (hasOverlappedMembers) {
      setIsOverlapConfirmModalOpen(true);
      return;
    }
    await props.onCreateSchedule();
  };

  const handleCancelOverlapConfirm = () => {
    setIsOverlapConfirmModalOpen(false);
  };

  return {
    selectedCompanyUsers,
    inputValue,
    allOptions: companyUsers || [],
    selectableSiteManagers,
    isLoading,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    hasOverlappedMembers,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  };
};
