import * as Sentry from "@sentry/browser";
import { mainOperations } from "store/main/operations";

// Redux dispatch用のエラーハンドラ
export const handleReduxError = (
  error: any,
  dispatch: (action: any) => void,
  defaultErrorMessage: string,
) => {
  if (error.response?.data?.message) {
    dispatch(mainOperations.updateErrorMessage(error.response.data.message));
  } else {
    dispatch(mainOperations.updateErrorMessage(defaultErrorMessage));
    Sentry.captureException(error, { extra: error.response });
  }
};

// setState用のエラーハンドラ
export const handleStateError = (
  error: any,
  setErrorMessage: (message: string) => void,
  defaultErrorMessage: string,
) => {
  if (error.response?.data?.message) {
    setErrorMessage(error.response.data.message);
  } else {
    setErrorMessage(defaultErrorMessage);
    Sentry.captureException(error, { extra: error.response });
  }
};
