import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Autocomplete,
  Box,
  Button,
  Popper,
  PopperPlacementType,
  PopperProps,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "components/icon/close-icon";
import { SiteManagerIcon } from "components/icon/site-manager-icon";
import { GroupLabel } from "components/label/group-label";
import { ForScheduleCompanyUser } from "data-access/repositories/company_user/for_schedule_company_user.dto";
import { UserId } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { getOptionStyles, getSelectedMemberStyles } from "./styles";

interface Props {
  allOptions: any[];
  selectedCompanyUsers: ForScheduleCompanyUser[];
  selectedSiteManagerId: UserId;
  inputValue: string;
  groupByFunction: (option: ForScheduleCompanyUser) => string;
  filterOptions: (options: ForScheduleCompanyUser[]) => ForScheduleCompanyUser[];
  setInputValue: (value: string) => void;
  onChangeUsers: (e: any, value: any) => void;
  handleClear: (selectedCompanyUser: any) => void;
  popperPlacement: PopperPlacementType;
}
export const SelectedMembers = (props: Props) => {
  const isOptionDisabled = (option: ForScheduleCompanyUser) => {
    return (
      (option.isScheduleOverlapped && option.scheduleOverlappingType.value === "forbidden") ||
      option.userId === props.selectedSiteManagerId
    );
  };
  return (
    <>
      <Autocomplete
        PopperComponent={(popperProps) => (
          <CustomPopper {...popperProps} popperPlacement={props.popperPlacement} />
        )}
        options={props.allOptions}
        value={props.selectedCompanyUsers}
        inputValue={props.inputValue}
        onInputChange={(_, newInputValue) => {
          props.setInputValue(newInputValue);
        }}
        onChange={(_, value) => {
          props.onChangeUsers(_, value);
          /**
           * MUI Autocompleteは選択時に入力値をクリアする仕様のため、内部状態の更新後に入力値を再設定する必要がある
           * setTimeout(0)で次のイベントループまで待つことで、Autocompleteの内部状態更新完了後に入力値を設定できる
           */
          setTimeout(() => {
            props.setInputValue(props.inputValue);
          }, 0);
        }}
        renderInput={(params) => (
          <TextField {...params} label="メンバーを選択" variant="standard" />
        )}
        getOptionLabel={(option) => option.name}
        multiple
        disableCloseOnSelect
        disableClearable
        renderTags={() => null}
        groupBy={props.groupByFunction}
        filterOptions={props.filterOptions}
        getOptionDisabled={isOptionDisabled}
        renderGroup={(params) => {
          // 検索結果なしのケース
          if (params.group === "検索結果") {
            const children = React.Children.toArray(params.children);
            if (children.length === 0) {
              return (
                <Box key={`${params.group}-empty`}>
                  <Typography sx={{ px: 2, py: 1, fontWeight: "bold" }}>{params.group}</Typography>
                  <Typography sx={{ px: 2, py: 1 }}>検索結果がありません</Typography>
                </Box>
              );
            }
          }
          return (
            <Box key={params.group}>
              <Typography sx={{ px: 2, py: 1, fontWeight: "bold" }}>{params.group}</Typography>
              {params.children}
            </Box>
          );
        }}
        renderOption={(optionProps, option, { selected }) => {
          if (option.name === "__NO_RESULT__") return null;
          const isSiteManager = option.userId === props.selectedSiteManagerId;
          return (
            <Box
              component="li"
              {...optionProps}
              key={option.userId}
              sx={getOptionStyles({ option, selected, isSiteManager })}
            >
              {selected && <CheckIcon color="primary" sx={{ mr: 1 }} />}
              <Typography sx={{ width: "120px", mr: "16px" }}>{option.name}</Typography>
              <GroupLabel
                name={option.group ? option.group.name : ""}
                colorNumber={option.group ? option.group.colorNumber : ""}
                sx={{ mr: "16px" }}
              />
              {option.isScheduleOverlapped && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color:
                      option.scheduleOverlappingType.value === "warning"
                        ? theme.palette.mustard[500]
                        : option.scheduleOverlappingType.value === "forbidden"
                          ? theme.palette.error.main
                          : "",
                  }}
                >
                  {option.scheduleOverlappingType.value !== "can" && (
                    <ErrorOutline sx={{ fontSize: 16, mr: 0.5 }} />
                  )}
                  <Typography variant="caption">予定あり</Typography>
                </Box>
              )}
            </Box>
          );
        }}
      />

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
        <Typography variant="subtitle2" sx={{ width: "100%" }}>
          選択中
        </Typography>
        {props.selectedCompanyUsers.map((selectedCompanyUser, index) => (
          <Box
            key={index}
            sx={getSelectedMemberStyles({
              isSiteManager: selectedCompanyUser.userId === props.selectedSiteManagerId,
              isOverlapped: selectedCompanyUser.isScheduleOverlapped,
              overlapType: selectedCompanyUser.scheduleOverlappingType,
            })}
          >
            {selectedCompanyUser.userId === props.selectedSiteManagerId && <SiteManagerIcon />}
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {selectedCompanyUser.name}
            </Typography>
            {props.selectedSiteManagerId !== selectedCompanyUser.userId && (
              <Button
                startIcon={<CloseIcon size={18} />}
                onClick={() => props.handleClear(selectedCompanyUser)}
                sx={{ p: 0, minWidth: "auto", "& .MuiButton-icon": { m: 0 } }}
              />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

interface CustomPopperProps extends PopperProps {
  popperPlacement: PopperPlacementType;
}

const CustomPopper = styled((props: CustomPopperProps) => {
  const { popperPlacement, ...otherProps } = props;
  return (
    <Popper
      {...otherProps}
      placement={popperPlacement}
      style={{ width: "100%", maxWidth: "420px" }}
    />
  );
})({
  "& .MuiAutocomplete-paper": {
    marginLeft: "8px",
  },
});
