import { useState } from "react";
import { Range } from "react-date-range";
import { Box } from "@mui/material";
import { CustomDateRangePicker } from "components/molecules/custom-date-range-picker";
import { SearchTextField } from "components/molecules/search-text-field";
import { mutate } from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";

interface Props {
  fetchKey: string;
  scheduleDateRange: Range;
  keyword: string;
  onSubmitKeyword: (keyword: string) => void;
  onUpdateScheduleDateRange: (range: Range) => void;
  setCurrentPage: (page: number) => void;
}

export const ScheduleSearchResultTableHeader = (props: Props) => {
  const classes = styles();

  const [scheduleDateRangeLabel, setScheduleDateRangeLabel] = useState<string>("すべての期間");

  const handleChangeScheduleDateRange = (range: Range) => {
    props.onUpdateScheduleDateRange(range);
    if (range.startDate && range.endDate) {
      setScheduleDateRangeLabel(
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
          "-" +
          formatDateUtil(range.endDate, "yyyy年MM月dd日"),
      );
    } else {
      setScheduleDateRangeLabel("すべての期間");
    }
    props.setCurrentPage(1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onSubmitKeyword(e.target.value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    props.setCurrentPage(1);
    mutate(props.fetchKey);
  };

  const handleClear = () => {
    props.setCurrentPage(1);
    props.onSubmitKeyword("");
  };

  return (
    <Box sx={{ display: "flex" }} className={classes.tableHeader}>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", gap: "24px" }}>
          <CustomDateRangePicker
            dateName="すべての期間"
            dateRangeLabel={scheduleDateRangeLabel}
            dateRange={props.scheduleDateRange}
            handleChangeDateRange={handleChangeScheduleDateRange}
          />
          <form onSubmit={handleSubmit}>
            <SearchTextField
              id="keyword"
              name="keyword"
              placeholder="予定を検索する"
              width="440px"
              value={props.keyword}
              onChange={handleChange}
              onSubmit={handleClear}
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
};
