import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { UserSelectModal } from "components/modal/user-select-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { SubscriberType, subscriberTypes } from "data-access/repositories/notice/index.dto";
import {
  NoticeProjectStatusChange,
  NoticeProjectStatusChangeId,
  NoticeProjectStatusChangeIndexResponse,
  NoticeProjectStatusChangeNoticeTarget,
  OPTIONS,
} from "data-access/repositories/notice/project-status/project_status_change.dto";
import { noticeProjectStatusChangeRepository } from "data-access/repositories/notice/project-status/project_status_change.repository";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { snakeToCamelCase } from "utils/convertObjectKeyCase";
import { handleReduxError } from "utils/errorHandler";

const STypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
  marginBottom: "8px",
});

interface Props {
  data: NoticeProjectStatusChangeIndexResponse;
  fetchIndexKey: string;
  setIsLoading: (v: boolean) => void;
}

export const ProjectStatus = (props: Props) => {
  const { data, fetchIndexKey, setIsLoading } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const [formState, setFormState] = useState<NoticeProjectStatusChangeIndexResponse>([]);

  const convertData = useMemo(() => {
    return data.map((noticeProjectStatus) => {
      return {
        ...noticeProjectStatus,
        // 予定タイプが空配列の場合はすべての案件ステータスを選択
        projectStatusIdsForFilter: noticeProjectStatus.projectStatusIdsForFilter.length
          ? noticeProjectStatus.projectStatusIdsForFilter
          : mainState.projectStatusTypes.map((projectStatus) => projectStatus.id),
      };
    });
  }, [data]);

  useEffect(() => {
    if (data) {
      setFormState(convertData);
    }
  }, [data]);

  const handleAdd = async () => {
    try {
      await noticeProjectStatusChangeRepository.create();
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを追加しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知ルールの追加に失敗しました");
    }
  };

  const handleChangeForSubscriberTypeSelect = (
    e: SelectChangeEvent<SubscriberType[]>,
    index: number,
  ) => {
    const { name, value } = e.target;
    if (!value.length) return;

    setFormState((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value,
      };
      return newState;
    });
  };

  const handleChangeForProjectStatusSelect = (
    e: SelectChangeEvent<ProjectStatusTypeId[]>,
    index: number,
    targetInfo: any,
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => {
      const newState = [...prevState];
      const allProjectStatusIds = mainState.projectStatusTypes.map(
        (projectStatusType) => projectStatusType.id,
      );

      if (!value.length) {
        // valueが空の場合、すべてのProjectStatusTypeIdを選択
        newState[index] = {
          ...newState[index],
          [name]: allProjectStatusIds,
        };
      } else if (newState[index].projectStatusIdsForFilter.length === allProjectStatusIds.length) {
        // 現在の選択状態がすべてなら、選択したもののみを選択状態にする
        newState[index] = {
          ...newState[index],
          [name]: [targetInfo.props.value],
        };
      } else {
        newState[index] = {
          ...newState[index],
          [name]: value,
        };
      }

      return newState;
    });
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    setIsLoading(true);
    try {
      await noticeProjectStatusChangeRepository.update(formState[index].id, {
        isEnable: checked,
      });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知ルールの更新に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseForSelectBox = async (_: React.SyntheticEvent, index: number) => {
    setIsLoading(true);
    // すべての予定タイプが選択されている場合は空配列に変換
    const convertProjectStatusIdsForFilter =
      formState[index].projectStatusIdsForFilter.length === mainState.projectStatusTypes.length
        ? []
        : formState[index].projectStatusIdsForFilter;

    try {
      await noticeProjectStatusChangeRepository.update(formState[index].id, {
        ...formState[index],
        projectStatusIdsForFilter: convertProjectStatusIdsForFilter,
      });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知ルールの更新に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (notice: NoticeProjectStatusChange) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();
    if (res) {
      setIsLoading(true);
      try {
        await noticeProjectStatusChangeRepository.destroy(notice.id);
        mutate(fetchIndexKey);
        dispatch(mainOperations.updateSuccessMessage("通知ルールを削除しました"));
      } catch (error) {
        handleReduxError(error, dispatch, "通知ルールの削除に失敗しました");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isSelectedProjectStatus = (projectStatusId: ProjectStatusTypeId, index: number) => {
    return formState[index].projectStatusIdsForFilter.find(
      (projectStatusIdForFilter) => projectStatusIdForFilter === projectStatusId,
    );
  };

  const selectedProjectStatusesLabel = (selectedProjectStatusIds: ProjectStatusTypeId[]) => {
    if (selectedProjectStatusIds.length === mainState.projectStatusTypes.length) return "すべて";
    return mainState.projectStatusTypes
      .filter((projectStatus) => selectedProjectStatusIds.includes(projectStatus.id))
      .map((projectStatus) => projectStatus.name)
      .join(", ");
  };

  const isSelectedSubscriberType = (subscriberType: SubscriberType, index: number) => {
    return formState[index].subscriberTypes.find(
      (noticeSubscriberType) => noticeSubscriberType === subscriberType,
    );
  };

  const selectedSubscriberTypesLabel = (selectedSubscriberTypes: SubscriberType[]): string => {
    return subscriberTypes
      .filter((subscriberType) => selectedSubscriberTypes.includes(subscriberType.value))
      .map((subscriberType) => subscriberType.name)
      .join(", ");
  };

  const selectedUsersLabel = (targetUsers: NoticeProjectStatusChangeNoticeTarget): string => {
    const labels: string[] = [];
    Object.entries(OPTIONS).forEach(([key, value]) => {
      const camelCaseKey = snakeToCamelCase(key) as keyof NoticeProjectStatusChangeNoticeTarget;
      if (targetUsers[camelCaseKey]) {
        labels.push(value.label);
      }
    });
    const selectedUsers: User[] = targetUsers.userIds
      .map((userId) => mainState.users.find((user) => user.id === userId))
      .filter((user): user is User => user !== undefined);
    if (selectedUsers.length) {
      labels.push(selectedUsers.map((selectedUser) => selectedUser.name).join(", "));
    }

    return labels.join(", ");
  };

  const [selectUsersModal, setSelectUsersModal] = useState<{
    isOpen: boolean;
    id: NoticeProjectStatusChangeId;
    selectedTargetUsers: NoticeProjectStatusChangeNoticeTarget;
  }>({
    isOpen: false,
    id: 0 as NoticeProjectStatusChangeId,
    selectedTargetUsers: {
      inquiredBy: false,
      manager: false,
      userIds: [],
    },
  });

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <UserSelectModal
        isOpen={selectUsersModal.isOpen}
        id={selectUsersModal.id}
        selectedNoticeTarget={selectUsersModal.selectedTargetUsers}
        fetchIndexKey={fetchIndexKey}
        onClose={() =>
          setSelectUsersModal({
            isOpen: false,
            id: 0 as NoticeProjectStatusChangeId,
            selectedTargetUsers: {
              inquiredBy: false,
              manager: false,
              userIds: [],
            },
          })
        }
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography fontWeight="bold" fontSize="20px" color="primary">
          案件ステータスの変更
        </Typography>
        <Button
          onClick={handleAdd}
          variant="contained"
          startIcon={<AddCircleOutlinedIcon />}
          sx={{ height: "40px" }}
        >
          通知ルールを追加
        </Button>
      </div>

      <div style={{ marginBottom: "24px" }}>
        {formState.length ? (
          <div style={{ marginLeft: "20px" }}>
            {formState.map((notice, index) => (
              <React.Fragment key={index}>
                <div style={{ display: "flex", alignItems: "flex-end", gap: "24px" }}>
                  <div>
                    <STypography>案件ステータス（変更先）</STypography>
                    <Select
                      name="projectStatusIdsForFilter"
                      value={notice.projectStatusIdsForFilter}
                      onChange={(e, targetInfo) =>
                        handleChangeForProjectStatusSelect(e, index, targetInfo)
                      }
                      onClose={(_) => handleCloseForSelectBox(_, index)}
                      multiple
                      sx={{ width: "200px", height: "40px" }}
                      renderValue={(values) => selectedProjectStatusesLabel(values)}
                    >
                      {mainState.projectStatusTypes.map((projectStatusType) => (
                        <MenuItem key={projectStatusType.id} value={projectStatusType.id}>
                          {isSelectedProjectStatus(projectStatusType.id, index) ? (
                            <CheckIcon color="primary" sx={{ mr: 1 }} />
                          ) : (
                            <Box sx={{ px: 1.5, mr: 1 }} />
                          )}
                          {projectStatusType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <STypography>通知対象者</STypography>
                    <Box
                      onClick={() =>
                        setSelectUsersModal({
                          isOpen: true,
                          id: notice.id,
                          selectedTargetUsers: notice.noticeTarget,
                        })
                      }
                      sx={{
                        height: "40px",
                        width: "180px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: `1px solid ${theme.palette.grayScale[300]}`,
                        borderRadius: "4px",
                        cursor: "pointer",
                        p: "0 8px",
                        "&:hover": {
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedUsersLabel(notice.noticeTarget)}
                      </Typography>
                      <ExpandMoreIcon />
                    </Box>
                  </div>
                  <div>
                    <STypography>通知方法</STypography>
                    <Select
                      name="subscriberTypes"
                      value={notice.subscriberTypes}
                      onChange={(e) => handleChangeForSubscriberTypeSelect(e, index)}
                      onClose={(_) => handleCloseForSelectBox(_, index)}
                      multiple
                      sx={{ width: "200px", height: "40px" }}
                      renderValue={(values) => selectedSubscriberTypesLabel(values)}
                    >
                      {subscriberTypes.map((subscriberType) => (
                        <MenuItem key={subscriberType.value} value={subscriberType.value}>
                          {isSelectedSubscriberType(subscriberType.value, index) ? (
                            <CheckIcon color="primary" sx={{ mr: 1 }} />
                          ) : (
                            <Box sx={{ px: 1.5, mr: 1 }} />
                          )}
                          {subscriberType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Switch
                      name="isEnable"
                      checked={notice.isEnable}
                      onChange={(e) => handleToggle(e, index)}
                      color="primary"
                    />
                  </div>
                  <IconButton onClick={() => handleDelete(notice)}>
                    <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
                  </IconButton>
                </div>
                <Divider sx={{ my: "12px" }} />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <Typography fontSize="14px">通知ルールが設定されていません。</Typography>
        )}
      </div>
    </>
  );
};
