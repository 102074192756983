import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { Layout } from "components/templates/layout";
import { BuildingIndexRequest } from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import useSWR from "swr";
import { BuildingSidebar } from "./sidebar";
import { BuildingTable } from "./table";
import { BuildingTableHeader } from "./table-header";

export const Building = () => {
  const sortStorageKeyName = "buildingSort";
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
  const [sortItem, setSortItem] = useState<{ field: string; sort: string } | undefined>({
    field: "recently_created",
    sort: "desc",
  });

  const indexQuery = useMemo((): BuildingIndexRequest => {
    return {
      keyword,
      page,
      orderBy: sortItem?.field,
      direction: sortItem?.sort,
    };
  }, [sortItem, keyword, page]);

  const fetchIndexKey = `/api/v1/buildings?page=${page}&orderBy=${sortItem?.field}&direction=${sortItem?.sort}`;
  const { data, mutate, isValidating } = useSWR(fetchIndexKey, () =>
    buildingRepository.index(indexQuery),
  );

  useEffect(() => {
    // 物件の新規作成
    if (location.pathname === "/buildings/new") {
      setIsSidebarOpen(true);
      setIsCreateNew(true);
    }
    // 物件編集
    if (/^\/buildings\/\d+$/.test(location.pathname)) {
      setIsSidebarOpen(true);
      setIsCreateNew(false);
    }
  }, [location]);

  useEffect(() => {
    const localStorageSortItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageSortItem !== null) {
      setSortItem(JSON.parse(localStorageSortItem));
    }
  }, []);

  const handleSubmit = () => {
    mutate();
  };

  const handleClickCreate = () => {
    navigate("/buildings/new");
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
    navigate("/buildings");
  };

  const handleSortModelChange = (sortItems: GridSortModel) => {
    if (sortItems.length > 0) {
      localStorage.setItem(sortStorageKeyName, JSON.stringify(sortItems[0]));
      setSortItem(sortItems[0] as { field: string; sort: string });
    } else if (sortItems.length === 0) {
      localStorage.removeItem(sortStorageKeyName);
      setSortItem(undefined);
    }
  };

  return (
    <Layout>
      <Box>
        <BuildingTableHeader
          keyword={keyword}
          setKeyword={setKeyword}
          onClick={handleClickCreate}
          onSubmit={handleSubmit}
        />
        <BuildingTable
          data={data}
          page={page}
          setPage={setPage}
          isLoading={isValidating}
          handleSortModelChange={handleSortModelChange}
        />

        <BuildingSidebar
          isOpen={isSidebarOpen}
          onClose={handleClose}
          isCreateNew={isCreateNew}
          fetchIndexKey={fetchIndexKey}
        />
      </Box>
    </Layout>
  );
};
