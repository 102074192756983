import { ElementRef, useEffect, useRef, useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { TitleLabel } from "components/label/title-label";
import { EstimateBlock } from "components/molecules/estimate-block";
import { FileDropzone } from "components/molecules/file-dropzone";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import {
  EstimateResponse,
  ProjectEstimateId,
} from "data-access/repositories/project/estimate/estimate.dto";
import { estimateRepository } from "data-access/repositories/project/estimate/estimate.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mainState } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import useSWR, { mutate } from "swr";
import { handleReduxError } from "utils/errorHandler";

interface Props {
  state: ProjectSidebarState;
  mainState: mainState;
  onUpload: (files: File[]) => void;
  activityFetchKey: string;
}

const convert = (value: EstimateResponse[]) => {
  return value.map((v) => ({
    id: v.id,
    name: v.file_name,
    url: v.file_url,
    code: v.code,
    statusType: v.status_type,
    userId: v.user_id,
    uploadedDate: v.created_date,
    taxIncludedAmount: v.tax_included_amount,
    displayAmount: v.display_amount,
    taxType: v.tax_type,
  }));
};

export const ProjectSidebarEstimateBlock = (props: Props) => {
  const dispatch = useAppDispatch();
  const projectId = props.state.project.id;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const { data, mutate: estimateMutate } = useSWR(
    props.state.project.id ? `/api/v1/projects/${projectId}/estimates` : undefined,
    () =>
      estimateRepository.index(props.state.project.id).then((res) => {
        return convert(res);
      }),
  );

  useEffect(() => {
    estimateMutate();
  }, [props.state.project.id, props.state.isEstimateSubmitted]);

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>, estimateId: number) => {
    setIsEditing(false);
    try {
      const value = Number(e.target.value);
      if (isNaN(value)) return;

      await estimateRepository.updateAmount(projectId, estimateId as ProjectEstimateId, {
        displayAmount: value,
      });

      // 売上金額に反映させるためにprojectを再取得
      dispatch(projectSidebarOperations.showProject(projectId));

      data &&
        estimateMutate([
          ...data.map((v) => (v.id === estimateId ? { ...v, displayAmount: value } : { ...v })),
        ]);
      mutate(props.activityFetchKey);
      dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "見積書の更新に失敗しました");
    }
  };

  const handleDelete = async (id: number) => {
    if (!deleteConfirmRef.current) return;
    const confirm = await deleteConfirmRef.current.confirm();

    if (confirm) {
      try {
        await estimateRepository.destroy(projectId, id as ProjectEstimateId);

        data && estimateMutate();
        mutate(props.activityFetchKey);
        dispatch(mainOperations.updateSuccessMessage("見積書を削除しました"));
      } catch (error) {
        dispatch(
          mainOperations.updateErrorMessage(
            error.response.data.message || "見積書の削除に失敗しました",
          ),
        );
      }
    }
  };

  const handleChange = async (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    id?: number | null,
  ) => {
    try {
      switch (event.target.name) {
        case "headerEstimateUserId": {
          await estimateRepository.update(projectId, id as ProjectEstimateId, {
            userId: event.target.value as number,
          });

          data &&
            estimateMutate(
              [
                ...data.map((v) =>
                  v.id === id ? { ...v, userId: event.target.value as number } : { ...v },
                ),
              ],
              false,
            );
          dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
          break;
        }
        case "headerEstimateAmount": {
          setIsEditing(true);
          data &&
            estimateMutate(
              [
                ...data.map((v) =>
                  v.id === id ? { ...v, displayAmount: event.target.value as number } : { ...v },
                ),
              ],
              false,
            );
          break;
        }
        case "headerEstimateTaxType": {
          await estimateRepository.updateTaxType(projectId, id as ProjectEstimateId, {
            taxType: event.target.value as string,
          });
          data && estimateMutate();
          dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
          break;
        }
      }
      mutate(props.activityFetchKey);
    } catch (error) {
      dispatch(
        mainOperations.updateErrorMessage(
          error.response.data.message || "見積書の更新に失敗しました",
        ),
      );
    }
  };

  const handleClick = async (estimateId: number, statusType: "decided" | "not_yet_decided") => {
    try {
      await estimateRepository.update(projectId, estimateId as ProjectEstimateId, {
        statusType,
      });
      // 売上金額に反映させるためにprojectを再取得
      dispatch(projectSidebarOperations.showProject(projectId));
      data &&
        estimateMutate(
          [...data.map((v) => (v.id === estimateId ? { ...v, statusType } : { ...v }))],
          false,
        );
      mutate(props.activityFetchKey);
      dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
    } catch (error) {
      dispatch(
        mainOperations.updateErrorMessage(
          error.response.data.message || "見積書の更新に失敗しました",
        ),
      );
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />

      <TitleLabel title="見積書" />
      <Box>
        <FileDropzone onDrop={props.onUpload} isDisable={!props.state.isExisting} />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {(data || []).map((estimate) => (
            <EstimateBlock
              key={estimate.id}
              project={props.state.project}
              estimate={estimate}
              onDelete={handleDelete}
              onChange={handleChange}
              onBlur={handleBlur}
              users={props.mainState.users}
              handleClick={handleClick}
              isEditing={isEditing}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
