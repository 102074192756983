import { Typography } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  title: string;
  sx?: object;
}

export const TitleLabel = (props: Props) => {
  return (
    <Typography
      sx={{
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
        fontWeight: "bold",
        ...props.sx,
      }}
    >
      {props.title}
    </Typography>
  );
};
