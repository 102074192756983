import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

interface Props {
  params: GridRenderCellParams<any>;
}

export const MultilineTooltip: React.FC<Props> = ({ params }) => {
  return (
    <Tooltip
      title={params.value.split("\n").map((str: string, index: number) => (
        <React.Fragment key={index}>
          <Typography fontSize="11px">
            {str}
            <br />
          </Typography>
        </React.Fragment>
      ))}
      placement="top"
      arrow
    >
      <Typography
        sx={{
          mr: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Typography>
    </Tooltip>
  );
};
