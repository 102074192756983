import { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DateSelectArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { EventReceiveArg } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Search } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
  SelectChangeEvent,
  Popover,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "components/icon/close-icon";
import { OutlookIcon } from "components/icon/outlook-icon";
import { RecurrenceUpdateInfoModal } from "components/molecules/recurrence-update-info-modal";
import { SelectAssignmentsModal } from "components/molecules/select-assignments-modal";
import { NoDatedScheduleBlock } from "components/organisms/no-dated-schedule-block";
import { ScheduleDeleteConfirmDialog } from "components/organisms/schedule-delete-confirm-dialog";
import { ScheduleRightClickMenu } from "components/organisms/schedule-right-click-menu";
import { SchedulesConfirmedDialog } from "components/organisms/schedules-confirmed-dialog";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import {
  CompanyUser,
  CompanyUserIndexResponse,
} from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { GroupId, GroupIndexResponse } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { externalScheduleRepository } from "data-access/repositories/microsoft/external_schedule/external_schedule.repository";
import { confirmedScheduleChangeRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-change.repository";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { User, UserId } from "data-access/repositories/user/user.dto";
import ja from "date-fns/locale/ja";
import { theme } from "extensions/theme";
import { useHolidays } from "hooks/useHolidays";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { calendarOperations } from "store/calendar/operations";
import { selectCalendar } from "store/calendar/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { scheduleRightClickMenuOperations } from "store/schedule-right-click-menu/operations";
import { selectScheduleRightClickMenu } from "store/schedule-right-click-menu/slice";
import useSWR, { mutate } from "swr";
import Cookies from "universal-cookie";
import { HOUR } from "utils/constant";
import { convertScheduleToEvent, scheduleTitle } from "utils/convertScheduleToCalendarEvent";
import { convertToCalendarStartDay } from "utils/convertToCalendarStartDay";
import { handleReduxError } from "utils/errorHandler";
import { isNonNumericString } from "utils/helpers";
import { StyleWrapper } from "./styled";
import { ExternalScheduleDetailModal } from "../external-schedule-detail-modal";
import { RecurrenceRuleConfirmDialog } from "../recurrence-rule-confirm-dialog";
import { ScheduleCreateModal } from "../schedule-create-modal";
import { ScheduleDetailModal } from "../schedule-detail-modal";
import { ScheduleEditModal } from "../schedule-edit-modal";
registerLocale("ja", { ...ja, options: { ...ja.options, weekStartsOn: 1 } });

export type ViewType = "dayGridMonth" | "resourceTimelineWeek" | "resourceTimelineDay";

const lastOpenedViewTypeKey = "lastOpenedViewType";
const lastOpenedDateTimeKey = "lastOpenedDateTime";
const filteredGroupsKey = "filteredGroups";
const filteredUsersKey = "filteredUsers";

export const CalenderMain = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { saveScrollPosition, scrollToSavedPosition } = useScrollToPosition("calendar");

  const cookie = cookieRepository.get();
  const myselfId: UserId = cookie.id;
  const calendarRef = useRef<FullCalendar>(null!);
  const state = useAppSelector(selectCalendar);
  const mainState = useAppSelector(selectMain);
  const rightClickMenuState = useAppSelector(selectScheduleRightClickMenu);
  const [viewType, setViewType] = useState<ViewType>("dayGridMonth");
  const [startDateTime, setStartDateTime] = useState<Date | null>(null);
  const [endDateTime, setEndDateTime] = useState<Date | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRecurrenceUpdateInfoModal, setIsRecurrenceUpdateInfoModal] = useState<boolean>(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [isIndicateMicrosoftSchedule, setIsIndicateMicrosoftSchedule] = useState<boolean>(false);
  const [selectAssignmentsModal, setSelectAssignmentsModal] = useState<{
    isOpen: boolean;
    scheduleId: ScheduleId;
    assignments: User[];
  }>({ isOpen: false, scheduleId: 0 as ScheduleId, assignments: [] });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [scheduleCreateModal, setScheduleCreateModal] = useState<{
    isOpen: boolean;
    startTime: string;
    endTime: string;
    userIds?: UserId[];
  }>({ isOpen: false, startTime: "", endTime: "" });

  const [deleteDialog, setDelete] = useState<{
    isOpen: boolean;
    id: ScheduleId | undefined;
  }>({
    isOpen: false,
    id: undefined,
  });

  const [recurrenceDialog, setRecurrenceDialog] = useState<{
    isOpen: boolean;
    id: ScheduleId | undefined;
    type: "delete" | "update";
    onUpdateFunc?: (withFutures: boolean) => Promise<void>;
    onCancelFunc?: () => void;
  }>({
    isOpen: false,
    id: undefined,
    type: "delete",
  });

  const [schedulesConfirmed, setSchedulesConfirmed] = useState<{
    isOpen: boolean;
    scheduleIds: number[];
  }>({
    isOpen: false,
    scheduleIds: [],
  });

  const noticeScheduleDialogRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const { data: groups } = useSWR("/api/v1/groups", groupRepository.index);

  const cookies = new Cookies();

  // 初期値用のフィルタ項目
  const savedGroups: Array<string> = useMemo(() => {
    const lastFilteredGroups = localStorage.getItem(filteredGroupsKey);
    return lastFilteredGroups ? JSON.parse(lastFilteredGroups) : ["all"];
  }, []);
  const savedUsers: Array<string> = useMemo(() => {
    const lastFilteredUsers = localStorage.getItem(filteredUsersKey);
    return lastFilteredUsers ? JSON.parse(lastFilteredUsers) : ["all"];
  }, []);

  // 選択しているフィルタ項目
  const [selectedFilterGroupItem, setSelectedFilterGroupItem] =
    useState<Array<string>>(savedGroups);
  const [selectedFilterUserItem, setSelectedFilterUserItem] = useState<Array<string>>(savedUsers);

  // フィルタ変更は基本的にこちらから行う
  const handleSetFilterGroups = (values: Array<string>) => {
    setSelectedFilterGroupItem(values);
    localStorage.setItem(filteredGroupsKey, JSON.stringify(values));
  };
  const handleSetFilterUsers = (values: Array<string>) => {
    setSelectedFilterUserItem(values);
    localStorage.setItem(filteredUsersKey, JSON.stringify(values));
  };

  // 選択しているグループのIds
  const selectedGroupIds: GroupId[] = useMemo(() => {
    return selectedFilterGroupItem
      .filter((item) => item.toString() !== "all")
      .map((item) => parseInt(item.replace(/\D/g, "")) as GroupId);
  }, [selectedFilterGroupItem]);

  // 選択しているユーザーのIds
  const selectedUserIds: UserId[] = useMemo(() => {
    return selectedFilterUserItem
      .filter((item) => item.toString() !== "all")
      .map((item) => {
        return parseInt(item.replace(/\D/g, "")) as UserId;
      });
  }, [selectedFilterUserItem]);

  // クリックアクションを検知して、スクロール位置を保存する
  useEffect(() => {
    const handleClick = () => {
      saveScrollPosition();
    };

    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [saveScrollPosition]);

  const fetchNoDatedEventKey = `/api/v1/schedules?is_time_entered=false`;
  const { data: noDatedEvents, mutate: noDatedEventsMutate } = useSWR(fetchNoDatedEventKey, () =>
    scheduleRepository.index({ isTimeEntered: false }),
  );

  const { holidays } = useHolidays({
    holidayRangeStart: startDateTime,
    holidayRangeEnd: endDateTime,
  });

  const fetchIndexKey = useMemo((): string => {
    return `/api/v1/schedules?scheduledRangeStart=${startDateTime}&scheduledRangeEnd=${endDateTime}&viewType=${viewType}`;
  }, [startDateTime, isIndicateMicrosoftSchedule]);

  // 日付範囲の中間の日付を計算する関数
  const calculateMiddleDate = (start: Date, end: Date): Date => {
    const startTime: number = start.getTime();
    const endTime: number = end.getTime();
    const middleTime: number = startTime + (endTime - startTime) / 2;
    const middleDate = new Date(middleTime);
    return new Date(middleDate.getFullYear(), middleDate.getMonth(), 15);
  };

  // 以前開いていたviewTypeと日付をlocalStorageから取得し初回に開く
  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const lastOpenedDateTime = localStorage.getItem(lastOpenedDateTimeKey);
    const lastOpenedViewType = localStorage.getItem(lastOpenedViewTypeKey);
    if (lastOpenedDateTime && lastOpenedViewType) {
      const { startDateTime, endDateTime } = JSON.parse(lastOpenedDateTime);
      const start = new Date(startDateTime);
      const end = new Date(endDateTime);

      let middleDate;
      // 月表示なら中間の日付に移動
      if (lastOpenedViewType === "dayGridMonth") {
        middleDate = calculateMiddleDate(start, end);
      } else {
        middleDate = start;
      }

      calendarApi.gotoDate(middleDate);
      setStartDateTime(start);
      setEndDateTime(end);
      saveViewType(lastOpenedViewType as ViewType);
    } else {
      // TODO: リリース後に削除
      // リリース直後はlocalStorageにlastOpenedDateTimeがないのでセットする
      saveCurrentDateTime();
    }
  }, []);

  const {
    data: events,
    mutate: mutateEvents,
    isValidating,
  } = useSWR(
    fetchIndexKey,
    () => {
      if (!startDateTime || !endDateTime) return;
      const fetchEvents =
        viewType === "dayGridMonth"
          ? scheduleRepository.index({
              scheduleRangeStart: startDateTime.toString(),
              scheduleRangeEnd: endDateTime.toString(),
              userIds: selectedUserIds,
              groupIds: selectedGroupIds,
              isTimeEntered: true,
            })
          : scheduleRepository.index({
              scheduleRangeStart: startDateTime.toString(),
              scheduleRangeEnd: endDateTime.toString(),
              userIds: selectedUserIds,
              groupIds: selectedGroupIds,
            });

      return fetchEvents
        .then((schedule) => schedule.map((schedule) => convertScheduleToEvent(schedule, viewType)))
        .then((events) => {
          if (isIndicateMicrosoftSchedule) {
            return externalScheduleRepository
              .index({
                scheduleRangeStart: startDateTime.toISOString(),
                scheduleRangeEnd: endDateTime.toISOString(),
                userIds: selectedUserIds,
                groupIds: selectedGroupIds,
              })
              .then((microsoftSchedules) => {
                const microsoftEvents = microsoftSchedules.map((event) => {
                  return {
                    id: event.id.toString(),
                    resourceIds: !event.users.length
                      ? ["0"]
                      : event.users.map((user) => {
                          return user.id.toString();
                        }),
                    title: scheduleTitle(
                      event.display_name,
                      event.display_name,
                      event.users,
                      viewType,
                    ),
                    note: event.note,
                    start: event.start_time,
                    end: event.end_time,
                    textColor: theme.palette.primary.main,
                    display: "block",
                    isConfirmed: false,
                    backgroundColor: theme.palette.grayScale[0],
                    borderColor: theme.palette.primary.main,
                    isHoliday: false,
                    recurrenceRule: undefined,
                  };
                });
                return [...events, ...microsoftEvents];
              })
              .catch((error) => {
                console.error(error);
                return events;
              });
          }
          return events;
        });
    },
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    (async () => {
      holidays.forEach(function (h) {
        const headerCell = document.querySelector(`[data-date="${h.date}"]`) as HTMLElement;
        headerCell?.classList.add("fc-day-holiday");
      });
    })();
  }, [holidays]);

  const { data: companyUsers } = useSWR("/api/v1/company_users/", companyUserRepository.index);
  const activeCompanyUsers = companyUsers?.filter((companyUser) => !companyUser.isDeactivate);

  const resources = useMemo(() => {
    let displayCompanyUsers = [
      ...(activeCompanyUsers?.map((companyUser: CompanyUser, index) => {
        return {
          id: companyUser.userId.toString(),
          title: companyUser.name,
          order: index,
        };
      }) || []),
    ];

    // グループに所属しているユーザーのid
    const groupUserIds = selectedGroupIds.flatMap((groupId) => {
      return activeCompanyUsers
        ?.filter((companyUser) => companyUser.groupId === groupId)
        .map((companyUser) => companyUser.userId);
    });

    // 「自分」「グループ」の両方が選択されているとき
    if (selectedUserIds.length > 0 && selectedGroupIds.length > 0) {
      const filterByUser = displayCompanyUsers.filter((item) => {
        return selectedUserIds.includes(parseInt(item.id) as UserId);
      });
      const filterByGroup = displayCompanyUsers.filter((item) => {
        return groupUserIds.includes(parseInt(item.id) as UserId);
      });
      const mergeDisplayCompanyUsers = filterByUser.concat(filterByGroup);
      // 重複を削除
      displayCompanyUsers = Array.from(
        new Set(mergeDisplayCompanyUsers.map((e) => JSON.stringify(e))),
      ).map((e) => JSON.parse(e));

      return displayCompanyUsers;
    }
    groupUserIds.concat(selectedUserIds);

    // 「ユーザー」のみが選択されているとき
    if (selectedUserIds.length > 0) {
      displayCompanyUsers = displayCompanyUsers.filter((item) => {
        return selectedUserIds.includes(parseInt(item.id) as UserId);
      });
    }
    // 「グループ」のみが選択されているとき
    if (selectedGroupIds.length > 0) {
      displayCompanyUsers = displayCompanyUsers.filter((item) => {
        return groupUserIds.includes(parseInt(item.id) as UserId);
      });
    }

    return [
      {
        id: "0",
        title: "参加者未定",
        order: -1,
      },
      ...displayCompanyUsers,
    ];
  }, [companyUsers, selectedFilterGroupItem, selectedFilterUserItem]);

  // エラーが起きた時、元の状態に戻すためにfetchしなおす
  useEffect(() => {
    if (state.errorMessage) {
      mutateEvents();
    }
  }, [state.errorMessage]);

  // 月表示で日付未設定からDropしたあと
  // 月表示で、右クリックからの処理のあと
  useEffect(() => {
    mutateEvents();
    scrollToSavedPosition();
  }, [rightClickMenuState.isSubmitted, state.isDropped, state.isSubmitted, state.isDestroyed]);

  const [externalDetail, setExternalDetail] = useState<{
    isOpen: boolean;
    title: string;
    startTime: string;
    endTime: string;
    note: string;
    users: User[];
  }>({ isOpen: false, title: "", startTime: "", endTime: "", note: "", users: [] });

  // [共通]予定をクリックした時
  const handleEventClick = (info: EventClickArg) => {
    // idがBase64形式なら専用の詳細画面を表示
    if (isNonNumericString(info.event.id)) {
      setExternalDetail({
        isOpen: true,
        title: info.event.title,
        startTime: info.event.startStr,
        endTime: info.event.endStr,
        note: info.event.extendedProps.note,
        users: mainState.users.filter((user) => {
          return info.event._def.resourceIds?.includes(user.id.toString());
        }),
      });
    } else {
      navigate(`/calendar/${info.event.id}`);
      setIsDetail(true);
    }
  };

  useEffect(() => {
    const url = location.pathname;
    const parts = url.split("/");
    const id = parts[2];
    const locationState = location.state;
    if (!id || locationState?.isToRightClick) return;
    setIsDetail(true);
  }, [location.pathname]);

  // 通知の確認ダイアログを出す条件を満たしているか
  const isNotifyDialogCondition = async (
    isConfirmed: boolean,
    userIds: UserId[],
  ): Promise<boolean> => {
    if (!isConfirmed) return false;
    if (userIds.length === 0) return false;
    if (userIds.length === 1 && userIds[0] === myselfId) return false;
    const response = await confirmedScheduleChangeRepository.show();
    return response.isEnable;
  };

  // 通知の確認を求めるダイアログを表示し、その結果を取得
  const getNotifyConfirmation = async (): Promise<boolean> => {
    if (!noticeScheduleDialogRef.current) return false;
    let isNotify: boolean = false;
    isNotify = await noticeScheduleDialogRef.current?.confirm();
    return isNotify;
  };

  // [月]日付未設定からDropした時
  const handleEventReceive = async (info: EventReceiveArg) => {
    const { event } = info;
    event.remove();

    const isConfirmed = event.extendedProps.isConfirmed === "true";
    const userIds = event.extendedProps.users.map((user: User) => user.id);
    let isNotify: boolean = false;
    if (await isNotifyDialogCondition(isConfirmed, userIds)) {
      isNotify = await getNotifyConfirmation();
    }
    dispatch(
      calendarOperations.dropGridSchedule(Number(event.id) as ScheduleId, false, {
        startTime: `${event.startStr}T8:00:00`,
        endTime: `${event.startStr}T17:00:00`,
        isNotify,
      }),
    );
    noDatedEventsMutate(
      noDatedEvents?.filter((noDatedEvent) => {
        return noDatedEvent.id !== Number(event.id);
      }),
      false,
    );
  };

  /**
   * カレンダーにViewTypeの反映と、localStorageに保存.
   *
   * @param viewType 反映するViewType（月、週、日）.
   */
  const saveViewType = (viewType: ViewType) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(viewType);
    setViewType(viewType);
    localStorage.setItem(lastOpenedViewTypeKey, viewType);
  };

  // 最後に開いた年月日をlocalStorageに保存
  const saveCurrentDateTime = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDateTime = {
      startDateTime: calendarApi.view.activeStart,
      endDateTime: calendarApi.view.activeEnd,
    };
    setStartDateTime(currentDateTime.startDateTime);
    setEndDateTime(currentDateTime.endDateTime);
    localStorage.setItem(lastOpenedDateTimeKey, JSON.stringify(currentDateTime));
  };

  // [月]予定を移動した時
  const handleMonthGridEventChange = async (info: EventChangeArg) => {
    // Outlookの予定は編集できないように
    if (isNonNumericString(info.event.id)) {
      info.revert();
      dispatch(mainOperations.updateErrorMessage("Outlookの予定は編集できません"));
      return;
    }

    if (info.event.extendedProps.recurrenceRule) {
      const beforeDow = info.oldEvent.start?.getDay();
      const afterDow = info.event.start?.getDay();
      const resultDaysOfWeek = info.event.extendedProps.recurrenceRule.days_of_week.map(
        (selectedDow: number) => {
          if (selectedDow === beforeDow) {
            return afterDow;
          }
          return selectedDow;
        },
      );
      setRecurrenceDialog({
        isOpen: true,
        id: Number(info.event.id) as ScheduleId,
        type: "update",
        onUpdateFunc: async (withFutures: boolean) => {
          try {
            await scheduleRepository.update(Number(info.event.id) as ScheduleId, withFutures, {
              startTime: info.event.startStr.replace("+09:00", ""),
              endTime: info.event.endStr.replace("+09:00", ""),
              recurrenceRuleId: info.event.extendedProps.recurrenceRule.id,
              recurrenceRule: {
                interval: info.event.extendedProps.recurrenceRule.interval,
                frequencyType: info.event.extendedProps.recurrenceRule.frequency_type,
                daysOfWeek: resultDaysOfWeek,
                endType: info.event.extendedProps.recurrenceRule.end_type,
                endDate: info.event.extendedProps.recurrenceRule.end_date,
                count: info.event.extendedProps.recurrenceRule.count,
              },
            });
            dispatch(mainOperations.updateSuccessMessage("予定を更新しました"));
          } catch (error) {
            info.revert();
            handleReduxError(error, dispatch, "予定の更新に失敗しました");
          }
        },
        onCancelFunc: () => {
          info.revert();
          dispatch(mainOperations.updateIsLoading(false));
        },
      });
    } else {
      let isNotify: boolean = false;
      const resourceIds: UserId[] = info.event._def.resourceIds
        ? info.event._def.resourceIds.filter((id) => id !== "0").map((id) => parseInt(id) as UserId)
        : [];
      if (await isNotifyDialogCondition(info.event.extendedProps.isConfirmed, resourceIds)) {
        isNotify = await getNotifyConfirmation();
      }

      dispatch(mainOperations.updateIsLoading(true));
      try {
        await scheduleRepository.update(Number(info.event.id) as ScheduleId, false, {
          startTime: info.event.startStr.replace("+09:00", ""),
          endTime: info.event.endStr.replace("+09:00", ""),
          userIds: resourceIds,
          isNotify,
        });
        dispatch(mainOperations.updateSuccessMessage("予定を更新しました"));
      } catch (error) {
        info.revert();
        handleReduxError(error, dispatch, "予定の更新に失敗しました");
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  // [週・日]予定を移動した時
  const handleTimelineEventChange = async (info: EventChangeArg) => {
    const { event: currentEvent, oldEvent } = info;

    // Outlookの予定は編集できないように
    if (isNonNumericString(currentEvent.id)) {
      info.revert();
      dispatch(mainOperations.updateErrorMessage("Outlookの予定は編集できません"));
      return;
    }

    // すでに参加しているユーザに予定を移動しようとしたらtrue
    const isIncludedUser: boolean | undefined = currentEvent._def.resourceIds?.every((id) =>
      oldEvent._def.resourceIds?.includes(id),
    );
    // [日]時間の変更が伴う場合はtrue
    const isTimeChanged: boolean =
      currentEvent.startStr !== oldEvent.startStr || currentEvent.endStr !== oldEvent.endStr;

    if (isIncludedUser && !isTimeChanged) {
      dispatch(mainOperations.updateErrorMessage("そのメンバーはすでに参加しています"));
      info.revert();
      return;
    }
    // 予定を参加者未定にDnDしたらtrue
    const isUnassigned: boolean | undefined = currentEvent._def.resourceIds?.includes("0");

    // 確定予定の日時を変更した&DnD先が参加者未定ではない場合、通知の確認を求める
    let isNotify: boolean = false;
    const resourceIds: UserId[] = currentEvent._def.resourceIds
      ? currentEvent._def.resourceIds.filter((id) => id !== "0").map((id) => parseInt(id) as UserId)
      : [];
    if (
      isTimeChanged &&
      !isUnassigned &&
      (await isNotifyDialogCondition(currentEvent.extendedProps.isConfirmed, resourceIds))
    ) {
      isNotify = await getNotifyConfirmation();
    }
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await scheduleRepository.update(Number(currentEvent.id) as ScheduleId, false, {
        startTime: currentEvent.startStr.replace("+09:00", ""),
        endTime: currentEvent.endStr.replace("+09:00", ""),
        userIds: resourceIds,
        ...(!isUnassigned && { isNotify }),
      });
      // 繰り返し予定はDnDに対応していないので、更新成功時にダイアログを表示する
      if (currentEvent.extendedProps.recurrenceRule) {
        setIsRecurrenceUpdateInfoModal(true);
      }

      dispatch(mainOperations.updateSuccessMessage("予定を更新しました"));
      mutateEvents();
    } catch (error) {
      info.revert();
      handleReduxError(error, dispatch, "予定の更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  // [週・日]ドラッグにより範囲を選択した時
  const handleTimelineSelect = (info: DateSelectArg) => {
    if (viewType === "resourceTimelineWeek") {
      info.start.setHours(HOUR.START_OF_ALL_DAY);
      info.end.setDate(info.end.getDate() - 1);
      info.end.setHours(HOUR.END_OF_ALL_DAY);
      setScheduleCreateModal({
        isOpen: true,
        startTime: info.start.toString(),
        endTime: info.end.toString(),
      });
    }
    if (viewType === "resourceTimelineDay") {
      setScheduleCreateModal({
        isOpen: true,
        startTime: info.start.toString(),
        endTime: info.end.toString(),
      });
    }
    // 参加者未定でない場合、メンバーを選択済みにする
    if (info.resource && info.resource.id !== "0") {
      const userIds = [Number(info.resource.id) as UserId];
      setScheduleCreateModal((prevState) => ({
        ...prevState,
        userIds,
      }));
    }
  };

  const handleClickCreateSchedule = () => {
    setScheduleCreateModal({ isOpen: true, startTime: initialStartTime, endTime: initialEndTime });
    if (viewType === "dayGridMonth" || viewType === "resourceTimelineWeek") {
      setScheduleCreateModal({ isOpen: true, startTime: "", endTime: "" });
    }
  };

  const handleDateClick = (info: any) => {
    if (info.jsEvent.shiftKey) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("resourceTimelineDay", info.date);
      setViewType("resourceTimelineDay");
    } else {
      // info.dateをDateオブジェクトに生成し直すことで値渡しにする
      const startDateTime: Date = new Date(info.date);
      const endDateTime: Date = new Date(info.date);
      startDateTime.setDate(startDateTime.getDate());
      startDateTime.setHours(HOUR.START_OF_ALL_DAY);
      endDateTime.setDate(endDateTime.getDate());
      endDateTime.setHours(HOUR.END_OF_ALL_DAY);

      setScheduleCreateModal({
        isOpen: true,
        startTime: startDateTime.toString(),
        endTime: endDateTime.toString(),
      });
    }
  };

  const handleEventRightClick = (arg: any) => {
    const eventId = arg.event.id;
    if (isNonNumericString(eventId) || !arg.event.extendedProps.isAccessible) return;

    const onContextMenu = (e: any) => {
      e.preventDefault();
      dispatch(scheduleRightClickMenuOperations.open());
      dispatch(
        scheduleRightClickMenuOperations.setObject({
          id: Number(eventId) as ScheduleId,
          userId: Number(arg.event._def.resourceIds[0]),
        }),
      );
      dispatch(scheduleRightClickMenuOperations.setAnchorEl(e.currentTarget));
    };

    arg.el.addEventListener("contextmenu", onContextMenu);

    return () => {
      arg.el.removeEventListener("contextmenu", onContextMenu);
    };
  };

  const handleEventDragStart = () => {
    handleCloseSnackBar();
    dispatch(scheduleRightClickMenuOperations.close());
  };

  const calendarViewButtons = useMemo(() => {
    const buttons: { [prop: string]: any } = {};

    buttons.dayGridMonth = {
      text: "月",
      click: () => {
        saveViewType("dayGridMonth");
        saveCurrentDateTime();
      },
    };
    buttons.resourceTimelineWeek = {
      text: "週",
      click: () => {
        saveViewType("resourceTimelineWeek");
        saveCurrentDateTime();
      },
    };
    buttons.resourceTimelineDay = {
      text: "日",
      click: () => {
        saveViewType("resourceTimelineDay");
        saveCurrentDateTime();
      },
    };
    return buttons;
  }, []);

  const handleClickSchedulesConfirmed = async () => {
    const schedule_ids: number[] = [];
    for (const schedule of events!) {
      if (schedule.isConfirmed) {
        continue;
      }
      schedule_ids.push(Number(schedule.id));
    }

    const isConfirmedNotShowAgain = cookies.get("isSchedulesConfirmedNotShowAgain");
    if (isConfirmedNotShowAgain) {
      await scheduleRepository.confirm({ schedule_ids });
      mutateEvents();
    } else {
      setSchedulesConfirmed({ isOpen: true, scheduleIds: schedule_ids });
    }
  };

  const calendarLeftButtons = useMemo(() => {
    const buttons: string[] = [];

    buttons.push("prev");
    buttons.push("title");
    buttons.push("next");
    buttons.push("todayButton");
    if (viewType === "resourceTimelineDay") {
      buttons.push("miniCalendarOpenButton");
    }
    return buttons;
  }, [viewType]);

  const [initialStartTime, setInitialStartTime] = useState<string>("");
  const [initialEndTime, setInitialEndTime] = useState<string>("");

  const handleDatesChange = (arg: any) => {
    // 予定作成画面の日付にデフォルトとして取得期間をセットする
    arg.start.setHours(HOUR.START_OF_ALL_DAY);
    arg.end.setDate(arg.end.getDate() - 1);
    arg.end.setHours(HOUR.END_OF_ALL_DAY);

    setInitialStartTime(arg.start.toString());
    setInitialEndTime(arg.end.toString());
  };

  const handleCloseSnackBar = () => {
    dispatch(calendarOperations.deleteSnackbarMessage());
    dispatch(scheduleRightClickMenuOperations.deleteSnackbarMessage());
    dispatch(mainOperations.updateSuccessMessage(""));
    dispatch(mainOperations.updateErrorMessage(""));
  };

  const handleEvents = (events: EventApi[]) => {
    if (viewType !== "dayGridMonth") return;
    const eventIdCountList: { [key: string]: number } = {};
    events.forEach((event) => {
      if (event.id) {
        if (eventIdCountList[event.id]) {
          eventIdCountList[event.id]++;
        } else {
          eventIdCountList[event.id] = 1;
        }
      }
    });

    const duplicateEvents = events.filter((event) => event.id && eventIdCountList[event.id] > 1);
    if (duplicateEvents.length) {
      duplicateEvents.splice(-1, 1);
      duplicateEvents.map((event) => event.remove());
    }
  };

  const handleDeleteRecurrenceRule = async (withFutures: boolean) => {
    setIsLoading(true);
    try {
      await scheduleRepository.destroy(recurrenceDialog.id! as ScheduleId, withFutures);
      events &&
        mutateEvents([...events.filter((event) => event.id !== String(recurrenceDialog.id))]);
      dispatch(mainOperations.updateSuccessMessage("予定を削除しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsDetail(false);
      navigate("/calendar");
      setRecurrenceDialog({ isOpen: false, id: undefined, type: "delete" });
      scrollToSavedPosition();
      setIsLoading(false);
    }
  };

  const handleDeleteSchedule = async () => {
    setIsLoading(true);
    try {
      await scheduleRepository.destroy(deleteDialog.id! as ScheduleId, false);
      events && mutateEvents([...events.filter((event) => event.id !== String(deleteDialog.id))]);
      dispatch(mainOperations.updateSuccessMessage("予定を削除しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsDetail(false);
      navigate("/calendar");
      setDelete({ isOpen: false, id: undefined });
      scrollToSavedPosition();
      noDatedEventsMutate(
        noDatedEvents?.filter((event) => {
          return event.id !== Number(deleteDialog.id);
        }),
        false,
      );
      setIsLoading(false);
    }
  };

  const handleCheckMicrosoftSchedule = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setIsIndicateMicrosoftSchedule(e.target.checked);
    mutateEvents();
  };

  const handleClearFilterSetting = async () => {
    await handleSetFilterGroups(["all"]);
    await handleSetFilterUsers(["all"]);
    mutateEvents();
  };

  return (
    <>
      <AsyncConfirmDialog
        ref={noticeScheduleDialogRef}
        content="予定の変更を通知しますか？"
        yesButtonColor="primary"
        yesButtonText="はい"
      />

      {viewType === "resourceTimelineDay" && (
        <Popover
          open={isDatePickerOpen}
          onClose={() => setIsDatePickerOpen(false)}
          sx={{ position: "fixed", left: "550px", top: "100px" }}
        >
          <DatePicker
            locale="ja"
            selected={calendarRef?.current?.getApi().getDate()}
            onChange={(date: Date | null) => {
              if (date) {
                calendarRef?.current?.getApi().changeView("resourceTimelineDay", date);
                setIsDatePickerOpen(false);
                saveCurrentDateTime();
              }
            }}
            inline
            calendarStartDay={convertToCalendarStartDay(
              mainState.personalSetting.calendar_start_day_of_week,
            )}
          />
        </Popover>
      )}
      <div style={{ position: "fixed", top: "140px", zIndex: "999" }}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            gap: "10px",
          }}
        >
          <GroupFilterSelectBox
            groups={groups || []}
            isLoading={isValidating}
            selectedFilterItem={selectedFilterGroupItem}
            setSelectedFilterItem={handleSetFilterGroups}
            fetchIndexKey={fetchIndexKey}
          />
          <MemberFilterSelectBox
            companyUsers={activeCompanyUsers || []}
            isLoading={isValidating}
            selectedFilterItem={selectedFilterUserItem}
            setSelectedFilterItem={handleSetFilterUsers}
            fetchIndexKey={fetchIndexKey}
            myselfId={myselfId}
          />
          <IconButton
            onClick={handleClearFilterSetting}
            sx={{
              "& .MuiButtonBase-root": {
                "& .MuiButton-startIcon": {
                  mr: 0,
                },
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          {mainState.personalSetting.is_microsoft_signed_in && (
            <FormControlLabel
              label={
                <Box display="flex" alignItems="center">
                  <OutlookIcon />
                  <Typography marginLeft="4px">Outlook</Typography>
                </Box>
              }
              control={<Checkbox onChange={handleCheckMicrosoftSchedule} />}
              checked={isIndicateMicrosoftSchedule}
              sx={{
                border: `1px solid ${theme.palette.grayScale[300]}`,
                borderRadius: "4px",
                height: "40px",
                ml: "2px",
                mr: "0px",
                pr: "8px",
              }}
            />
          )}
          <Button
            onClick={() => navigate("/schedule-search-result")}
            variant="outlined"
            sx={{ height: "40px", width: "105px", px: "10px", ml: "0px" }}
            startIcon={<Search />}
          >
            予定検索
          </Button>
          {viewType === "resourceTimelineDay" && (
            <Button
              onClick={handleClickSchedulesConfirmed}
              variant="contained"
              sx={{
                height: "40px",
                width: "140px",
              }}
            >
              予定を一括確定
            </Button>
          )}
        </div>
      </div>
      <ResponseSnackbar
        successMessage={state.successMessage || rightClickMenuState.successMessage}
        handleCloseSuccess={handleCloseSnackBar}
        errorMessage={state.errorMessage || rightClickMenuState.errorMessage}
        handleCloseError={handleCloseSnackBar}
      />
      <ScheduleCreateModal
        obj={scheduleCreateModal}
        func={() => setScheduleCreateModal({ isOpen: false, startTime: "", endTime: "" })}
        fetchIndexKey={fetchIndexKey}
        fetchNoDatedEventKey={fetchNoDatedEventKey}
      />
      <ScheduleEditModal
        isOpen={isEdit}
        setIsOpen={setIsEdit}
        setIsDetail={setIsDetail}
        fetchIndexKey={fetchIndexKey}
        fetchNoDatedEventKey={fetchNoDatedEventKey}
        setIsLoading={setIsLoading}
        setRecurrenceDialog={(isOpen, id, type, onUpdateFunc) =>
          setRecurrenceDialog({ isOpen, id, type, onUpdateFunc })
        }
      />
      <ScheduleDetailModal
        setIsLoading={setIsLoading}
        onDelete={(_, id) => setDelete({ isOpen: true, id })}
        setRecurrenceDialog={(isOpen, id, type, onUpdateFunc) =>
          setRecurrenceDialog({ isOpen, id, type, onUpdateFunc })
        }
        noDatedEvents={noDatedEvents || []}
        fetchIndexKey={fetchIndexKey}
        fetchNoDatedEventKey={fetchNoDatedEventKey}
        isOpen={isDetail}
        setIsOpen={setIsDetail}
        setIsEdit={setIsEdit}
      />
      <ExternalScheduleDetailModal
        isOpen={externalDetail.isOpen}
        onClose={() =>
          setExternalDetail({
            isOpen: false,
            title: "",
            startTime: "",
            endTime: "",
            note: "",
            users: [],
          })
        }
        state={{
          title: externalDetail.title,
          startTime: externalDetail.startTime,
          endTime: externalDetail.endTime,
          note: externalDetail.note,
          users: externalDetail.users,
        }}
      />
      <ScheduleRightClickMenu
        viewType={viewType}
        setSelectAssignmentsModal={(isOpen, scheduleId, assignments) =>
          setSelectAssignmentsModal({ isOpen, scheduleId, assignments })
        }
        setIsEdit={setIsEdit}
        setIsLoading={setIsLoading}
        onDelete={(_, id) => {
          setDelete({ isOpen: true, id });
        }}
        setRecurrenceDialog={(isOpen, id, type, onUpdateFunc) =>
          setRecurrenceDialog({ isOpen, id, type, onUpdateFunc })
        }
      />
      <SelectAssignmentsModal
        isOpen={selectAssignmentsModal.isOpen}
        onClose={() =>
          setSelectAssignmentsModal({ isOpen: false, scheduleId: 0 as ScheduleId, assignments: [] })
        }
        scheduleId={selectAssignmentsModal.scheduleId}
        assignments={selectAssignmentsModal.assignments}
        fetchIndexKey={fetchIndexKey}
      />
      <RecurrenceRuleConfirmDialog
        isOpen={recurrenceDialog.isOpen}
        type={recurrenceDialog.type}
        onClose={() => {
          setRecurrenceDialog({ isOpen: false, id: undefined, type: "delete" });
        }}
        onUpdate={async (_, withFutures) => {
          if (typeof recurrenceDialog.onUpdateFunc === "function") {
            await recurrenceDialog.onUpdateFunc(withFutures);
          }
          setRecurrenceDialog({ isOpen: false, id: undefined, type: "delete" });
          scrollToSavedPosition();
          mutateEvents();
        }}
        onCancel={() => {
          if (typeof recurrenceDialog.onCancelFunc === "function") {
            recurrenceDialog.onCancelFunc();
          }
        }}
        onDelete={(_, withFutures) => handleDeleteRecurrenceRule(withFutures)}
      />
      <RecurrenceUpdateInfoModal
        isOpen={isRecurrenceUpdateInfoModal}
        onClose={() => setIsRecurrenceUpdateInfoModal(false)}
      />
      <ScheduleDeleteConfirmDialog
        handleNo={() => {
          setDelete({ isOpen: false, id: undefined });
        }}
        handleYes={handleDeleteSchedule}
        isOpen={deleteDialog.isOpen}
      />
      <SchedulesConfirmedDialog
        isOpen={schedulesConfirmed.isOpen}
        scheduleIds={schedulesConfirmed.scheduleIds}
        onClose={() => {
          setSchedulesConfirmed({ isOpen: false, scheduleIds: [] });
        }}
        fetchIndexKey={fetchIndexKey}
      />
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 9999 }}
        open={isLoading || isValidating}
        invisible
      >
        <CircularProgress />
      </Backdrop>
      <Box sx={{ display: "flex", pt: "0rem", position: "relative" }}>
        <StyleWrapper viewType={viewType}>
          <FullCalendar
            eventsSet={handleEvents}
            ref={calendarRef}
            initialView="dayGridMonth"
            schedulerLicenseKey="0377836251-fcs-1690508823"
            locale="ja"
            // データとして保持している曜日の値とFullCalendarの示す曜日の値が+1ずつズレているため
            firstDay={mainState.personalSetting.calendar_start_day_of_week + 1}
            plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
            height="auto"
            datesSet={handleDatesChange}
            customButtons={{
              prev: {
                icon: "chevron-left",
                click: () => {
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.prev();
                  saveCurrentDateTime();
                },
              },
              next: {
                icon: "chevron-right",
                click: () => {
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.next();
                  saveCurrentDateTime();
                },
              },
              miniCalendarOpenButton: {
                text: "日付選択",
                click: () => setIsDatePickerOpen(true),
              },
              ...calendarViewButtons,
              createScheduleButton: {
                text: "予定作成",
                click: handleClickCreateSchedule,
              },
              todayButton: {
                text: "今日",
                click: () => {
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.today();
                  saveCurrentDateTime();

                  const elements: HTMLCollectionOf<Element> =
                    document.getElementsByClassName("fc-day-today");
                  if (elements.length > 0) {
                    const firstElement = elements[0] as HTMLElement;
                    // scrollのカスタムフックはクリックされるたびに発火すようにしているため使用しない
                    setTimeout(() => {
                      window.scrollTo(0, firstElement.offsetTop);
                    }, 1);
                  }
                },
              },
            }}
            headerToolbar={{
              left: `${calendarLeftButtons.join(" ")}`,
              right: "dayGridMonth,resourceTimelineWeek,resourceTimelineDay createScheduleButton",
            }}
            views={{
              dayGridMonth: {
                eventClick: handleEventClick,
                eventReceive: handleEventReceive,
                eventChange: handleMonthGridEventChange,
                dateClick: handleDateClick,
                eventDidMount: handleEventRightClick,
                eventDragStart: handleEventDragStart,
                eventOrder: ["-isHoliday", "start"],
              },
              resourceTimelineDay: {
                titleFormat: {
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                  weekday: "short",
                },
                resourceOrder: "order",
                nowIndicator: true,
                slotDuration: "00:30",
                scrollTime: "08:00",
                resourceAreaHeaderContent: "メンバー",
                resourceAreaWidth: "10rem",
                eventClick: handleEventClick,
                select: handleTimelineSelect,
                eventChange: handleTimelineEventChange,
                eventDidMount: handleEventRightClick,
                eventDragStart: handleEventDragStart,
                slotLabelFormat: [{ hour: "numeric", minute: "2-digit" }],
              },
              resourceTimelineWeek: {
                resourceOrder: "order",
                nowIndicator: true,
                resourceAreaHeaderContent: "メンバー",
                resourceAreaWidth: "10rem",
                slotDuration: { day: 1 },
                duration: { weeks: 1 },
                eventClick: handleEventClick,
                select: handleTimelineSelect,
                eventChange: handleTimelineEventChange,
                eventDidMount: handleEventRightClick,
                eventDragStart: handleEventDragStart,
              },
            }}
            editable
            selectable
            droppable
            eventDurationEditable
            resources={resources}
            resourceLabelClassNames={(arg) => {
              if (arg.resource.title === "参加者未定") {
                return "not_assigned";
              } else {
                return "";
              }
            }}
            events={events}
            eventTimeFormat={{ hour: "numeric", minute: "2-digit" }}
            eventContent={(e) => renderEventContent(e)}
          />
        </StyleWrapper>

        {viewType === "dayGridMonth" && (
          <Box sx={{ minWidth: "240px", px: "0.5rem", mt: "32px" }}>
            <NoDatedScheduleBlock setIsOpen={setIsDetail} events={noDatedEvents || []} />
          </Box>
        )}
      </Box>
    </>
  );
};
const renderEventContent = (eventInfo: EventContentArg) => {
  return (
    <Box sx={{ p: "2px" }}>
      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{eventInfo.timeText}</Typography>
      <Box sx={{ width: "100%", display: "flex", gap: "4px", alignItems: "center" }}>
        <Typography sx={{ fontSize: "14px", overflow: "hidden" }}>
          {eventInfo.event.title}
        </Typography>

        {isNonNumericString(eventInfo.event.id) && (
          <Box>
            <OutlookIcon />
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface GroupFilterSelectBoxProps {
  groups: GroupIndexResponse;
  isLoading: boolean;
  selectedFilterItem: string[];
  setSelectedFilterItem: (value: string[]) => void;
  fetchIndexKey: string;
}
const GroupFilterSelectBox = ({
  groups,
  isLoading,
  selectedFilterItem,
  setSelectedFilterItem,
  fetchIndexKey,
}: GroupFilterSelectBoxProps) => {
  // フィルタのセレクトボックスに表示する名前を作成
  const filterSelectBoxDisplayName = (): string => {
    const selectedNames = [];
    selectedFilterItem.includes("all") && selectedNames.push("すべて");

    let filteredGroupNames: string[] = [];
    if (groups) {
      filteredGroupNames = groups
        .filter((group) => selectedFilterItem.includes(`${group.id}`))
        .map((group) => group.name);
    }

    if (filteredGroupNames.length > 0) {
      selectedNames.push(filteredGroupNames);
    }

    return selectedNames.join(", ");
  };

  const handleFilter = (e: SelectChangeEvent<Array<string>>) => {
    const { value } = e.target as { value: Array<string> };

    // 「すべて」が選択されたら他の項目を削除する
    if (!selectedFilterItem.includes("all") && value[value.length - 1] === "all") {
      return setSelectedFilterItem(["all"]);
    }

    // 「すべて」が選択されているときに他の項目を選択した場合は「すべて」を削除する
    if (value.includes("all") && value[value.length - 1] !== "all") {
      setSelectedFilterItem(value.filter((item) => item !== "all"));
      return;
    }

    // 最後の選択項目を削除した場合は「すべて」を選択する
    if (value.length === 0) {
      return setSelectedFilterItem(["all"]);
    }

    setSelectedFilterItem(value as Array<string>);
  };

  return (
    <>
      <FormControl>
        <InputLabel>グループ</InputLabel>
        <Select
          label="グループ"
          name="filter"
          value={selectedFilterItem}
          multiple
          onChange={(e) => handleFilter(e)}
          onClose={() => mutate(fetchIndexKey)}
          style={{
            height: "40px",
            width: "180px",
            backgroundColor: theme.palette.grayScale[0],
            color: theme.palette.primary.main,
            fontWeight: "500",
          }}
          renderValue={filterSelectBoxDisplayName}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "500px",
              },
            },
          }}
        >
          <MenuItem value="all" disabled={isLoading}>
            {selectedFilterItem.includes("all") ? (
              <CheckIcon color="primary" sx={{ mr: 1 }} />
            ) : (
              <Box sx={{ p: 1.5, mr: 1 }} />
            )}
            <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>すべて</Box>
          </MenuItem>
          {groups &&
            groups.map((group) => (
              <MenuItem key={group.id} value={`${group.id}`} disabled={isLoading}>
                {selectedFilterItem.includes(`${group.id}`) ? (
                  <CheckIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <Box sx={{ px: 1.5, mr: 1 }} />
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 24,
                    width: "min-content",
                    px: "10px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    borderRadius: "4px",
                    backgroundColor: `#${group.color_number}`,
                  }}
                >
                  {group.name}
                </Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

interface MemberFilterSelectBoxProps {
  companyUsers: CompanyUserIndexResponse;
  isLoading: boolean;
  selectedFilterItem: string[];
  setSelectedFilterItem: (value: string[]) => void;
  fetchIndexKey: string;
  myselfId: UserId;
}

/** グループのセレクトボックスと基本構成は同じ. */
const MemberFilterSelectBox = (props: MemberFilterSelectBoxProps) => {
  // 自分と同じidのユーザーを省く
  const showingCompanyUsers = useMemo(() => {
    return props.companyUsers?.filter((companyUser) => companyUser.userId !== props.myselfId);
  }, [props.companyUsers]);

  const mySelfUserName = useMemo(() => {
    const mySelfUser = props.companyUsers?.find(
      (companyUser) => companyUser.userId === props.myselfId,
    );
    return mySelfUser ? mySelfUser.name : "";
  }, [props.companyUsers]);

  // フィルタのセレクトボックスに表示する名前を作成
  const filterSelectBoxDisplayName = useMemo(() => {
    const selectedNames = [];
    props.selectedFilterItem.includes("all") && selectedNames.push("すべて");
    props.selectedFilterItem.includes(`${props.myselfId}`) && selectedNames.push(mySelfUserName);

    let filteredUserNames: string[] = [];
    if (showingCompanyUsers) {
      filteredUserNames = showingCompanyUsers
        .filter((companyUser) => props.selectedFilterItem.includes(`${companyUser.userId}`))
        .map((user) => user.name);
    }

    if (filteredUserNames.length > 0) {
      selectedNames.push(filteredUserNames);
    }

    return selectedNames.join(", ");
  }, [props.selectedFilterItem, showingCompanyUsers]);

  const handleFilter = (e: SelectChangeEvent<Array<string>>) => {
    const { value } = e.target as { value: Array<string> };

    // 「すべて」が選択されたら他の項目を削除する
    if (!props.selectedFilterItem.includes("all") && value[value.length - 1] === "all") {
      return props.setSelectedFilterItem(["all"]);
    }

    // 「すべて」が選択されているときに他の項目を選択した場合は「すべて」を削除する
    if (value.includes("all") && value[value.length - 1] !== "all") {
      props.setSelectedFilterItem(value.filter((item) => item !== "all"));
      return;
    }
    // 最後の選択項目を削除した場合は「すべて」を選択する
    if (value.length === 0) {
      return props.setSelectedFilterItem(["all"]);
    }

    props.setSelectedFilterItem(value as Array<string>);
  };

  return (
    <>
      <FormControl>
        <InputLabel>参加者</InputLabel>
        <Select
          label="参加者"
          name="filter"
          value={props.selectedFilterItem}
          multiple
          onChange={(e) => handleFilter(e)}
          onClose={() => {
            return mutate(props.fetchIndexKey);
          }}
          style={{
            height: "40px",
            width: "180px",
            backgroundColor: theme.palette.grayScale[0],
            color: theme.palette.primary.main,
            fontWeight: "500",
          }}
          renderValue={() => filterSelectBoxDisplayName}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "500px",
              },
            },
          }}
        >
          <MenuItem value="all" disabled={props.isLoading}>
            {props.selectedFilterItem.includes("all") ? (
              <CheckIcon color="primary" sx={{ mr: 1 }} />
            ) : (
              <Box sx={{ p: 1.5, mr: 1 }} />
            )}
            <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>すべて</Box>
          </MenuItem>
          <MenuItem value={`${props.myselfId}`} disabled={props.isLoading}>
            {props.selectedFilterItem.includes(`${props.myselfId}`) ? (
              <CheckIcon color="primary" sx={{ mr: 1 }} />
            ) : (
              <Box sx={{ px: 1.5, mr: 1 }} />
            )}
            {mySelfUserName}
          </MenuItem>
          {showingCompanyUsers &&
            showingCompanyUsers.map((user) => (
              <MenuItem key={user.userId} value={`${user.userId}`} disabled={props.isLoading}>
                {props.selectedFilterItem.includes(`${user.userId}`) ? (
                  <CheckIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <Box sx={{ px: 1.5, mr: 1 }} />
                )}
                <Box>{user.name}</Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
