import {
  PrimaryCategory,
  PrimaryCategoryId,
} from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { tertiaryCategoryRepository } from "data-access/repositories/project/tertiary_category/tertiary_category.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleReduxError } from "utils/errorHandler";

export const useCategoryDelete = (
  projectId: ProjectId,
  primaryCategories: PrimaryCategory[],
  selectedPrimaryCategoryId: PrimaryCategoryId,
  selectedSecondaryCategoryId: SecondaryCategoryId,
  setSelectedPrimaryCategoryId: (primaryCategoryId: PrimaryCategoryId) => void,
  setSelectedSecondaryCategoryId: (secondaryCategoryId: SecondaryCategoryId) => void,
  primaryCategoryMutate: () => void,
  secondaryCategoryMutate: () => void,
) => {
  const dispatch = useAppDispatch();

  const handleDeletePrimaryCategory = async (primaryCategoryId: PrimaryCategoryId) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await primaryCategoryRepository.delete(projectId, primaryCategoryId);
      primaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("大項目を削除しました"));
      if (primaryCategoryId === selectedPrimaryCategoryId) {
        setSelectedPrimaryCategoryId(primaryCategories[0].id);
        setSelectedSecondaryCategoryId(primaryCategories[0].secondaryCategories[0].id);
      }
    } catch (error) {
      handleReduxError(error, dispatch, "大項目の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDeleteSecondaryCategory = async (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await secondaryCategoryRepository.delete(projectId, primaryCategoryId, secondaryCategoryId);
      primaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("中項目を削除しました"));
      if (secondaryCategoryId === selectedSecondaryCategoryId) {
        setSelectedSecondaryCategoryId(
          primaryCategories.filter((primaryCategory) => primaryCategory.id === primaryCategoryId)[0]
            ?.secondaryCategories[0].id,
        );
      }
    } catch (error) {
      handleReduxError(error, dispatch, "中項目の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDeleteTertiaryCategory = async (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
    tertiaryCategoryId: TertiaryCategoryId,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await tertiaryCategoryRepository.delete(
        projectId,
        primaryCategoryId,
        secondaryCategoryId,
        tertiaryCategoryId,
      );
      secondaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("小項目を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "小項目の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return {
    handleDeletePrimaryCategory,
    handleDeleteSecondaryCategory,
    handleDeleteTertiaryCategory,
  };
};
