import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { GroupLabel } from "components/label/group-label";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import {
  PrimaryCategory,
  PrimaryCategoryId,
} from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { sharablePhotoRepository } from "data-access/repositories/share/project_photo/sharable_photo.repository";
import { theme } from "extensions/theme";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { usePhotoSelect } from "hooks/usePhotoSelect";
import FileDownload from "images/file_download_icon.svg";
import Logo from "images/logo.svg";
import { ErrorPage404 } from "pages/error/error-page-404";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { downloadImageAll, downloadImagesV2 } from "utils/downloadImages";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

export const ShareProjectPhotos = () => {
  const classes = styles();
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const pathParts = window.location.pathname.split("/");
  const { data, error } = useSWR(`/api/v1/sharable_photos/${pathParts[2]}/primary_categories`, () =>
    primaryCategoryRepository.sharedIndex(pathParts[2]),
  );

  // リンクが不正な場合は404ページを表示
  if (error) {
    return <ErrorPage404 />;
  }

  const [selectedPrimaryCategoryId, setSelectedPrimaryCategoryId] = useState<PrimaryCategoryId>(
    0 as PrimaryCategoryId,
  );
  const [selectedSecondaryCategoryId, setSelectedSecondaryCategoryId] =
    useState<SecondaryCategoryId>(0 as SecondaryCategoryId);

  const { data: secondaryCategory } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/sharable_photos/${pathParts[2]}/secondary_categories/${selectedSecondaryCategoryId}`,
    () =>
      secondaryCategoryRepository.sharedIndex(
        pathParts[2],
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
  );

  const { data: photos } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/sharable_photos/${pathParts[2]}/primary_categories/${selectedPrimaryCategoryId}/secondary_categories/${selectedSecondaryCategoryId}/photos`,
    () =>
      secondaryCategoryRepository.getSharedPhotos(
        pathParts[2],
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
  );

  useEffect(() => {
    if (data) {
      setSelectedPrimaryCategoryId(data.primaryCategories[0].id);
      setSelectedSecondaryCategoryId(data.primaryCategories[0].secondaryCategories[0].id);
    }
  }, [data]);

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(photos);

  const {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  } = usePhotoSelect(photos);

  const handleSelectPrimaryCategory = (primaryCategory: PrimaryCategory) => {
    setSelectedPrimaryCategoryId(primaryCategory.id);
    setSelectedSecondaryCategoryId(primaryCategory.secondaryCategories[0].id);
    setSelectedPhotoIds(new Set());
  };

  const handleSelectSecondaryCategory = (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    setSelectedPrimaryCategoryId(primaryCategoryId);
    setSelectedSecondaryCategoryId(secondaryCategoryId);
    setSelectedPhotoIds(new Set());
  };

  const handleDownloadAll = async () => {
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const allPhotoObj = await sharablePhotoRepository.index(pathParts[2]);
      await downloadImageAll(allPhotoObj, data?.projectName || "");
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
    } catch (error) {
      handleReduxError(error, dispatch, "写真のダウンロードに失敗しました");
    }
  };

  const handleDownload = async () => {
    if (!photos || !secondaryCategory) return;
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const selectedPhotos = photos.filter((photo) => selectedPhotoIds.has(photo.id));
      await downloadImagesV2(selectedPhotos, secondaryCategory.name);
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
    } catch (error) {
      handleReduxError(error, dispatch, "写真のダウンロードに失敗しました");
    }
  };

  return (
    <>
      <ResponseSnackbar
        successMessage={mainState.successMessage}
        errorMessage={mainState.errorMessage}
      />
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "4rem",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <img src={Logo} alt="logo" style={{ marginLeft: "24px", width: 150 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "1.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold", pr: "24px" }}>
            {data?.projectName}
          </Typography>
          <Button
            variant="contained"
            onClick={handleDownloadAll}
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              height: "30px",
              borderRadius: "30px",
            }}
            startIcon={
              <Box sx={{ color: theme.palette.grayScale[0] }} component="img" src={FileDownload} />
            }
          >
            一括ダウンロード
          </Button>
        </Box>
        <Box style={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>作成企業:</Typography>
          <Typography sx={{ pl: "0.5rem" }}>{data?.companyName}</Typography>
        </Box>
      </Box>
      <Divider />

      <Box sx={{ display: "flex", p: "24px" }}>
        <Box sx={{ width: "180px" }}>
          {data?.primaryCategories.map((primaryCategory) => (
            <Box key={primaryCategory.id} sx={{ mb: "16px" }}>
              <Box
                onClick={() => handleSelectPrimaryCategory(primaryCategory)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "12px",
                  ":hover": { cursor: "pointer" },
                  color:
                    selectedPrimaryCategoryId === primaryCategory.id
                      ? theme.palette.primary.main
                      : "inherit",
                }}
              >
                {primaryCategory.name}
              </Box>
              {primaryCategory.secondaryCategories.map((secondaryCategory) => (
                <div key={secondaryCategory.id} style={{ marginLeft: "8px" }}>
                  <Box
                    onClick={() =>
                      handleSelectSecondaryCategory(primaryCategory.id, secondaryCategory.id)
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      mb: "12px",
                      ":hover": { cursor: "pointer" },
                      color:
                        selectedSecondaryCategoryId === secondaryCategory.id
                          ? theme.palette.primary.main
                          : theme.palette.grayScale[700],
                    }}
                  >
                    {secondaryCategory.name}
                  </Box>
                </div>
              ))}
            </Box>
          ))}
        </Box>

        {secondaryCategory && (
          <Box sx={{ width: "100%", overflow: "scroll", height: "75vh" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: "20px" }}>
              {secondaryCategory.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px", mb: "20px" }}>
              <Typography
                onClick={handleSelectPhotoAll}
                className={classes.selectAllButton}
                fontSize={14}
                color={theme.palette.primary.main}
              >
                すべて選択
              </Typography>
              <Typography
                onClick={handleDeselectPhotoAll}
                className={classes.deselectButton}
                fontSize={14}
              >
                選択を解除
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleDownload}
                  disabled={selectedPhotoIds.size === 0}
                  sx={{
                    fontSize: "12px",
                    height: "30px",
                    borderRadius: "20px",
                    fontWeight: "500",
                  }}
                  startIcon={
                    <Box
                      sx={{ color: theme.palette.grayScale[0] }}
                      component="img"
                      src={FileDownload}
                    />
                  }
                >
                  {selectedPhotoIds.size}件をダウンロード
                </Button>
              </Box>
            </Box>
            {secondaryCategory.tertiaryCategories.map((tertiaryCategory) => {
              const filteredPhotos = photos?.filter(
                (photo) => photo.tertiaryCategory.id === tertiaryCategory.id,
              );

              return (
                <React.Fragment key={tertiaryCategory.id}>
                  <GroupLabel
                    name={tertiaryCategory.name}
                    colorNumber={tertiaryCategory.colorNumber}
                  />
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: "24px", mt: "16px", mb: "28px" }}
                  >
                    {filteredPhotos && filteredPhotos.length > 0 ? (
                      filteredPhotos.map((photo) => (
                        <Box
                          key={photo.id}
                          sx={{
                            position: "relative",
                            overflow: "hidden",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.grayScale[300]}`,
                          }}
                        >
                          <Box
                            sx={{
                              borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                              height: "137px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              alt={`photo-${photo.id}`}
                              src={photo.thumbnailUrl}
                              loading="lazy"
                              style={{
                                width: "215px",
                                height: "137px",
                                objectFit: "cover",
                              }}
                              onClick={() => handleClickPhotoPreview(photo)}
                            />
                            {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                            <input
                              type="checkbox"
                              checked={selectedPhotoIds.has(photo.id)}
                              onChange={() => handleSelectPhoto(photo.id)}
                              className={classes.checkbox}
                            />
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography>写真はありません</Typography>
                    )}
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
