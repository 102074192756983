import { ApiClient } from "data-access/ApiClient";
import { HolidayIndexRequest, HolidayIndexResponse } from "./holiday.dto";

export const holidayRepository = {
  async index({
    holidayRangeStart,
    holidayRangeEnd,
  }: HolidayIndexRequest = {}): Promise<HolidayIndexResponse> {
    const params = {
      holiday_range_start: holidayRangeStart,
      holiday_range_end: holidayRangeEnd,
    };
    const res = await ApiClient.get("/api/v1/holidays", { params });
    return res.data;
  },
};
