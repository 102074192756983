import { ViewType } from "components/organisms/calendar";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { CalendarEvent } from "types/calendarEvent";
import { usersName } from "./usersName";

export const scheduleTitle = (
  scheduleName: string,
  displayName: string,
  users: User[],
  viewType: ViewType,
): string => {
  if (viewType !== "dayGridMonth") {
    // 月表示以外: 予定名が空なら（予定なし）と表示
    return scheduleName === "" ? `${displayName} (予定名なし)` : displayName;
  }

  // 月表示: 予定名がある場合はdisplayNameを表示、ない場合は参加者名を表示
  return scheduleName ? displayName : `${displayName} ${usersName(users)}`;
};

export const convertScheduleToEvent = (
  schedule: ScheduleList,
  viewType: ViewType,
): CalendarEvent => {
  return {
    id: schedule.id.toString(),
    resourceIds: !schedule.users.length
      ? ["0"]
      : schedule.users.map((user) => {
          return user.id.toString();
        }),
    title: scheduleTitle(schedule.name, schedule.display_name, schedule.users, viewType),
    note: schedule.note,
    start: schedule.start_time,
    end: schedule.end_time,
    textColor: schedule.is_confirmed
      ? theme.palette.grayScale[0]
      : `#${schedule.schedule_type.color_number}`,
    display: "block",
    isConfirmed: schedule.is_confirmed,
    backgroundColor: schedule.is_confirmed
      ? `#${schedule.schedule_type.color_number}`
      : theme.palette.grayScale[0],
    borderColor: `#${schedule.schedule_type.color_number}`,
    isHoliday: schedule.schedule_type.is_holiday,
    recurrenceRule: schedule.recurrence_rule,
    isAccessible: schedule.is_accessible,
  };
};
