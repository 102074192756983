import { GridColumnVisibilityModel, GridValidRowModel } from "@mui/x-data-grid";
import { CSVGridColDef } from "pages/projects";

type EntityName = "billing" | "project" | "scheduleSearchResult";

export const downloadDataGridRowsCsv = <RowModel extends GridValidRowModel>(
  entityName: EntityName,
  rows: RowModel[],
  columns: CSVGridColDef[],
  visibility: GridColumnVisibilityModel,
) => {
  const headerRow = columns
    .filter((column) => visibility[column.field] !== false)
    .map((column) => column.headerName)
    .join(",");
  const dataRows = rows.map((row) =>
    columns
      .filter((column) => visibility[column.field] !== false)
      .map((column) => {
        if (column.csvRender) {
          return column.csvRender(row[column.field], row);
        }
        return row[column.field];
      })
      .join(","),
  );
  const csvData = [headerRow, ...dataRows].join("\n");
  // BOMを付与することでExcelで開いた時の文字化けを防止
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvData], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const filename = `${getEntityNameInJapanese(entityName)}_${year}${month}${day}.csv`;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

const getEntityNameInJapanese = (entityName: EntityName) => {
  switch (entityName) {
    case "billing":
      return "請求";
    case "project":
      return "案件";
    case "scheduleSearchResult":
      return "スケジュール";
  }
};
