import { useMemo } from "react";
import { holidayRepository } from "data-access/repositories/holiday/holiday.repository";
import useSWR from "swr";
import { formatDateUtil } from "utils/formatDateUtil";

type Props = {
  holidayRangeStart?: Date | null;
  holidayRangeEnd?: Date | null;
};

/**
 * 祝日APIから祝日を取得する
 * @returns 祝日の配列と取得完了フラグ
 */
export const useHolidays = ({ holidayRangeStart, holidayRangeEnd }: Props) => {
  const holidayRangeStartStr = useMemo(() => {
    if (!holidayRangeStart) return undefined;
    return formatDateUtil(holidayRangeStart, "yyyy-MM-dd");
  }, [holidayRangeStart]);

  const holidayRangeEndStr = useMemo(() => {
    if (!holidayRangeEnd) return undefined;
    return formatDateUtil(holidayRangeEnd, "yyyy-MM-dd");
  }, [holidayRangeEnd]);

  const fetchHolidaysKey = useMemo(() => {
    // startとendがない場合はnullを返し、APIを叩かない
    if (!holidayRangeStart || !holidayRangeEnd) return null;

    return `/api/v1/holidays?holidayRangeStart=${holidayRangeStartStr}&holidayRangeEnd=${holidayRangeEndStr}`;
  }, [holidayRangeStartStr, holidayRangeEndStr]);

  const { data: holidays } = useSWR(fetchHolidaysKey, () => {
    return holidayRepository.index({
      holidayRangeStart: holidayRangeStartStr,
      holidayRangeEnd: holidayRangeEndStr,
    });
  });

  return { holidays: holidays || [] };
};
