import { Range } from "react-date-range";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { UserId } from "data-access/repositories/user/user.dto";
import { ALL_USERS_VALUE } from "utils/constant";

// billing
export type DefaultBillingType = {
  billingDateV2: {
    range: Range;
    label: string;
  };
  statusType: string;
  depositStatusTypes: string[];
  billingFileCreateStatusType: string;
  warrantyCreateStatusType: string;
  createdById: number;
  keyword: string;
};

export const DefaultBilling: DefaultBillingType = {
  billingDateV2: {
    range: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
    label: "すべての請求日",
  },
  statusType: "all",
  depositStatusTypes: ["before_deposited", "partial_deposited", "all_deposited"],
  billingFileCreateStatusType: "all",
  warrantyCreateStatusType: "all",
  createdById: 0,
  keyword: "",
};

// project
export type DefaultProjectType = {
  projectTypeIds: ProjectTypeId[];
  projectStatusTypeIds: ProjectStatusTypeId[];
  inquiredDate: {
    range: Range;
    label: string;
  };
  orderedDate: {
    range: Range;
    label: string;
  };
  completedDate: {
    range: Range;
    label: string;
  };
  billingConditions: string[];
  keyword: string;
};

export const DefaultProjectFilter: DefaultProjectType = {
  projectTypeIds: [],
  projectStatusTypeIds: [],
  inquiredDate: {
    range: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
    label: "すべての受付日",
  },
  orderedDate: {
    range: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
    label: "すべての受注日",
  },
  completedDate: {
    range: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
    label: "すべての完了日",
  },
  billingConditions: [
    "no_bill",
    "partially_not_yet_bill",
    "all_billed",
    "managing_billing_on_another_system",
    "all_not_yet_bill",
  ],
  keyword: "",
};

// work_report
export type DefaultWorkReportType = {
  date: {
    range: Range;
    label: string;
  };
  userIds: UserId[];
  keyword: string;
};

export const DefaultWorkReport: DefaultWorkReportType = {
  date: {
    range: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },
    label: "すべての作業日時",
  },
  userIds: [ALL_USERS_VALUE],
  keyword: "",
};
