import React from "react";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowId,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { theme } from "../../extensions/theme";

const StyledDataGridPro = styled(DataGridPro)`
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-columnHeader:focus {
    outline: inherit !important;
  }
  .MuiDataGrid-rowReorderCellContainer {
    color: ${theme.palette.text.secondary};
  }
  // Drag中にIDが表示されるため、見えなくするstyle
  .MuiDataGrid-rowReorderCellPlaceholder {
    color: ${theme.palette.grayScale[0]};
  }
  .MuiDataGrid-cell {
    display: flex;
    align-items: center;
    line-height: inherit !important;
  }
`;

export const CustomizedDataGridPro = (props: DataGridProProps<GridValidRowModel>) => {
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const onCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) return;

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [setCellModesModel],
  );

  /**
   * propsより指定されたprocessRowUpdateをwrapする形で実行.
   *  無編集の場合は指定された編集後処理を実行しない。
   */
  const handleProcessRowUpdate = React.useCallback(
    (
      newRow: GridValidRowModel,
      oldRow: GridValidRowModel,
      _: {
        rowId: GridRowId;
      },
    ) => {
      let isNewValueExisted = false;
      Object.keys(newRow).forEach((key: string) => {
        if (newRow[key] !== oldRow[key]) {
          isNewValueExisted = true;
          return;
        }
      });

      if (!isNewValueExisted) return newRow;
      props.processRowUpdate && props.processRowUpdate(newRow, oldRow, _);
      return newRow;
    },
    [props.processRowUpdate],
  );
  return (
    <StyledDataGridPro
      {...props}
      onCellClick={onCellClick}
      cellModesModel={cellModesModel}
      onCellModesModelChange={setCellModesModel}
      processRowUpdate={(newRow, oldRow, params) => handleProcessRowUpdate(newRow, oldRow, params)}
    />
  );
};
