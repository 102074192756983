import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Edit, OpenInNew } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LinkButton } from "components/button/link-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { GroupLabel } from "components/label/group-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { DetailTable } from "components/molecules/detail-table";
import { ShareSettingDialog } from "components/molecules/share-setting-dialog";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { workReportPhotoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import { primaryCategoryRepository } from "data-access/repositories/work_report/primary_category/primary_category.repository";
import {
  WorkReport,
  WorkReportId,
  WorkReportState,
  WorkReportUpdateRequest,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { usePhotoSelect } from "hooks/usePhotoSelect";
import FileDownload from "images/file_download_icon.svg";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate, useSWRConfig } from "swr";
import { downloadImagesV2 } from "utils/downloadImages";
import { handleReduxError } from "utils/errorHandler";
import { formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { WorkReportEditModal } from "./edit-modal";
import { styles } from "./styles";
import { PhotoPreviewModal } from "../photo-preview-modal";

interface Props {
  show: { isOpen: boolean; id: WorkReportId | undefined };
  setShow: (e: { isOpen: boolean; id: WorkReportId | undefined }) => void;
  onDelete: (id: WorkReportId) => void;
  fetchIndexKey?: string;
}

const CustomBox = styled(Box)({
  lineHeight: "14px",
  letterSpacing: "0.2px",
  fontWeight: "500",
  padding: "1rem 0",
});

const CustomHeaderBox = styled(Box)({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.grayScale[0],
  zIndex: 9,
  paddingTop: "16px",
});

const CustomTextForm = (props: { label: string; isTitle?: boolean }) => {
  const labelColor = props.isTitle ? theme.palette.grayScale[700] : theme.palette.grayScale[900];
  return (
    <CustomFormLabel
      small
      labelName={props.label}
      labelColor={labelColor}
      sx={{ height: "14px", lineHeight: "14px" }}
    />
  );
};

export const WorkReportDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: IndexMutate } = useSWRConfig();

  const handleClose = () => {
    props.setShow({ isOpen: false, id: undefined });
    // 作業日報画面から開いている場合、作業日報画面に戻る（予定や案件からの動線もあるため）
    if (location.pathname.includes("work-reports")) {
      navigate("/work-reports");
    }
  };

  const handleClickDelete = (id: WorkReportId) => {
    props.onDelete(id);
  };

  const handleUpdateSuccess = async (e: string) => {
    dispatch(mainOperations.updateSuccessMessage(e));
    try {
      await IndexMutate(props.fetchIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      <Dialog
        open={props.show.isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxWidth: 600,
          },
        }}
      >
        <Box sx={{ p: "0 1rem 1rem", width: "600px" }}>
          {props.show.id && (
            <Contents
              id={props.show.id}
              setSuccessMessage={handleUpdateSuccess}
              handleClose={handleClose}
              handleClickDelete={handleClickDelete}
              fetchIndexKey={props.fetchIndexKey || ""}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

const Contents = (props: {
  id: WorkReportId;
  setSuccessMessage: (e: string) => void;
  handleClose: () => void;
  handleClickDelete: (id: WorkReportId) => void;
  fetchIndexKey: string;
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);
  const fetchKey = `/api/v1/work_reports/${props.id}`;
  const fetchPhotosKey = `/api/v1/work_reports/${props.id}/photos`;
  const fetchPrimaryCategoriesKey = `/api/v1/work_reports/${props.id}/primary_categories`;
  const photoCountIndexKey = `${fetchPhotosKey}/count_info`;

  const { data: workReport, isLoading } = useSWR(fetchKey, () =>
    workReportRepository.show(props.id as WorkReportId),
  );

  const { data: primaryCategories } = useSWR(
    fetchPrimaryCategoriesKey,
    () => primaryCategoryRepository.index(props.id),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: photos, mutate: photosMutate } = useSWR(fetchPhotosKey, () =>
    workReportPhotoRepository.index(props.id),
  );

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(photos);

  const {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  } = usePhotoSelect(photos);

  const handleUpdate = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.update(
        props.id as WorkReportId,
        body as WorkReportUpdateRequest,
      );
      props.setSuccessMessage("作業日報を更新しました");
      return res;
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      throw error;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    openURLInNewTab(`projects/${workReport?.project_id}`);
  };

  const initialDefaultState = useMemo(() => {
    return {
      siteName: workReport?.site_name,
      userIds: workReport?.users.map((user) => user.id),
      users: workReport?.users,
      siteManagerId: workReport?.site_manager ? workReport.site_manager.id : 0,
      startTime: workReport?.start_time,
      endTime: workReport?.end_time,
      content: workReport?.content,
      workStatusType: workReport?.work_status_type,
      created_date: workReport?.created_date,
      created_by: workReport?.created_by,
    } as WorkReportState;
  }, [workReport]);

  /**
   * 写真の共有リンクタップ時の処理.
   *
   * @param photo 写真オブジェクト(画像ではないので注意).
   */
  const handleClickImageLinkButton = async (photo: PhotoResponse) => {
    const photoId = photo.id;
    const isSharable = !photo.isSharable;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await workReportPhotoRepository.update(
        photo.workReportAttributes?.id as WorkReportId,
        photoId as WorkReportPhotoId,
        isSharable,
      );
      mutate(photoCountIndexKey);
      photosMutate();
    } catch (error) {
      handleReduxError(error, dispatch, "写真共有の切り替えに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDownload = async () => {
    if (!photos) return;
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const selectedPhotos = photos.filter((photo) => selectedPhotoIds.has(photo.id));
      await downloadImagesV2(selectedPhotos, `作業日報写真_(${workReport?.site_name})`);
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
      setSelectedPhotoIds(new Set());
    } catch (error) {
      handleReduxError(error, dispatch, "写真のダウンロードに失敗しました");
    }
  };

  if (isLoading || !workReport) {
    return (
      <Backdrop sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <>
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />
      <WorkReportEditModal
        id={props.id as WorkReportId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleUpdate}
        defaultValue={initialDefaultState}
        projectId={workReport.project_id}
        defaultPhotos={photos || []}
        fetchIndexKeys={[fetchKey, fetchPhotosKey, props.fetchIndexKey, fetchPrimaryCategoriesKey]}
      />
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      {workReport && (
        <ShareSettingDialog
          open={isOpenShareModal}
          onClose={() => setIsOpenShareModal(false)}
          id={workReport.id}
          resourceType="work_reports"
          photoUrlKey={workReport.url_key || ""}
        />
      )}

      <CustomHeaderBox>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: "8px" }}>
          <Button
            startIcon={<Close />}
            onClick={() => props.handleClose()}
            sx={{ color: theme.palette.grayScale[700] }}
          >
            とじる
          </Button>
          <div>
            <Button
              onClick={() => setIsOpenShareModal(true)}
              startIcon={
                <LinkIcon
                  size={16}
                  color={workReport?.url_key ? theme.palette.blue[500] : theme.palette.primary.main}
                />
              }
            >
              <Typography
                sx={{
                  color: workReport?.url_key ? theme.palette.blue[500] : theme.palette.primary.main,
                  mr: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {workReport?.url_key ? "リンクで共有中" : "リンクで共有"}
              </Typography>
            </Button>
            <Button
              startIcon={<Edit />}
              color="primary"
              onClick={() => setIsOpen(true)}
              sx={{ mr: "8px" }}
            >
              内容を編集
            </Button>
            <Button
              startIcon={<DeleteIcon size={20} />}
              color="error"
              onClick={() => props.handleClickDelete(props.id)}
            >
              削除
            </Button>
          </div>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", p: "2px", width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                wordBreak: "break-all",
                flex: 1,
                mr: "20px",
              }}
            >
              {workReport?.site_name}
            </Typography>
            <StatusTypeLabel
              statusType={workReport?.work_status_type_i18n || ""}
              sx={{ width: "80px", mr: "12px", p: "6px 20px" }}
            />
          </Box>
        </Box>

        <Divider sx={{ mt: "26px" }} />
      </CustomHeaderBox>

      <DetailTable
        sx={{ mt: 1 }}
        items={[
          {
            label: <CustomTextForm isTitle label="案件" />,
            value: (
              <Link
                href="#"
                onClick={handleClick}
                underline="none"
                sx={{ display: "flex", alignItems: "center", fontWeight: "500", fontSize: "14px" }}
              >
                {workReport?.project_name}
                <OpenInNew fontSize="small" color="primary" />
              </Link>
            ),
          },
          {
            label: <CustomTextForm isTitle label="作業日時" />,
            value: (
              <CustomTextForm
                label={timeRange(
                  "yyyy年MM月dd日 HH:mm",
                  workReport?.start_time.toString(),
                  workReport?.end_time.toString(),
                )}
              />
            ),
          },
          {
            label: <CustomTextForm isTitle label="現場責任者" />,
            value: (
              <CustomTextForm
                label={workReport?.site_manager ? workReport?.site_manager.name : "未設定"}
              />
            ),
          },
        ]}
      />

      <CustomBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            参加者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {usersName(workReport?.users || [])}
          </Typography>
        </div>
      </CustomBox>

      <CustomBox>
        <Box sx={{ display: "flex" }}>
          <Typography
            component="span"
            variant="body2"
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作業内容・連絡事項
          </Typography>
          <Box sx={{ ml: "13px", width: "70%" }}>
            <Typography
              sx={{ fontSize: "14px", color: theme.palette.grayScale[900], wordBreak: "break-all" }}
            >
              {workReport?.content.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </Box>
      </CustomBox>

      <CustomBox>
        <Box sx={{ display: "flex" }}>
          <Typography
            fontWeight="500"
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              height: "35px",
              lineHeight: "30px",
            }}
          >
            現場写真
          </Typography>
          {photos && photos.length > 0 && (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "16px", mb: "20px", ml: "20px" }}
            >
              <Typography
                onClick={handleSelectPhotoAll}
                className={classes.selectAllButton}
                fontSize={14}
                color={theme.palette.primary.main}
              >
                すべて選択
              </Typography>
              <Typography
                onClick={handleDeselectPhotoAll}
                className={classes.deselectButton}
                fontSize={14}
              >
                選択を解除
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleDownload}
                  disabled={selectedPhotoIds.size === 0}
                  sx={{
                    fontSize: "12px",
                    height: "30px",
                    borderRadius: "20px",
                    fontWeight: "500",
                  }}
                  startIcon={
                    <Box
                      sx={{ color: theme.palette.grayScale[0] }}
                      component="img"
                      src={FileDownload}
                    />
                  }
                >
                  {selectedPhotoIds.size}件をダウンロード
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Box>
          {primaryCategories &&
            primaryCategories.length > 0 &&
            primaryCategories.map((primaryCategory, index) => (
              <React.Fragment key={index}>
                <Typography fontSize={20} fontWeight="bold" sx={{ mb: "12px" }}>
                  {primaryCategory.name}
                </Typography>
                {primaryCategory.secondaryCategories.map((secondaryCategory, index) => (
                  <React.Fragment key={index}>
                    <Typography fontSize={16} fontWeight="bold" sx={{ mb: "14px" }}>
                      {secondaryCategory.name}
                    </Typography>
                    {secondaryCategory.tertiaryCategories.map((tertiaryCategory) => {
                      const filteredPhotos = photos?.filter((photo) => {
                        return photo.tertiaryCategory.id === tertiaryCategory.id;
                      });

                      return (
                        <React.Fragment key={tertiaryCategory.id}>
                          <GroupLabel
                            name={tertiaryCategory.name}
                            colorNumber={tertiaryCategory.colorNumber}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "24px",
                              mt: "12px",
                              mb: "28px",
                            }}
                          >
                            {filteredPhotos && filteredPhotos.length > 0 ? (
                              filteredPhotos.map((photo) => (
                                <Box
                                  key={photo.id}
                                  sx={{
                                    position: "relative",
                                    overflow: "hidden",
                                    borderRadius: "4px",
                                    border: `1px solid ${theme.palette.grayScale[300]}`,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                                      height: "137px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      alt={`photo-${photo.id}`}
                                      src={photo.thumbnailUrl}
                                      loading="lazy"
                                      style={{
                                        width: "215px",
                                        height: "137px",
                                        objectFit: "cover",
                                      }}
                                      onClick={() => handleClickPhotoPreview(photo)}
                                    />
                                    {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                                    <input
                                      type="checkbox"
                                      checked={selectedPhotoIds.has(photo.id)}
                                      onChange={() => handleSelectPhoto(photo.id)}
                                      className={classes.checkbox}
                                    />
                                    {workReport.url_key && (
                                      <LinkButton
                                        sx={{ top: "8px", right: "8px", position: "absolute" }}
                                        isEnabled={photo.isSharable}
                                        onClick={() => handleClickImageLinkButton(photo)}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              ))
                            ) : (
                              <Typography>写真はありません</Typography>
                            )}
                          </Box>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
        </Box>
      </CustomBox>

      <DetailTable
        sx={{ minWidth: "35rem" }}
        items={[
          {
            label: <CustomTextForm isTitle label="作成日" />,
            value: (
              <CustomTextForm
                label={formatDateUtil(
                  new Date(workReport?.created_date as string),
                  "yyyy年MM月dd日",
                )}
              />
            ),
          },
          {
            label: <CustomTextForm isTitle label="作成者" />,
            value: <CustomTextForm label={workReport?.created_by.name || ""} />,
          },
        ]}
      />
    </>
  );
};
